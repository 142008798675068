.call-list {
  width: 100%;
  background-color: $color-white;
  height: 100%;
  border-radius: 5px;

  // @include respond-to($large-screen) {
  //   height: calc(100vh - 260px);
  // }

  // @include respond-to($medium-screen) {
  //   height: calc(100vh - 240px);
  // }

  // @include respond-to($small-screen) {
  //   height: calc(100vh - 210px);
  // }

  &--pagination {
    height: 100% !important;
    border-radius: 5px;
  }

  &__call {
    font-size: rem(16);
    font-weight: 300;
    color: $dark-grey;
    text-align: left;
    height: 59px;
    width: 100%;
    border-right: 0;
    justify-content: flex-start !important;
    padding-left: 6.5px !important;

    &-dealer {
      justify-content: center !important;
      padding-left: 0 !important;
    }

    .strong {
      font-size: 14px;
      color: $navy-blue;
      font-weight: 400;
    }

    &-admin {
      height: 32px;
    }

    &:nth-child(-n + 1) {
      padding-left: 25px !important;
    }

    &:nth-child(-n + 3) {
      justify-content: initial !important;
    }

    @include respond-to($large-screen) {
      font-size: rem(15);
    }

    @include respond-to($medium-screen) {
      font-size: rem(13);
    }

    @include respond-to($small-screen) {
      font-size: rem(10);
    }

    &:last-child {
      border-bottom-width: 0;
    }

    .call__date {
      flex-shrink: 0;
      flex-grow: 0;
      width: 20% !important;
    }

    .call__icon {
      @include flex(flex-start);

      .icon {
        width: 22px;
        height: 22px;
        fill: $dark-grey;

        @include respond-to($large-screen) {
          width: 20px;
          height: 20px;
        }

        @include respond-to($medium-screen) {
          width: 18px;
          height: 18px;
        }

        @include respond-to($small-screen) {
          width: 16px;
          height: 16px;
        }
      }
    }

    .call__lawyer {
      width: 15%;
    }

    .call__time {
      width: 18%;
    }

    .call__price {
      width: 20%;
    }

    .call__service {
      width: 20%;
    }

    &.noPadding {
      padding-left: 0 !important;
    }

    &--mobile {
      height: 72px;
      .call__text {
        color: $gray-400;
        font-size: 12px;
        height: 30px;
        display: block;
        margin-top: 3px;
        font-weight: normal;
      }
    }
  }

  .call-list__call-active {
    background-color: $color-cream;
  }
}

.call-list--border {
  border-right: 1px solid $color-grey;
}

.singleLawyerContainer {
  margin: 30px;
  box-shadow: 1px -1px 55px -33px rgba(0, 0, 0, 0.72);
  -webkit-box-shadow: 1px -1px 55px -33px rgba(0, 0, 0, 0.72);
  -moz-box-shadow: 1px -1px 55px -33px rgba(0, 0, 0, 0.72);

  &.gray {
    background: #f8f8f8;
    box-shadow: unset;
    margin: 0;
    padding: 30px;

    > div {
      margin-top: 0;
    }
  }

  @media (max-width: 960px) {
    margin: 0;

    box-shadow: unset;
    > div {
      margin-top: 0px;
    }
  }
}

.client-list-container {
  height: 100%;
  width: 100%;
  background: #fff;
  width: 100%;
  @include box-shadow(0 2px 5px 0 rgba(22, 18, 20, 0.25));

  background-color: $color-white;
  max-height: 100%;

  @media (max-width: 960px) {
    box-shadow: none;
  }

  .client-list {
    .ReactTable {
      .rt-thead.-filters {
        .rt-tr {
          > :first-of-type {
            padding-left: 20px;
          }
          > :last-of-type {
            //padding-right: 12px;
            padding-right: 6px;
          }
        }

        .rt-th {
          padding: 0 0 0 5px;
          justify-content: flex-start;
        }
      }
    }

    &__filter {
      width: 99%;

      &--wide {
        width: 99%;
      }

      &--small-padding {
        .lp-input__field {
          padding: 0 0 0 4px;
        }
      }

      & .table-filters__input .lp-input__field {
        width: 100%;
      }
    }
  }
}

.service-history-list {
  width: 100%;
  background-color: $color-white;
  height: 100%;
  border-radius: 5px;

  // @include respond-to($large-screen) {
  //   height: calc(100vh - 260px);
  // }

  // @include respond-to($medium-screen) {
  //   height: calc(100vh - 240px);
  // }

  // @include respond-to($small-screen) {
  //   height: calc(100vh - 210px);
  // }

  &--pagination {
    height: 100% !important;
    border-radius: 5px;
  }

  &__service {
    font-size: rem(16);
    font-weight: 300;
    color: $dark-grey;
    text-align: left;
    height: 59px;
    width: 100%;
    border-right: 0;
    justify-content: flex-start !important;
    padding-left: 15px !important;

    @include respond-to($mobile-screen) {
      height: 86px;
    }

    .strong {
      font-size: 14px;
      color: $navy-blue;
      font-weight: 400;
      @include respond-to($mobile-screen) {
        height: 30px;
        margin-top: 4px;
      }
    }

    &-admin {
      height: 32px;
    }

    &:nth-child(-n + 1) {
      padding-left: 25px !important;
    }

    &:nth-child(-n + 3) {
      justify-content: initial !important;
    }

    @include respond-to($large-screen) {
      font-size: rem(15);
    }

    @include respond-to($medium-screen) {
      font-size: rem(13);
    }

    @include respond-to($small-screen) {
      font-size: rem(12);
    }

    &:last-child {
      border-bottom-width: 0;
    }

    .service__date {
      flex-shrink: 0;
      flex-grow: 0;
      width: 20% !important;
    }

    .service__icon {
      @include flex(flex-start);

      .icon {
        width: 22px;
        height: 22px;
        fill: $dark-grey;

        @include respond-to($large-screen) {
          width: 20px;
          height: 20px;
        }

        @include respond-to($medium-screen) {
          width: 18px;
          height: 18px;
        }

        @include respond-to($small-screen) {
          width: 16px;
          height: 16px;
        }
      }
    }

    .service__lawyer {
      width: 15%;
    }

    .service__time {
      width: 18%;
    }

    .service__price {
      width: 20%;
    }

    .service__service {
      width: 20%;
    }

    &.noPadding {
      padding-left: 0 !important;
    }

    &--mobile {
      height: 72px;
      .service__text {
        color: $gray-400;
        font-size: 12px;
        height: 30px;
        display: block;
        margin-top: 3px;
        font-weight: normal;
      }
    }
  }

  .service-history-list__service-active {
    background-color: $color-cream;
  }
}

.service-history-list--border {
  border-right: 1px solid $color-grey;
}
