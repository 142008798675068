.navbar {
  height: calc(100% - 80px);
  width: 50px;
  position: fixed;
  z-index: 1;
  top: 80px;
  left: 0;
  background-color: $color-white;
  overflow-x: hidden;
  border-right: 1px solid $light-grey;
  @include flex;
  flex-direction: column;

  &__right {
    right: 0;
    left: initial;
    border-left: 1px solid $color-grey;
    border-right-width: 0;
    height: 100%;
    width: 56px;
    position: initial;
    top: 0;
  }

  &__option {
    cursor: pointer;
    flex: 1;
    width: 100%;

    @include flex;
    flex-direction: column;
    border: 0;
    border-top: 1px solid $light-grey;
    margin: 0;
    background-color: #fff;

    .option__image {
      width: 33px;
      height: 33px;
      fill: $dark-grey;

      @include respond-to($large-screen) {
        width: 30px;
        height: 30px;
      }

      @include respond-to($medium-screen) {
        width: 26px;
        height: 26px;
      }

      @include respond-to($small-screen) {
        width: 23px;
        height: 23px;
      }
    }

    .option__name {
      width: 0;
      font-size: rem(0);
      font-weight: 300;
      text-align: center;
      color: $dark-grey;
    }
  }

  .selected {
    background-color: $color-green;

    .option__image {
      fill: $dark-green;
    }

    .option__name {
      color: $color-white;
    }
  }

  .disable {
    background-color: $color-grey;
    cursor: not-allowed;
  }
  .secondSelect {
    background-color: white;
    .option__image {
      fill: #8ec04b;
    }
  }
}

.navbar-movement {
  position: fixed;
  right: 0;
  z-index: 50;
  top: calc(50% + 40px);
  transform: translateY(-50%);
  width: 40px;
  height: 80px;
  border-radius: 100% 0 0 100% / 50% 0 0 50%;
  background-color: $color-green;
  @include flex;
  cursor: pointer;

  @include respond-to($medium-screen) {
    top: calc(50% + 35px);
  }

  @include respond-to($small-screen) {
    top: calc(50% + 25px);
  }

  &--padding {
    right: 54px;

    @include respond-to($medium-screen) {
      right: 54px;
    }

    @include respond-to($small-screen) {
      right: 50px;
    }
  }

  & .accordion-arrow {
    width: 13px;
    height: 20px;
    fill: $color-white;
    @include transform(rotateZ(180deg));
    @include transition-very-slow;

    &__rotate {
      @include transform(rotateZ(0deg));
    }
  }
}
