// @import "src/variables";
// body.videoChatPage {
//   padding: 0;
//   margin:0;
//   height: 100vh; /* Fallback for browsers that do not support Custom Properties */
//   height: calc(var(--vh, 1vh) * 100);
//   overflow: hidden;

// }

// html.videoChatPage {
//   height: 100% !important;
// }

// .videoChat {
//   height: 100vh; /* Fallback for browsers that do not support Custom Properties */
//   height: calc(var(--vh, 1vh) * 100) !important;
//   .configWrapper {
//     text-align: center;
//     button {
//       display: inline-flex;
//       justify-content: center;
//       align-items: center;
//       width: 80px;
//       height: 80px;
//       border-radius: 50%;
//       outline: none;
//       cursor: pointer;
//       border: none;
//       background-repeat: no-repeat;
//       background-position: center;
//       background-color: #c0c0c0;
//       color: #fff;
//       box-shadow: 0px 1px 3px 0px rgba(27, 28, 31, 0.3);
//       cursor: pointer;

//       @media(max-width:962px){

//         width: 60px;
//         height: 60px;

//         svg {
//           width: 24px !important;

//         }
//       }

//       &.active {
//         background: white;
//         svg {
//           fill: $navy-blue;
//         }
//       }

//       svg {
//         width: 32px;
//         height: auto;
//         fill: #fff;
//       }

//       &.red {
//         background: #e44343;
//         color: #fff;
//       }

//       &.chat {
//         position: absolute;
//         right: 56px;
//         background-color: #fff;

//         @media(max-width:962px){
//           position: relative;
//           right: 0;
//         }

//        svg {
//          fill: $navy-blue;
//        }

//        &.unread {
//          background: $base-green;

//          svg {
//            fill: white;
//          }
//        }
//       }
//     }

//     .bottomButtons {
//       display: flex;
//       align-items: center;
//       justify-content: center;

//       button {
//         &:not(:last-child) {
//           margin-right: 20px;
//         }
//       }
//     }
//   }

//   .textChat {
//     background: #fff;
//     box-shadow: -1px 0px 3px 0px rgba(50, 50, 50, 0.2);
//     transition: 0.3s;
//     width: 360px;
//     max-width: 100%;
//     z-index: 1010;
//     position: fixed;

//     overflow: hidden;
//     height: 100%;

//     .header {
//       background: #fff;
//       box-shadow: 1px 1px 3px 0px rgba(50, 50, 50, 0.2);
//       padding: 10px 16px;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       position: relative;
//       height: 61px;

//       .title {
//         color: $base-green;
//         font-size: 22px;
//         line-height: 22px;
//         text-shadow: 0 0 1px $base-green;
//         font-weight: 700;
//       }

//       .subTitle {
//         font-size: 14px;
//         margin-top: 2px;
//       }

//       .close {
//         cursor: pointer;
//         margin-left: 16px;
//         transition: 0.1s;
//         display: flex;
//         align-items: center;

//         svg {
//           width: 32px;
//           height: 32px;
//           fill: #8FA3AA;
//           margin-left: 8px;
//         }

//         &:hover {
//           svg {
//             transform: scale(1.1);
//             fill: #a3b2b7;
//           }
//         }
//       }
//     }

//     .messages {
//       padding: 16px;
//       overflow-y: scroll;
//       height: calc(100vh - 200px);

//       .single {
//         display: flex;
//         justify-content: flex-start;
//         margin-bottom: 10px;

//         .avatar {
//           margin-right: 14px;
//           img {
//             width: 41px;
//             height: 41px;
//             object-fit: cover;
//             border-radius: 50%;
//             box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
//           }
//         }

//         .messageContainer {
//           display: flex;
//           flex-direction: column;
//           align-items: flex-start;

//           .time {
//             color: $gray-400;
//             font-size: 12px;
//           }

//           .text {
//             display: inline-block;
//             padding: 10px;
//             border-radius: 4px;
//             color: #4B555F;
//             background-color: #f8f8f8;
//             font-size: 14px;
//             margin-top: 2px;
//             box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
//           }
//         }

//         &.isMine {
//           justify-content: flex-end;

//           .messageContainer {
//             display: flex;
//             flex-direction: column;
//             align-items: flex-end;

//             .text {
//               background-color: #d5d7d9;
//               color: #4B555F;
//               box-shadow: none;
//               margin-top: 4px;
//             }
//           }

//         }

//       }
//     }

//     .replayContainer {
//       position: absolute;
//       bottom: 0;
//       background: #fff;
//       box-shadow: 0px -1px 3px 0px rgba(50, 50, 50, 0.2);
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       width: 100%;
//       padding: 16px;
//       height: 78px;

//       input[type="text"] {
//         background: #f8f8f8;
//         border: 1px solid #e1e6e8;
//         font-size: 14px;
//         padding: 14px;
//         width: calc(100% - 53px);
//         border-radius: 4px;
//       }

//       input[type="submit"] {
//         font-size: 14px;
//         padding: 14px;
//         margin-left: 8px;
//         border-radius: 50%;
//         box-shadow: 0px 0px 3px 0px rgba(50, 50, 50, 0.2);
//         width: 46px;
//         height: 46px;
//         background-size: 50%;
//         background-repeat: no-repeat !important;
//         background-position: center !important;
//         border: none;
//         background: #fff;
//         cursor: pointer;
//       }
//     }
//   }

//   @media(max-width:962px){

//    #camera {
//      width: 160px;
//      height: 120px;
//    }
//   }

//   @media(max-width:560px){

//     #camera {
//       width: 120px;
//       height: 90px;
//     }
//    }
// }

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

@import "src/variables";
body.videoChatPage {
  padding: 0;
  margin: 0;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

html.videoChatPage {
  height: 100% !important;
}

.videoChat {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100) !important;
  .configWrapper {
    text-align: center;
    button {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      outline: none;
      cursor: pointer;
      border: none;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #c0c0c0;
      color: #fff;
      -webkit-box-shadow: 0px 1px 3px 0px rgba(27, 28, 31, 0.3);
      box-shadow: 0px 1px 3px 0px rgba(27, 28, 31, 0.3);
      cursor: pointer;

      @media (max-width: 962px) {
        width: 60px;
        height: 60px;

        svg {
          width: 24px !important;
        }
      }

      &.active {
        background: white;
        svg {
          fill: $navy-blue;
        }
      }

      svg {
        width: 32px;
        height: auto;
        fill: #fff;
      }

      &.red {
        background: #e44343;
        color: #fff;
      }

      &.chat {
        position: absolute;
        right: 56px;
        background-color: #fff;

        @media (max-width: 962px) {
          position: relative;
          right: 0;
        }

        svg {
          fill: $navy-blue;
        }

        &.unread {
          background: $base-green;

          svg {
            fill: white;
          }
        }
      }
    }

    .bottomButtons {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      button {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  .textChat {
    background: #fff;
    -webkit-box-shadow: -1px 0px 3px 0px rgba(50, 50, 50, 0.2);
    box-shadow: -1px 0px 3px 0px rgba(50, 50, 50, 0.2);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    width: 360px;
    max-width: 100%;
    z-index: 1010;
    position: fixed;

    overflow: hidden;
    height: 100%;

    .header {
      background: #fff;
      -webkit-box-shadow: 1px 1px 3px 0px rgba(50, 50, 50, 0.2);
      box-shadow: 1px 1px 3px 0px rgba(50, 50, 50, 0.2);
      padding: 10px 16px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      height: 61px;

      .title {
        color: $base-green;
        font-size: 22px;
        line-height: 22px;
        text-shadow: 0 0 1px $base-green;
        font-weight: 700;
      }

      .subTitle {
        font-size: 14px;
        margin-top: 2px;
      }

      .close {
        cursor: pointer;
        margin-left: 16px;
        -webkit-transition: 0.1s;
        -o-transition: 0.1s;
        transition: 0.1s;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        svg {
          width: 32px;
          height: 32px;
          fill: #8fa3aa;
          margin-left: 8px;
        }

        &:hover {
          svg {
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
            fill: #a3b2b7;
          }
        }
      }
    }

    .messages {
      padding: 16px;
      overflow-y: scroll;
      height: calc(100vh - 200px);

      .single {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        margin-bottom: 10px;

        .avatar {
          margin-right: 14px;
          img {
            width: 41px;
            height: 41px;
            -o-object-fit: cover;
            object-fit: cover;
            border-radius: 50%;
            -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
          }
        }

        .messageContainer {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;

          .time {
            color: $gray-400;
            font-size: 12px;
          }

          .text {
            display: inline-block;
            padding: 10px;
            border-radius: 4px;
            color: #4b555f;
            background-color: #f8f8f8;
            font-size: 14px;
            margin-top: 2px;
            -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
          }
        }

        &.isMine {
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;

          .messageContainer {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;

            .text {
              background-color: #d5d7d9;
              color: #4b555f;
              -webkit-box-shadow: none;
              box-shadow: none;
              margin-top: 4px;
            }
          }
        }
      }
    }

    .replayContainer {
      position: absolute;
      bottom: 0;
      background: #fff;
      -webkit-box-shadow: 0px -1px 3px 0px rgba(50, 50, 50, 0.2);
      box-shadow: 0px -1px 3px 0px rgba(50, 50, 50, 0.2);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 100%;
      padding: 16px;
      height: 78px;

      input[type="text"] {
        background: #f8f8f8;
        border: 1px solid #e1e6e8;
        font-size: 14px;
        padding: 14px;
        width: calc(100% - 53px);
        border-radius: 4px;
      }

      input[type="submit"] {
        font-size: 14px;
        padding: 14px;
        margin-left: 8px;
        border-radius: 50%;
        -webkit-box-shadow: 0px 0px 3px 0px rgba(50, 50, 50, 0.2);
        box-shadow: 0px 0px 3px 0px rgba(50, 50, 50, 0.2);
        width: 46px;
        height: 46px;
        background-size: 50%;
        background-repeat: no-repeat !important;
        background-position: center !important;
        border: none;
        background: #fff;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 962px) {
    #camera {
      width: 160px;
      height: 120px;
    }
  }

  @media (max-width: 560px) {
    #camera {
      width: 120px;
      height: 90px;
    }
  }
}
