.no-data-view {
  @include flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  height: 80%;
  position: absolute;
  top: 10%;

  &__image {
    padding-top: 30px;
    .icon {
      width: 130px;
      height: 130px;
      fill: $color-green;
      margin-bottom: 40px;

      @include respond-to($large-screen) {
        width: 115px;
        height: 115px;
        margin-bottom: 35px;
      }

      @include respond-to($medium-screen) {
        width: 100px;
        height: 100px;
        margin-bottom: 30px;
      }

      @include respond-to($small-screen) {
        width: 80px;
        height: 80px;
        margin-bottom: 25px;
      }
    }
  }

  &__title {
    font-weight: 500;
    font-size: rem(30);
    letter-spacing: 0.9px;
    color: $color-green;
    margin-bottom: 40px;
    width: 387px;

    @include respond-to($large-screen) {
      font-size: rem(26);
      margin-bottom: 35px;
    }

    @include respond-to($medium-screen) {
      font-size: rem(22);
      margin-bottom: 30px;
    }

    @include respond-to($small-screen) {
      font-size: rem(18);
      margin-bottom: 25px;
    }
  }

  &__text {
    font-size: rem(24);
    font-weight: 300;
    letter-spacing: 0.6px;
    text-align: center;
    color: $color-black;
    width: 600px;

    @include respond-to($large-screen) {
      font-size: rem(22);
    }

    @include respond-to($medium-screen) {
      font-size: rem(20);
      width: 80%;
    }

    @include respond-to($small-screen) {
      font-size: rem(18);
    }
  }

  @media (max-width: 768px) {
    position: unset;
  }
}
