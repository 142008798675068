.mail-config {
  width: 100%;
  padding: 20px 50px 0 30px;
  .input__info {
    height: 0;
  }
  input,
  select {
    font-size: inherit;
    height: auto;
    padding: 3px;
    max-width: initial;
    height: 30px;
    text-align: left;
  }
  .header__text {
    color: $navy-blue;
    font-weight: 300;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid $color-grey;
    line-height: 30px;
    font-size: rem(16);
  }
  .config-header {
    padding: 20px 0;
  }
  &__header {
    padding-top: 20px;
    .header__text {
      font-size: rem(18);
      line-height: 30px;
    }
  }
  &__mail-footer {
    .mail-footer {
      &__header {
        padding: 20px 0;
      }
      &__content--editor {
        min-height: 200px;

        .DraftEditor-root {
          min-height: 200px;
          height: 200px;
        }
      }
    }
  }
  &__redirect {
    .redirect__destinations {
      .destinations__row-item {
        @include flex(flex-start, center);
        font-size: rem(16);
        padding: 10px 0;
        .row-item__index {
          padding: 0 20px 0 10px;
          color: $dark-grey;
        }
      }
    }
  }
  &__auto-response {
    .auto-response__form {
      .form__form-row {
        @include flex(flex-start, center);
        padding: 10px 0 10px 10px;
        .form-row {
          &__label {
            font-size: rem(16);
            color: $navy-blue;
            width: 20%;
            &--message {
              align-self: flex-start;
              padding-top: 5px;
            }
          }
          &__input {
            &--date-from {
              width: 200px;
            }
            &--subject {
              width: 500px;
            }
            &--message {
              width: 80%;
            }
            &--reply-frequency {
              select {
                padding: 3px;
                width: 200px;
                border-radius: 5px;
                font-size: rem(16);
                color: $dark-grey;
                box-shadow: 0px 2px 5px 0 rgba(22, 18, 20, 0.25);
              }
            }
            &--editor {
              min-height: 200px;

              .DraftEditor-root {
                min-height: 200px;
                height: 200px;
              }
            }
          }
        }
      }
    }
  }
  &__control-buttons {
    padding: 20px 0;
    .lp-button:first-child {
      margin-right: 20px;
    }
  }
}
