.clientbox {
  padding-top: 20px;
  position: relative;
  min-height: 60px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid silver;
}
.clientbox__client {
  color: #000;
  font-size: 1rem;
  font-weight: 300;
  .client__name {
    font-size: 1.05rem;
    color: #000;
    letter-spacing: 1px;
    margin: 0;
  }
  .client__data {
    color: $dark-grey;
    margin: 2px 0;
  }
}

.clientbox__buttons {
  position: absolute;
  top: 20px;
  right: 0;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 20px;
  .lp-button-circle--green {
    margin-left: 12px;
  }
  .lp-button-square--green {
    margin-left: 12px;
  }
}

.lp-button-circle--green {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  min-width: 40px;
  object-fit: contain;
  background-color: #fff;
  fill: #8ec04b;
  border-radius: 50%;
  cursor: pointer;
  -webkit-box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3);
  -moz-box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3);
  box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3);
  transition: all 0.4s ease-in-out;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  justify-content: center;
  align-items: center;
  background-color: #8ec04b;
  fill: #fff;
  -webkit-box-shadow: initial;
  -moz-box-shadow: initial;
  box-shadow: initial;
  border: 0;
  &:disabled {
    background-color: silver;
    border-color: silver;
    cursor: not-allowed;
    &:hover {
      background-color: silver;
      border-color: silver;
    }
  }
  &:hover {
    background-color: #8ec04b;
    fill: #fff;
    background-color: #d2e6b7;
  }
  .icon {
    width: 30px;
    height: 20px;
    color: white;
  }
}
.lp-button-circle--green-invert {
  background-color: #fff !important;
  border: 1px solid #8ec04b;

  &:hover {
    -webkit-box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3) !important;
    -moz-box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3) !important;
    box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3) !important;
  }
  .icon * {
    fill: #8ec04b;
    width: 30px;
    height: 20px;
  }
}
.lp-button-square--green {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  min-width: 40px;
  object-fit: contain;
  background-color: #fff;
  fill: #8ec04b;
  border-radius: 50%;
  cursor: pointer;
  -webkit-box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3);
  -moz-box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3);
  box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3);
  transition: all 0.4s ease-in-out;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  border: 1px solid #8ec04b;
  -webkit-box-shadow: initial;
  -moz-box-shadow: initial;
  box-shadow: initial;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.4s ease-in-out;
  &:hover {
    background-color: #8ec04b;
    fill: #fff;
    background-color: rgba(0, 0, 0, 0);
  }
  &:disabled {
    background-color: silver;
    border-color: silver;
    cursor: not-allowed;
    .icon {
      transition: all 0.4s ease-in-out;
      fill: #fff;
    }
    &:hover {
      transition: all 0.4s ease-in-out;
      background-color: silver;
      border-color: silver;
      cursor: not-allowed;
    }
  }
  &:focus {
    transition: all 0.4s ease-in-out;
    border-width: 2px;
  }
  .icon {
    width: 30px;
    height: 20px;
    fill: #8ec04b;
  }
}
.lp-button-square--green__focus {
  background-color: #fff;
  border-color: #666;
  .icon * {
    transition: all 0.4s ease-in-out;
    fill: #666 !important;
  }
  &:disabled {
    background-color: #fff;
    border-color: #666;
    .icon * {
      fill: #666 !important;
    }
  }
  &:hover {
    transition: all 0.4s ease-in-out;
    background-color: #fff !important;
    border-color: #666;
  }
}
