.edit-client-form {
  position: relative;
  width: 100%;
  font-size: rem(18);
  color: $navy-blue;
  font-weight: 300;
  padding-bottom: 30px;

  & .button {
    margin: 10px 0 0 2px;
    height: 40px !important;
  }

  & .lp-button {
    margin: 10px 0 0 2px;
    line-height: 36px !important;
    height: 40px !important;
    font-weight: 500 !important;
    position: relative;

    .check {
      position: absolute;
      left: 4px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: auto;
    }
  }

  &__header {
    margin-top: 10px;
    height: 60px;
    line-height: 60px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid $color-grey;
    @include flex(flex-start);

    & .header__client {
      text-align: left;
    }

    & .header__password {
      position: absolute;
      top: 0;
      right: 0;
      @include flex;

      & .password__button {
        margin-left: 20px;
        background-color: $color-green;
        cursor: pointer;
        @include transition-very-slow;

        & .icon {
          fill: $color-white;
        }

        &:hover {
          background-color: $light-green;
          border-color: $light-green;
        }
      }
    }
  }

  &__main {
    @include flex(flex-start, flex-start);
    flex-direction: column;

    & .main__contact {
      @include grid(2, 40px);
    }

    & .main__client {
      @include grid(2, 10px 40px);
    }

    & .main__minor {
      @include grid(2, 10px 40px);
    }

    & .main__address {
      @include grid(2, 10px 40px);
      grid-template-columns: 60% auto;
    }

    & .main__company {
      @include grid(1, 10px 0);

      & .company__name {
        width: 100%;
        @include grid(1);
      }

      & .company__details {
        @include grid(3, 40px);
      }
    }
  }

  &__form-wrapper {
    width: 100%;

    & .form-wrapper__header {
      width: 100%;
      height: 30px;
      line-height: 30px;
      margin-top: 15px;
      margin-bottom: 10px;
      padding-bottom: 5px;
      border-bottom: 1px solid $color-grey;
      @include flex(flex-start);

      & .checkbox-wrapper {
        margin-right: 10px;
      }
    }

    & .form-wrapper__content {
      width: 100%;
      height: auto;
      max-height: 0;
      padding: 0;
      overflow: hidden;
      transition: max-height 1s ease-out;
      @include transition-very-slow;
      transition-delay: 1s;

      &--shown {
        max-height: 9999px;
        padding: 5px;
        transition: max-height 3s ease-in;
      }
    }
  }

  &__input {
    width: 100%;
    @include flex;

    & .input__button {
      margin-left: 10px;

      & .lawyer-eye {
        width: 23px;
        height: 14px;

        @include respond-to($large-screen) {
          width: 21px;
          height: 13px;
        }

        @include respond-to($medium-screen) {
          width: 19px;
          height: 12px;
        }

        @include respond-to($small-screen) {
          width: 18px;
          height: 11px;
        }
      }
    }

    & .lp-input {
      &__info {
        height: 0;
        width: 0;
      }

      &__field {
        height: 40px;
        font-size: rem(16);
        font-weight: 300;
        text-align: left;
        padding-left: 10px;
        margin-left: 2px;
        max-width: initial;
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }

  &__residence {
    @include grid(3, 10px);
    grid-template-columns: 40% 40% auto;
    align-items: center;

    & .lp-input {
      min-width: initial;

      &__info {
        height: 0;
        width: 0;
      }

      &__field {
        height: 40px;
        font-size: rem(16);
        font-weight: 300;
        text-align: left;
        padding-left: 10px;
        margin: 2px;
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }

  & .call-grid {
    width: 100%;
    @include grid(2, 10px);
    grid-template-columns: auto auto;
  }

  .formControll {
    margin-bottom: 10px;
    width: 100%;
    margin-left: 2px;

    .select {
      //width: 100%;
      box-shadow: none;
      border: 1px solid #e9eaef;
      transition: 0s;

      .Mui-disabled {
        color: rgb(84, 84, 84);
        > div {
          color: rgb(84, 84, 84);
        }
      }

      &.MuiOutlinedInput-root.Mui-disabled {
        background: transparent;
        border: 1px solid #ffff;
      }

      &:not(.Mui-disabled) {
        .MuiOutlinedInput-input {
          @include box-shadow(0px 2px 5px 0 rgba(22, 18, 20, 0.25));
        }
      }

      .sLabel {
        margin-left: 8px;
      }

      > .MuiOutlinedInput-input {
        padding: 10.5px 14px;
      }
    }

    .chipContainer {
      margin-bottom: 4px;

      .chip {
        float: left;
        margin-bottom: 4px;
        height: 26px;
        margin-right: 4px;
      }
    }
  }
}

.subscriptionPeepper {
  .MuiMenuItem-root {
    padding: 0;
  }

  .MuiListSubheader-root {
    line-height: 35px;
    font-weight: bold;
    //border-bottom: 1px solid #dde3e5;
  }

  .MuiIconButton-label {
    width: 19px;
    justify-content: flex-start;

    .checkbox {
      height: 16px;
      fill: #878787;
    }
  }
  .MuiListItemText-primary {
    font-size: 14px;
  }
}

.lp-input {
  min-width: unset !important;
}
