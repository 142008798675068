@import "./src/variables";

.Footer {
  background: #fff;
  border-top: 1px solid #f8f8f8;
  margin-top: 40px;
  //border-top: 1px solid #dde3e5;footer1

  .footer1 {
    margin-top: 16px;
    margin-bottom: 24px;
    display: none;

    @media all and (max-width: 768px) {
      text-align: center;
    }

    .title {
      font-weight: bold;
    }

    .links {
      padding-inline-start: 0;
      li {
        list-style: none;
        margin-bottom: 8px;

        a {
          font-weight: 300;
          text-decoration: none;
          color: #000;
          //font-size: 14px;
        }
      }
    }
  }

  .footer2 {
    //background: #f8f8f8;
    padding-top: 80px;
    color: #4b555f;
    //font-size: 14px;
    box-shadow: 0px -2px 5px -2px rgba(0, 0, 0, 0.25);
    line-height: 21px;

    @media all and (max-width: 768px) {
      text-align: center;
    }

    > div {
      border-bottom: 1px solid #e0e2e1;
      padding-bottom: 80px;
    }

    .logo {
      display: block;
      margin: 0 0 23px 0;
      //width: 260px;
      max-width: 94%;

      @media all and (max-width: 768px) {
        margin: 0 auto 32px auto;
      }
    }

    .comainyInfo {
      @media all and (max-width: 599px) {
        margin-bottom: 18px;
      }

      @media all and (min-width: 768px) {
        padding-right: 16px;
      }
    }

    .footerRow {
      display: flex;
      justify-content: center;

      @media all and (max-width: 599px) {
        margin-top: 32px;
      }

      .title {
        margin: 0;
        color: #000;
        text-align: center;
      }

      .cont {
        padding: 0 80px;
        border-left: 1px solid #e2e2e2;
        border-right: 1px solid #e2e2e2;

        @media all and (max-width: 1100px) {
          padding: 0 32px;
        }

        @media all and (max-width: 767px) {
          padding: 0 16px;
          border: none;
        }

        .links {
          padding-inline-start: 0;
          list-style: none;

          li {
            a {
              text-decoration: none;
              color: $gray-500;

              &:hover {
                color: $base-green;
              }
            }
          }
        }
      }
    }
  }

  .footer3 {
    color: #4b555f;
    padding: 32px 0;

    > div {
      display: flex;
      justify-content: space-between;

      @media all and (max-width: 767px) {
        flex-direction: column;
        justify-content: center;

        > div {
          justify-content: center;

          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }

      > div {
        display: flex;
        align-items: center;

        a {
          margin-left: 4px;

          img {
            margin-left: 8px;
          }
        }

        @media all and (max-width: 767px) {
          flex-direction: column;
          justify-content: center;

          > a {
            margin-top: 16px;
          }
        }
      }
    }

    hr {
      border: 1px solid #f2f4f5;
    }

    //.coppytight {
    //  padding: 24px 0;
    //  font-size: 13px;
    //  line-height: 32px;
    //  color: $gray-500;
    //  font-weight: 300;
    //
    //  a {
    //    color: $black;
    //  }
    //}
  }

  .social {
    margin-top: 16px;

    a {
      outline: none;
      transition: 0.3s;

      img {
        padding: 0 8px;
      }

      &:hover {
        img {
          transform: scale(1.06);
        }
      }
    }
  }
}
