.service-cards {
  position: relative;
  width: 100%;
  @include grid(3, 20px 0);

  &__card {
    min-width: 110px;
    height: 100%;
    width: 100%;
    min-width: 33%;
    max-width: 270px;

    position: relative;
    padding: 0 10px;
    border-right: 1px solid $color-grey;
    @include flex(flex-start);

    &:nth-child(3n) {
      border-width: 0;
    }

    & .card__content {
      font-size: rem(16);
      font-weight: 300;
      color: $navy-blue;

      @include respond-to($large-screen) {
        font-size: rem(15);
      }

      @include respond-to($medium-screen) {
        font-size: rem(14);
      }

      @include respond-to($small-screen) {
        font-size: rem(12);
      }

      & .content__name {
        color: $color-black;
        font-size: rem(18);

        @include respond-to($large-screen) {
          font-size: rem(17);
        }

        @include respond-to($medium-screen) {
          font-size: rem(16);
        }

        @include respond-to($small-screen) {
          font-size: rem(14);
        }
      }
    }

    & .card__icons {
      position: absolute;
      top: 0;
      right: 20px;

      @include respond-to($medium-screen) {
        right: 15px;
      }

      @include respond-to($small-screen) {
        right: 10px;
      }

      & .icons__icon {
        $size: 30px;
        width: $size;
        height: $size;
        min-width: $size;
        margin-bottom: 10px;

        @include respond-to($large-screen) {
          $size: 25px;
          width: $size;
          height: $size;
          min-width: $size;
        }

        @include respond-to($medium-screen) {
          $size: 22px;
          width: $size;
          height: $size;
          min-width: $size;
          margin-bottom: 8px;
        }

        @include respond-to($small-screen) {
          $size: 20px;
          width: $size;
          height: $size;
          min-width: $size;
          margin-bottom: 5px;
        }
        & .icon {
          width: 18px;
          height: 18px;

          @include respond-to($medium-screen) {
            width: 16px;
            height: 16px;
          }

          @include respond-to($small-screen) {
            width: 13px;
            height: 13px;
          }
        }
      }
    }
  }
}
