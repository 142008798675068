.accordion-wrapper {
  padding-bottom: 20px;
  position: relative;
  width: 100%;

  @include respond-to($medium-screen) {
    padding-bottom: 15px;
  }

  @include respond-to($small-screen) {
    padding-bottom: 10px;
  }

  .accordion__header {
    border-top: 1px solid $color-grey;
    margin-top: 20px;

    @include respond-to($medium-screen) {
      margin-top: 15px;
    }

    @include respond-to($small-screen) {
      margin-top: 10px;
    }

    & .header__icon {
      position: absolute;
      right: 0;
      bottom: 4px;
      fill: $dark-grey;
      cursor: pointer;
      padding: 5px;
      border-radius: 50%;

      &--hide {
        right: 20px;
      }

      &:hover {
        @include transition-very-slow;
        fill: $navy-blue;
      }

      .icon {
        width: 13px;
        height: 16px;
        @include transition-very-slow;
        @include transform(rotateZ(-90deg));

        &--rotate {
          @include transition-very-slow;
          @include transform(rotateZ(90deg));
        }
      }
    }
  }

  & .accordion__content {
  }
}
