/*
nagłówkek light - #989898
naglowek dartk - #4b555f;


Label: #4b555f
Value light - #989898

Value bold - #989898

text - #4b555f;

przycisko - Lato regular #4b555f;


*/

.view-message-wrapper {
  width: 100%;
  padding-bottom: 60px;
}

.message-header {
  padding: 10px 20px;
  border-bottom: solid 1px $color-grey;
  width: 100%;
  @include respond-to($medium-screen) {
    font-size: rem(14);
    padding: 10px 20px;
  }

  @include respond-to($small-screen) {
    font-size: rem(14);
    padding: 10px 10px;
  }
  @include flex(flex-start, center);
  .header-labels {
    color: $navy-blue;
    font-weight: 300;
    &__label {
      padding: 0;
      height: 25px;
    }
  }
  .header-values {
    padding-left: 20px;
    color: $gray-400;
    font-weight: 300;
    &__value {
      padding: 0;
      height: 25px;
    }
  }
}

.message-content {
  width: 100%;
  .message {
    &__message {
      color: $navy-blue;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
      font-weight: 300;
      border: none;
      padding: 20px;

      @include respond-to($small-screen) {
        padding: 10pxl;
      }

      span {
        font-size: rem(18);
        @include respond-to($large-screen) {
          font-size: rem(18);
        }
        @include respond-to($medium-screen) {
          font-size: rem(14);
        }
        @include respond-to($small-screen) {
          font-size: rem(12);
        }
      }
      .public-DraftEditor-content {
        padding: 0;
      }
    }
    &__controls {
      padding: 10px 20px;
      border-bottom: solid 1px $color-grey;
      border-top: solid 1px $color-grey;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      @include respond-to($medium-screen) {
        padding: 10px 20px;
      }

      @include respond-to($small-screen) {
        padding: 10px 10px;
      }

      .message-content__button {
        background-color: transparent;
        color: $navy-blue;
        font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI",
          "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
          "Helvetica Neue", sans-serif;
        font-weight: 400;
        border: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: rem(16);
        border-right: 1px solid $light-grey;
        cursor: pointer;
        padding: 0;

        @include respond-to($medium-screen) {
          font-size: rem(14);
        }

        @include respond-to($small-screen) {
          font-size: rem(14);
        }

        .icon {
          height: 22px;
          margin-right: 10px;
          fill: $dark-grey;
          color: $dark-grey;

          @include respond-to($medium-screen) {
            height: 16px;
          }

          @include respond-to($small-screen) {
            height: 12px;
          }
        }
        span {
          height: 100%;
        }
        &:disabled {
          color: $gray-400;
          font-weight: 300;
          cursor: default;

          .icon {
            height: 22px;
            margin-right: 10px;
            fill: $gray-300;
            color: $gray-300;
          }
        }

        &:nth-of-type(1) {
          justify-content: flex-start;
        }

        &:last-of-type {
          border-right: 0;
        }
      }
    }
    &__attachments {
      .attachments__title {
        padding: 40px 20px 20px;
        border-bottom: solid 1px $color-grey;

        @include respond-to($medium-screen) {
          padding: 40px 20px 20px;
        }

        @include respond-to($small-screen) {
          padding: 40px 10px 20px;
        }
      }
      .attachments__list {
        .attachment {
          width: 100%;
          padding: 5px 20px;
          border-bottom: solid 1px $color-grey;
          @include respond-to($medium-screen) {
            padding: 5px 20px;
          }

          @include respond-to($small-screen) {
            padding: 5px 10px;
          }
          @include flex(space-between, center);
          &__button {
            width: 20px;
            height: 20px;
            cursor: pointer;
            svg {
              height: 100%;
              width: 100%;
              fill: $dark-grey;
            }
            &--show {
              @include flex();
              border: solid 1px $dark-grey;
              border-radius: 50%;
              transform: rotateY(180deg);
              margin-right: 20px;
              svg {
                height: 10px;
                width: 10px;
              }
            }
            &--delete {
              margin-left: 10px;
            }
          }
          &__file-name {
            color: $dark-grey;
            font-size: rem(16);
          }
          .side-wrapper {
            @include flex();
          }
        }
      }
    }
  }
}

.messages-list {
  width: 100%;
  height: calc(100% - 57px);
  .attachments-icon {
    .icon {
      width: rem(18);
      height: rem(18);

      display: flex;
      .st0 {
        fill: #aaa;
      }
    }
  }

  .scroll-wrapper {
    height: 100%;
  }

  &--pagination {
    height: calc(100% - 165px) !important;
    .scroll-wrapper {
      height: calc(100% - 70px);
    }
  }

  @include respond-to($medium-screen) {
    height: calc(100% - 84px);
  }
  &__filter {
    width: 98%;

    &--wide {
      width: 99%;
    }

    & .table-filters__input .lp-input__field {
      width: 100%;
    }

    &.message-first-column {
      padding-left: 12px !important;
    }

    &--small-padding {
      .lp-input__field {
        padding: 0 0 0 4px;
      }
    }
  }

  &__message {
    &--no-padding-left {
      padding-left: 0px !important;
    }
    &.hidden {
      .icon {
        display: none;
      }
    }
    font-size: rem(14);
    font-weight: 400;
    color: $gray-400;
    text-align: left;
    min-height: 31px;
    width: 100%;
    padding-left: 12px !important;
    @include flex;

    &:nth-child(-n + 1) {
      padding-left: 0px !important;
    }

    &:nth-child(-n + 2) {
      padding-left: 0px !important;
    }

    &:last-of-type {
      padding: 0 !important;
    }

    &.message-first-column {
      padding-left: 12px !important;
    }

    @include respond-to($large-screen) {
      font-size: rem(14);
    }

    @include respond-to($medium-screen) {
      font-size: rem(12);
    }

    @include respond-to($small-screen) {
      font-size: rem(10);
    }

    & .attachments {
      width: rem(16);
      fill: $dark-grey;
    }

    & .message__icon {
      & .icon {
        width: 27px;
        height: 18px;
        fill: $dark-grey;
      }
    }

    &:last-child {
      border-bottom-width: 0px;
    }
  }

  .message {
    &-from {
      color: $navy-blue;
      font-weight: 300;
      .unread {
        font-weight: 400;
      }
    }

    &-service {
      font-weight: 300;
      .unread {
        font-weight: 400;
      }
      > div {
        display: flex;
        align-items: center;
      }
      &-number {
        color: $gray-400;
      }
      &-name {
        color: $navy-blue;

        max-width: calc(70% - 10px);
      }
    }

    &-date {
      color: $navy-blue;
      font-weight: 300;
      text-align: right;
      padding: 0 10px 0 0;

      .hour {
        color: $gray-400;
      }
      .unread {
        font-weight: 400;
      }
    }

    &-lawyer {
      display: flex;
      width: 100%;
      justify-content: center !important;
      padding-left: 0 !important;
    }

    &-topic {
      color: $gray-400;
      font-weight: 300;
    }
  }

  .messages-list__message-active {
    background-color: $color-cream;
  }

  .list-checkbox {
    height: rem(16);
    fill: $dark-grey;
    margin-left: 0px;
    .icon {
      height: 100%;
    }
  }
  .list-checkbox-disabled {
    height: rem(16);
    fill: $dark-grey;
    margin-left: 15px;
    background-color: grey;
    border-radius: 5px;
    .icon {
      height: 100%;
    }
  }
}
.messages-list-header {
  .icon {
    height: 100%;
    &.small {
      height: 70%;
      padding-left: 2px;
    }
  }

  &__controls {
    //border-top: solid 1px #c0c0c0;
    border-bottom: solid 1px $color-grey;
    @include flex(space-between);
    padding: 8px 10px;
    color: $dark-grey;
    fill: $dark-grey;
    font-size: rem(16);
    .flex {
      @include flex();
    }
    .control-icon {
      height: rem(16);
      padding-right: 10px;
      cursor: pointer;
    }
    .left-wrapper {
      @include flex(flex-start);
      &.hidden {
        div {
          display: none;
        }
      }
    }
    .right-wrapper {
      @include flex(flex-end);
    }
    .controls {
      &__select-all,
      &__selection-counter,
      &__delete-selected,
      &__settings {
        padding: 10px;
      }
      &__selection-counter .selection-counter {
        &__label {
          padding-right: 10px;
        }
      }
    }

    @include respond-to($large-screen) {
      font-size: rem(14);
    }

    @include respond-to($medium-screen) {
      font-size: rem(12);
    }

    @include respond-to($small-screen) {
      font-size: rem(10);
    }

    .MuiFormControlLabel-label {
      font-size: rem(16);
      @include respond-to($large-screen) {
        font-size: rem(14);
      }

      @include respond-to($medium-screen) {
        font-size: rem(12);
      }

      @include respond-to($small-screen) {
        font-size: rem(10);
      }
    }

    &.call-controls {
      max-height: 57px;
    }
  }
}

.message-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100%;
  z-index: 2;
}

.message-service-info,
.message-client-info {
  font-size: rem(14);
  color: $color-light-grey;
  padding: 5px 40px;
  width: 100%;
  border-bottom: solid 1px $color-grey;

  @include respond-to($medium-screen) {
    font-size: rem(12);
  }

  @include respond-to($small-screen) {
    font-size: rem(10);
  }

  &__list {
    width: calc(100%);
    .list__service-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .list__client-row {
      display: grid;
      grid-template-columns: 1fr 100px 1fr 3fr 50px 50px;
      border-bottom: solid 1px $color-grey;
      padding: 5px 20px;
    }
    .bold {
      color: $darkest-grey;
    }
  }
}

.message-service-info {
  padding: 5px 20px;

  @include respond-to($small-screen) {
    padding: 5px 10px;
  }

  &--nopadding {
    padding: 5px 0 !important;
  }
}

.ReactTable {
  .rt-thead.-filters {
    .rt-tr {
      > :first-of-type {
        padding-left: 20px;
      }
    }

    .rt-th {
      padding: 0 0 0 5px;
      justify-content: flex-start;
      &:nth-child(-n + 2) {
        padding-left: 2px;
      }
    }
  }
}
