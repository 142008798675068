.modal {
  & .confirm-modal {
    @include flex;
    flex-direction: column;
    padding: 15px;

    &__header {
      @include flex;
      flex-direction: column;

      & .header__top {
        font-size: rem(18);
        color: $navy-blue;
      }

      & .header__bottom {
        font-size: rem(16);
        margin: 20px;
        color: $dark-grey;
      }
    }

    & .button {
      $margin: 10px;
      margin: 20px $margin 0 $margin;
      min-width: 140px;
    }
  }

  &__close {
    width: 0;
    height: 0;
    padding: 0;
  }
}
