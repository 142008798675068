.upsert-service-form {
  .MuiFormControl-root {
    max-width: 100%;
    width: 100%;
    margin: 0 0 0 20px !important;
    .MuiInputBase-root {
      height: 40px;

      &.Mui-disabled {
        background-color: white;
        box-shadow: none;
      }
    }
  }

  .serviceDatePicker {
    margin: 0;
    margin-right: 8px;

    > div {
      padding-right: 0;
    }

    input {
      padding: 5px;
      width: 100%;
      margin: 0;
      box-shadow: none;
    }

    svg {
      fill: #d5d7d9;
    }
  }

  width: 100%;
  padding-top: 20px;
  position: relative;
  height: 100%;
  @include flex(flex-start, flex-start);
  flex-direction: column;

  &__buttons {
    @include flex;
    padding: 20px 0;

    & .button {
      margin: 0 20px 0 0 !important;
    }
  }

  &__header {
    width: 100%;
    height: 30px;
    line-height: 30px;
    color: $navy-blue;
    font-size: rem(18);
    font-weight: 300;
    border-bottom: 1px solid $color-grey;
    margin: 16px 0;
  }

  &__input {
    margin-top: 10px;

    &--center {
      @include flex;
      padding: 10px;
    }

    &--margin {
      margin-top: 0;
    }

    & .lp-input__info {
      height: 0;
    }

    & .lp-input__field {
      height: 40px;
      font-size: rem(16);
      font-weight: 300;
      text-align: left;
      padding-left: 10px;
      margin-left: 2px;
      max-width: initial;
      background-color: rgba(0, 0, 0, 0);
    }
  }

  &__select {
    &.lp-input__field {
      color: $dark-grey;
      height: 40px;
      font-size: rem(16);
      font-weight: 300;
      text-align: left;
      padding-left: 10px;
      margin-left: 2px;
      max-width: initial;
      background-color: rgba(0, 0, 0, 0);
    }
  }

  &__textarea {
    & .lp-input__field {
      height: 40px;
      font-size: rem(16);
      font-weight: 300;
      text-align: left;
      margin-left: 2px;
      max-width: 100%;
      min-width: 100%;
      min-height: 220px;
      max-height: 300px;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0);
    }
  }

  &__textarea_abusive {
    & .lp-input__field {
      height: 40px;
      font-size: rem(16);
      font-weight: 300;
      text-align: left;
      margin-left: 2px;
      max-width: 100%;
      min-width: 100%;
      min-height: 130px;
      max-height: 300px;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0);
    }
  }

  &__textarea_small {
    & .lp-input__field {
      height: 40px;
      font-size: rem(16);
      font-weight: 300;
      text-align: left;
      margin-left: 2px;
      max-width: 100%;
      min-width: 100%;
      min-height: 60px;
      max-height: 300px;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0);
    }
  }

  & .button {
    margin: 20px 0 0 2px;
    height: 40px;
    line-height: 36px;
  }

  & .button--margin {
    margin-top: 0px;
  }

  & .button--margin-right {
    margin-right: 20px;
  }

  &__datepicker {
    width: 100%;
  }

  .select_service:not(:first-of-type) {
    margin-left: 20px;
  }

  & .step__wrapper {
    width: 100%;
    @include flex;
    flex: 3 9;
    height: 50px;

    .upsert-service-form__datepicker {
      flex: 1;
    }

    .upsert-service-form__input {
      padding-left: 20px;
      min-width: 100px;
    }
  }

  & .step__loan-form__wrapper {
    width: 100%;
    @include flex;
    flex: 3 9;
    gap: 0.75rem;
    height: 50px;

    .upsert-service-form__datepicker {
      flex: 1;
    }

    .upsert-service-form__input {
      margin-top: 0px;
      padding-left: 0px;
      min-width: 100px;
    }
  }

  & .step__loan-form__column__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    // flex: 3 9;
    gap: 0.75rem;
  }

  &__button {
    font-size: rem(18);
    margin: 10px 0 10px 0;

    &--add {
      color: $color-green;
      @include transition-very-slow;
      background: none;
      border: 0;

      &:hover {
        color: $light-green;
      }

      &:disabled {
        color: $color-grey;
      }
    }

    &--remove {
      color: $color-grey;
      width: 50px;
      @include flex;
      @include transition-very-slow;
      padding-left: 15px;
      border-radius: 50%;
      background: 0;
      border: 0;

      &:hover {
        color: $light-grey;
      }

      &:disabled {
        color: $gray-300;
      }
    }

    &--remove_nopadding {
      color: $color-grey;
      width: 50px;
      @include flex;
      @include transition-very-slow;
      padding: 0px;
      border-radius: 50%;
      background: 0;
      border: 0;

      &:hover {
        color: $light-grey;
      }

      &:disabled {
        color: $gray-300;
      }
    }

    &--nomargin {
      margin: 0;
    }
  }

  &__form-wrapper {
    margin: 20px 0;
    border-radius: 5px;
    padding: 20px;
    @include flex;
    flex-direction: column;
    @include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3));

    & .form-wrapper__header {
      width: 100%;
      @include flex(flex-end, flex-end);

      & .header__icon {
        $size: 20px;
        width: $size;
        height: $size;

        background: none;
        border: 0;

        & .icon {
          $size: 20px;
          width: $size;
          height: $size;
          fill: $color-grey;
          cursor: pointer;
          @include transition-very-slow;

          &:hover {
            fill: $dark-grey;
          }
        }
      }
    }
  }
}
.documentationTab-tabs__nav {
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 7;

  & .documentationTab-tabs {
    padding-top: 10px;
    border-top: 1px solid $color-grey;

    & .step-tabs__nav {
      height: 40px;
    }

    & .step-tabs__nav .step-tabs__tab {
      color: $dark-grey;
    }

    & .step-tabs__nav .active {
      color: $color-black;
    }

    & .step-tabs__content {
      height: initial;
    }
  }

  &--addButtons {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & .addButton {
      padding-right: 25px;
      cursor: pointer;
      color: $color-green;
      @include transition(300, ease-in-out, all);
    }

    & .addButton:hover {
      color: $light-green;
    }

    & .addButton--active {
      color: $color-grey;
    }
  }

  & .documentDetailsInputs {
    &--input {
      & .lp-input__field {
        height: 30px;
        text-align: left;
        font-size: rem(16);
      }

      & .lp-input__field--textarea {
        height: 90px;
      }
    }

    &--circles {
      display: flex;
      padding: 15px;
      align-items: center;

      .fileName {
        position: absolute;
        right: calc(5px + 21%);
        padding-right: 10px;
      }

      label[for='file__upload'] {
        display: block;
        margin-bottom: 1em;
        font-size: 1em;
        color: #fff;
        opacity: 0.9;
        font-weight: bold;
      }

      & .file__upload {
        position: absolute;
        right: 5px;
        width: 21%;
        margin: 0;
        color: transparent;
        cursor: pointer !important;
      }
      & .file__upload::-webkit-file-upload-button {
        border: none;
        padding: 5px 12px;
        background: $color-green;
        color: #fff;
        font-size: 1em;
        transition: all 0.4s;
        cursor: pointer;
        border-radius: 20px;
      }

      .addButton {
        position: absolute;
        right: 5px;
        margin: 0;
      }

      & .circle-button {
        &:disabled {
          background-color: $color-grey;
          border-color: $color-grey;
          cursor: not-allowed;

          &:hover {
            background-color: $color-grey;
            border-color: $color-grey;
          }
        }
        font-weight: 600;
        margin-right: 20px;
        color: white;
        @include circle-button(40px, 40px);
        background-color: $color-green;
        fill: $color-white;
        @include box-shadow(initial);

        &-invert {
          background-color: $color-white !important;
          border: 1px solid $color-green;

          &:hover {
            @include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3) !important);
          }

          .icon {
            fill: $color-green;
          }
        }

        @include respond-to($medium-screen) {
          @include circle-button(35px, 35px);
          background-color: $color-green;
          fill: $color-white;
          @include box-shadow(initial);
        }

        @include respond-to($small-screen) {
          @include circle-button(30px, 30px);
          background-color: $color-green;
          fill: $color-white;
          @include box-shadow(initial);
        }

        .icon {
          width: 20px;
          height: 20px;

          @include respond-to($large-screen) {
            width: 19px;
            height: 19px;
          }

          @include respond-to($medium-screen) {
            width: 18px;
            height: 18px;
          }

          @include respond-to($small-screen) {
            width: 16px;
            height: 16px;
          }
        }

        &:hover {
          background-color: $light-green;
          color: black;
        }
      }
    }
  }
}

.upsert-service-form__documentList {
  .filters {
    display: grid;
    grid-template-columns: 40px 1fr 6fr;
    padding: 15px 0;
    grid-gap: 0 15px;
    border-bottom: 1px solid $light-grey;

    .filter__input {
      min-width: initial;
    }

    .filter__input .input__info {
      display: none;
    }

    .filter__input .lp-input__field {
      height: 30px;
      text-align: left;
      font-size: rem(14);
    }
  }

  & .active {
    background: $color-cream;
  }

  & .files__file {
    cursor: pointer;
    border-bottom: 1px solid $light-grey;
    padding: 5px 20px;
    @include flex(flex-start);

    & .file__icon {
      $size: 20px;
      min-width: $size;
      max-width: $size;
      min-height: $size;
      max-height: $size;
      border: 1px solid $dark-grey;
      border-radius: 50%;
      margin-right: 30px;
      @include flex;
      cursor: pointer;

      &:hover {
        border-color: $color-green;
        @include transform(rotateZ(-180deg));

        & .icon {
          fill: $color-green;
        }
      }

      @include respond-to($medium-screen) {
        margin-right: 15px;
      }

      @include respond-to($small-screen) {
        margin-right: 10px;
      }

      & .icon {
        width: 10px;
        height: 10px;
        fill: $dark-grey;
        @include transform(rotateZ(180deg));
      }
    }

    & .file__icon--active {
      border-color: $color-green;
      @include transform(rotateZ(-180deg));

      & .icon {
        fill: $color-green;
      }
    }

    & .file__name {
      font-size: rem(16);
      font-weight: 300;
      color: $dark-grey;
      margin-right: 30px;

      @include respond-to($large-screen) {
        font-size: rem(15);
      }

      @include respond-to($medium-screen) {
        font-size: rem(12);
      }

      @include respond-to($small-screen) {
        font-size: rem(12);
      }
    }
  }
}

.dynamic-upsert-service-form {
  & .upsert-service-form__header {
    margin-bottom: 15px;
    height: 40px;
    @include flex(space-between, flex-end);

    &--selectors {
      width: 40%;
      @include flex(space-around);

      &--select {
        padding: 2px 7px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background: #f0f0f0;
        font-size: rem(16);
      }
    }
  }

  .upsert-service-form__input {
    height: 40px;
    font-size: rem(16);
    font-weight: 300;
    text-align: left;
    padding-left: 10px;
    margin-left: 2px;
    max-width: initial;
    background-color: rgba(0, 0, 0, 0);
    min-width: unset;
  }

  .upsert-service-form__inputs-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 0 15px;

    .upsert-service-form__input--wrapper {
      grid-column-start: 1;
      grid-column-end: 7;

      & .upsert-service-form__input {
        height: 40px;
        width: 100%;
        font-size: rem(16);
        font-weight: 300;
        text-align: left;
        padding-left: 10px;
        margin-left: 2px;
        max-width: initial;
        background-color: rgba(0, 0, 0, 0);
      }
    }

    & .margin__top {
      margin-top: 20px;
    }

    & .triple__left {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    & .triple__center {
      grid-column-start: 3;
      grid-column-end: 5;
    }

    & .triple__right {
      grid-column-start: 5;
      grid-column-end: 7;
    }

    & .half__left {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    & .half__right {
      grid-column-start: 4;
      grid-column-end: 7;
    }

    .half {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    .upsert-service-form__label--wrapper {
      grid-column-start: 1;
      grid-column-end: 7;
      padding: 15px 0;
      position: relative;

      &:after {
        content: '';
        width: 100%;
        position: absolute;
        height: 1px;
        background: $color-green;
        bottom: 10px;
        left: 0;
      }
    }

    .upsert-service-form__datepicker--wrapper {
      width: initial;
      display: flex;
      flex-direction: row;
      padding-top: 15px;
      & .date-picker-label {
        width: 100%;
        margin-top: 5px;
        white-space: nowrap;
      }
      & .upsert-service-form__datepicker {
        margin-left: 15px;
        width: initial;
        height: 30px;
      }
    }

    &__textarea {
      grid-column-start: 1;
      grid-column-end: 7;

      & .lp-input__field {
        height: 40px;
        font-size: rem(16);
        font-weight: 300;
        text-align: left;
        margin-left: 2px;
        max-width: 100%;
        min-width: 100%;
        min-height: 220px;
        max-height: 300px;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0);
      }
    }

    .upsert-service-form__button {
      justify-self: flex-start;
      margin: 10px 0 0 0;
      grid-column-start: 1;
      grid-column-end: 7;
    }

    .upsert-service-form__checkbox--wrapper,
    .upsert-service-form__input-wrapper {
      display: flex;
      height: 60px;
      grid-column-start: 1;
      grid-column-end: 7;
      align-items: center;
      justify-content: flex-start;

      &-inside {
        @include flex;
        margin-right: 20px;
        &:focus {
          border: solid 1px $color-green;
          border-radius: 5px;
        }
      }

      & .checkbox-additional-input {
        width: 100%;
        margin: 0 0 0 15px;
      }

      &-title {
        white-space: nowrap;
        padding-right: 5px;
      }
    }

    .withAddition-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-start: 1;
      grid-column-end: 7;
      grid-gap: 0 15px;

      .addition__remove {
        justify-self: flex-end;
        margin: 0;
        padding: 0 10px;
        text-align: right;
        display: inline-block;
      }
    }

    & .clientsList {
      margin: 0;
      grid-column-start: 1;
      grid-column-end: 7;
    }
  }

  .step__wrapper {
    display: grid;
    grid-template-columns: 155px 1fr 1fr 3fr 45px;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-gap: 10px;
    margin-top: 10px;

    & .upsert-service-form__datepicker {
      width: initial;
    }

    & .select_service {
      width: initial;
      margin-left: initial;
    }

    & .upsert-service-form__input {
      width: initial;
      margin: initial;
    }

    & .upsert-service-form__button {
      margin: initial;
      grid-column: auto;
    }
  }
}

.react-autosuggest__container {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 3;

  &--open {
  }
}

.react-autosuggest__input {
  height: 40px;
  padding-left: 10px;
  width: 100%;
  margin: 10px 0 0 2px;
  max-width: 800px;
  border-radius: 5px;
  background-color: $color-white;
  font-size: rem(16);
  font-weight: 300;
  letter-spacing: 0.9px;
  display: inline-block;
  border: 1px solid $color-white;
  box-shadow: 0px 2px 5px 0px rgba(22, 18, 20, 0.25);
  -webkit-appearance: none;

  &--focused {
    border: 1px solid $color-green;
  }
}

.react-autosuggest__suggestions-container--open {
  position: absolute;
  z-index: 2;
  top: 50px;
  right: 0px;
  width: 50%;
  max-height: 400px;
  overflow: auto;

  @include box-shadow(0px 2px 5px 0 rgba(22, 18, 20, 0.25));
}

.react-autosuggest__suggestion {
  width: 100%;
  cursor: pointer;
  background: $color-white;
  padding: 15px 10px;
  @include box-shadow(0px 2px 5px 0 rgba(22, 18, 20, 0.25));
  &--highlighted {
    background: $color-cream;
  }
}
