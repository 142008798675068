.service-steps {
  &__header {
    padding-top: 20px;
    padding-bottom: 9px;

    @include respond-to($medium-screen) {
      padding-top: 15px;
      padding-bottom: 7px;
    }

    @include respond-to($small-screen) {
      padding-top: 10px;
      padding-bottom: 4px;
    }

    @include flex(flex-start);

    justify-content: flex-start;
    align-items: flex-start;

    & .header__button {
      @include flex;
      margin-right: 8px;

      &--version {
        & .button__text {
          color: $gray-400 !important;
        }
      }

      & .button__icon {
        cursor: pointer;
        @include flex;

        & .icon {
          fill: $dark-grey;
          width: 20px;
          height: 20px;
          @include transition-very-slow;

          @include respond-to($medium-screen) {
            width: 18px;
            height: 18px;
          }

          @include respond-to($small-screen) {
            width: 16px;
            height: 16px;
          }
        }

        &--selected {
          .icon {
            fill: $dark-green;
          }
        }

        &:hover {
          .icon {
            fill: $dark-green;
          }
        }
      }

      & .button__text {
        color: $navy-blue;
        font-size: rem(16);
        line-height: 20px;
        height: 20px;
        margin-left: 5px;

        @include respond-to($large-screen) {
          font-size: rem(15);
        }

        @include respond-to($medium-screen) {
          font-size: rem(12);
        }

        @include respond-to($small-screen) {
          font-size: rem(10);
        }
      }

      & .highlight-text {
        padding: 0 3px;
        border-radius: 5px;
        background: $light-green;
      }
    }
  }

  &__main-category {
    @include flex(flex-start);
  }

  &__sub-category {
    @include flex(flex-start);
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__main {
    @include flex(flex-start, flex-start);
    flex-direction: column;

    & .main__step {
      @include flex(flex-start, flex-start);
      font-size: rem(16);
      font-weight: 300;
      color: $gray-400;
      padding-top: 9px;

      @include respond-to($large-screen) {
        font-size: rem(15);
      }

      @include respond-to($medium-screen) {
        font-size: rem(14);
        padding-top: 7px;
      }

      @include respond-to($small-screen) {
        font-size: rem(12);
        padding-top: 4px;
      }

      &--bold {
        & .step__date {
          font-weight: normal;
          color: $navy-blue;
        }

        & .step__description {
          font-weight: normal;
          color: $navy-blue;
        }
      }

      & .step__icon {
        min-width: 38px;

        @include respond-to($large-screen) {
          min-width: 35px;
        }

        @include respond-to($medium-screen) {
          min-width: 33px;
        }

        @include respond-to($small-screen) {
          min-width: 30px;
        }

        &--disabled {
          & .icon {
            .st0 {
              fill: $gray-300;
            }
          }
        }

        & .icon {
          cursor: pointer;
          width: 15px;
          height: 15px;
          fill: $dark-grey;
          @include transition-very-slow;

          &:hover {
            fill: $dark-green;
          }
        }
      }

      & .step__date {
        padding-right: 19px;
      }

      & .step__admin {
        padding-right: 21px;
      }

      & .blue {
        color: #4378e4;
      }

      & .red {
        color: $color-red;
      }

      & .orange {
        color: #ffc90e;
      }

      & .black {
        color: $color-black;
      }

      & .purple {
        color: #a349a4;
      }

      & .green {
        color: $color-green;
      }

      & .yellow {
        color: #fff200;
      }

      & .step__description {
      }

      & .isSystem {
        visibility: hidden;
      }
    }

    & > .step__wrapper {
      width: 100%;
      @include flex;
      flex: 3 9;
      padding-top: 10px;

      & > * {
        margin-right: 10px;
      }

      & > .datepicker {
        flex: 1;
      }

      & > button {
        height: 30px;
        line-height: 30px;
        width: 30px;
        min-width: 30px;
        &.lp-button-circle--green {
          .st0 {
            fill: white;
          }
        }
      }

      & > .serviceDatePicker {
        width: 100%;
        margin: 0;
        height: 40px;
        margin-right: 10px;

        > .MuiInputBase-formControl {
          height: 40px;
        }
      }
    }
  }
}

.react-date-picker__calendar {
  top: 250px !important;
  left: initial !important;
  position: fixed !important;
}
