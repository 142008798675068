.ReactTable {
  .pagination-bottom {
    position: relative;
    .Table__pagination {
      display: flex;
      justify-content: space-between;
      align-items: center;

      border-top: 1px solid silver;
      height: 60px;
      //margin:10px 0 0 0;
      margin: 0;
      padding: 5px 0;
      .Table__nextPageWrapper {
        width: 20%;
        display: flex;
        justify-content: center;
        .Table__pageButton {
          font-size: 15px;
          outline: none;
          height: 40px;
          border: none;
          line-height: 1em;
          background-color: transparent;
          color: rgb(0, 0, 0);
          cursor: pointer;
          display: -webkit-flex;
          justify-content: center;
          align-items: center;
          &:disabled {
            cursor: not-allowed;
            background-color: transparent;
            color: silver;
            .accordion-arrow {
              fill: silver;
            }
          }
          .accordion-arrow {
            width: 12px;
            height: 12px;
            margin: 0px 5px;
            fill: rgb(142, 192, 75);
          }
        }
      }
      .Table__prevPageWrapper {
        width: 20%;
        display: flex;
        justify-content: center;
        .Table__pageButton {
          font-size: 15px;
          outline: none;
          height: 40px;
          border: none;
          line-height: 1em;
          background-color: transparent;
          color: rgb(0, 0, 0);
          cursor: pointer;
          display: -webkit-flex;
          justify-content: center;
          align-items: center;
          &:disabled {
            cursor: not-allowed;
            background-color: transparent;
            color: silver;
            .accordion-arrow {
              fill: silver;
            }
          }
          .accordion-arrow {
            width: 12px;
            height: 12px;
            margin: 0px 5px;
            fill: rgb(142, 192, 75);
          }
        }
      }
      .button__prev {
        .accordion-arrow {
          transform: rotate(180deg);
        }
      }
    }
    .Table__visiblePagesWrapper {
      width: 40%;
      display: flex;
      justify-content: space-around;
      .Table__pageInputWrapper {
        width: 25%;
        display: flex;
        justify-content: center;
        min-width: 50px;
      }
      .Table__pageInputWrapper--input {
        height: 30px;
        width: 80%;
        text-align: center;
        box-shadow: rgba(28, 28, 32, 0.3) 0px 1px 5px 0px;
        &::-webkit-inner-spin-button {
          margin: 0px;
          appearance: none;
        }
        &::-webkit-outer-spin-button {
          margin: 0px;
          appearance: none;
        }
      }
      .Table__smallPageButton {
        font-size: 16px;
        color: silver;
        margin: 0px 7px;
        border: 0;
        background: none;
        outline: none;
        cursor: pointer;
      }
      .Table__smallPageButton--active {
        height: 30px;
        width: 80%;
        text-align: center;
        box-shadow: none;
        background: 0;
        border: 0;
        color: black;
      }
    }
  }
}
