.service-info {
  padding: 40px;
  position: relative;
  background-color: $color-cream;

  a {
    text-decoration: none;
  }

  @include respond-to($large-screen) {
    padding: 35px;
  }

  @include respond-to($medium-screen) {
    padding: 25px;
  }

  @include respond-to($small-screen) {
    padding: 20px;
  }

  &--white {
    background-color: $color-white;

    .service-info__button {
      background-color: $color-white !important;
    }
  }

  &__header {
    font-size: rem(18);
    font-weight: normal;
    color: $navy-blue;
    @include flex(flex-start, flex-end);

    @include respond-to($large-screen) {
      font-size: rem(17);
    }

    @include respond-to($medium-screen) {
      font-size: rem(16);
    }

    @include respond-to($small-screen) {
      font-size: rem(14);
    }

    & .header__text {
      white-space: nowrap;
      margin-right: 10px;

      @include respond-to($large-screen) {
        margin-right: 8px;
      }

      @include respond-to($medium-screen) {
        margin-right: 6px;
      }

      @include respond-to($small-screen) {
        margin-right: 5px;
        width: 100%;
        margin: 0;
        font-size: 16px;
        color: black;
        padding: 10px 0;
        border-bottom: 1px solid #dde3e5;
      }
    }

    & .header__border {
      width: 100%;
      border-bottom: 1px solid $color-grey;
      margin-bottom: 3px;

      @include respond-to($small-screen) {
        display: none;
      }
    }

    @include respond-to($large-screen) {
      font-size: rem(15);
    }

    @include respond-to($medium-screen) {
      font-size: rem(14);
    }

    @include respond-to($small-screen) {
      font-size: rem(12);
    }
  }

  &__table {
    width: 100%;
    text-align: left;
    padding-top: 20px;

    @include respond-to($medium-screen) {
      padding-top: 10px;
    }

    .table__row {
      border-radius: 5px;
      margin-top: 5px;
      font-size: rem(18);
      font-weight: 300;
      text-align: left;
      color: $navy-blue;
      @include flex(flex-start, flex-start);

      @include respond-to($large-screen) {
        font-size: rem(17);
      }

      @include respond-to($medium-screen) {
        font-size: rem(16);
      }

      @include respond-to($small-screen) {
        font-size: rem(14);
      }

      .row__element {
        font-size: rem(18);
        font-weight: 300;
        color: $navy-blue;

        @include respond-to($large-screen) {
          font-size: rem(17);
        }

        @include respond-to($medium-screen) {
          font-size: rem(16);
        }

        @include respond-to($small-screen) {
          font-size: rem(14);
          font-weight: 400;
        }

        &__name {
          width: 100px;
          font-weight: normal;

          @include respond-to($large-screen) {
            width: 90px;
          }

          @include respond-to($medium-screen) {
            width: 75px;
          }

          @include respond-to($small-screen) {
            width: 60px;
            color: $color-black;
          }
        }
      }
    }
  }

  &__button {
    position: absolute !important;
    right: 40px;
    bottom: 40px;
    min-width: 180px;
    height: 50px;
    color: $navy-blue !important;
    padding: 0 20px !important;
    background-color: $color-cream !important;
    font-weight: normal !important;
    font-size: 16px !important;

    @include respond-to($large-screen) {
      height: 45px;
      line-height: 41px;
      min-width: 160px;
      right: 35px;
      bottom: 35px;
    }

    @include respond-to($medium-screen) {
      padding: 0 15px;
      height: 40px;
      line-height: 36px;
      min-width: 130px;
      right: 25px;
      bottom: 25px;
    }

    @include respond-to($small-screen) {
      padding: 0 10px;
      height: 30px;
      line-height: 26px;
      min-width: 100px;
      right: 20px;
      bottom: 20px;
    }

    @include respond-to($mobile-screen) {
      position: unset !important;
      margin-top: 15px !important;
      font-size: 12px !important;
    }
  }
}
