.modal-holder {
  display: none;
  &.active {
    @include flex(center, center);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 123456;
  }
}

.backdrop-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.modal {
  position: relative;
  background-color: #fff;
  z-index: 2;
  @include box-shadow(0px 2px 5px 0 rgba(22, 18, 20, 0.25));
  border-radius: 5px;

  svg {
    height: 20px !important;
    width: 20px !important;
  }

  &__header {
    text-align: right;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__close {
    cursor: pointer;
    width: 80px;
    height: 80px;
    padding: 20px;
    border-left: 1px solid $color-grey;
    border-bottom: 1px solid $color-grey;
    position: absolute;
    right: 0;
    top: 0;
    @include flex;
    @include transition-very-slow;

    &:hover {
      border-left-color: $dark-grey;
      border-bottom-color: $dark-grey;

      .icon {
        fill: $dark-grey;
      }
    }

    @include respond-to($large-screen) {
      width: 70px;
      height: 70px;
      padding: 15px;
    }

    @include respond-to($medium-screen) {
      width: 60px;
      height: 60px;
      padding: 15px;
    }

    @include respond-to($small-screen) {
      width: 50px;
      height: 50px;
      padding: 10px;
    }

    .icon {
      @include transition-very-slow;
      fill: $color-grey;
    }
  }

  &__container {
    min-width: 500px;

    &__header {
      padding: 16px 29px;
      @include flex(space-between, center);

      svg {
        height: 25px;
        width: 25px;

        &:focus {
        }

        &:active {
        }
      }

      .title {
        font-size: rem(16);
      }
    }

    &__body {
      padding: 0 17px;
    }

    &__footer {
      height: 75px;
      width: 100%;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
      }

      .content {
        @include flex(flex-end, center);
        height: 100%;
      }
    }
  }
}

@include respond-to($large-screen) {
  .modal {
    &__container {
      &__header {
        padding: 12px 23px;

        .title {
          font-size: rem(14);
        }
      }

      &__footer {
        height: 65px;
      }
    }
  }
}

@include respond-to($medium-screen) {
  .modal__container__header {
    padding: 12px 17px;
  }
}

@media (hover: hover) {
  .modal__container__header svg:hover {
  }
}
