/*! autoprefixer grid: autoplace */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;500;600;700;900&display=swap");
@import "variables.scss";
@import "assets/styles/perfectScrollbar";
@import "assets/styles/stepTabs";
@import "assets/styles/reactTable";
@import "assets/styles/callHistory.scss";
@import "assets/styles/serviceInfo.scss";
@import "assets/styles/messageListHistory";
@import "assets/styles/services";
@import "assets/styles/pagination";
@import "assets/styles/pagination-customer";
@import "assets/styles/clientBox";
@import "assets/styles/buttons";
@import "assets/styles/noDataView";
@import "assets/styles/editClientForm";
@import "assets/styles/inputs";
@import "assets/styles/serviceSteps";
@import "assets/styles/accordion";
@import "assets/styles/upsertServiceForm.scss";
@import "assets/styles/emailHistory.scss";
@import "assets/styles/navbar";
@import "assets/styles/tableFilters";
@import "assets/styles/videoChat";
@import "assets/styles/serviceDescription";
@import "assets/styles/serviceCards";
@import "assets/styles/documentList";
@import "assets/styles/modal";
@import "assets/styles/selectClientModal";
@import "assets/styles/confirmModal";
@import "assets/styles/viewMessage";
@import "assets/styles/newMessage";
@import "assets/styles/valuation";
@import "assets/styles/mailConfig";
@import "assets/styles/messagePrint";
@import "assets/styles/serviceCalls";
@import "assets/styles/messegesListHistoryLawyer";
@import "assets/styles/blog";

* {
  box-sizing: border-box;
}

:focus {
  outline: none;
}

html {
  //overflow: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: calc(100vh - 80px);
  padding-top: 80px;
  scroll-padding: 32px;

  overflow-x: hidden;

  word-wrap: break-word;

  scroll-behavior: smooth;

  @media all and (min-width: 768px) {
    overflow-y: hidden;
    font-size: 16px;
  }

  //@media all and (min-width:1600px) {
  //  font-size: 16px;
  //}

  @media all and (max-width: 959px) {
    height: calc(100vh - 60px);
    padding-top: 60px;
  }
}

a {
  color: $base-green;
}

hr {
  border-color: #e9edee;
  border-style: solid;
  border-width: 1px 0 0 0;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

input::placeholder {
  font-weight: 400;
}

button {
  text-transform: none;
}

#pageContent {
  height: calc(100vh - 80px);

  @media all and (max-width: 960px) {
    height: calc(100vh - 60px);
  }

  @media all and (min-width: 768px) {
    display: flex;
  }

  #leftSide {
    width: 100%;
    &.grayBG {
      background: #e6e7ed;
      padding-top: 40px;
    }

    @media all and (min-width: 769px) {
      height: 100%;
    }

    //&.scroll {
    //  height: calc(100vh - 68px);
    //  overflow-y: scroll;
    //}
  }
  #leftSideAdmin {
    width: calc(50% - 34.5px);
    max-width: calc(50% - 34.5px);

    @media all and (min-width: 769px) {
      height: 100%;
    }

    @media all and (max-width: 960px) {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      height: 100%;
    }

    //&.scroll {
    //  height: calc(100vh - 68px);
    //  overflow-y: scroll;
    //}
  }

  #rightSideAdmin {
    width: calc(50% - 34.5px);
    max-width: calc(50% - 34.5px);
    display: flex;
    flex-direction: column;
  }

  //&.Messages {
  //  .step-tabs__nav {
  //    border-bottom: none;
  //  }
  //}
}

.twoWindows {
  overflow: hidden;
  #pageContent {
    display: flex;

    .leftSide {
      height: calc(100vh - 80px);
    }
  }

  #hLawyers {
    text-align: left;
    max-width: 86%;
  }

  footer {
    display: none;
  }

  #editSchedule {
    display: none;
  }

  @media all and (min-width: 769px) {
    #lawyersContainer {
      max-width: 86%;
    }

    #searchAndFilters > div:first-child {
      width: 100%;
    }
  }

  .fc-button-group {
    margin-left: 0 !important;
  }

  .fc-toolbar {
    .fc-toolbar-chunk:nth-child(3) {
      display: none;
    }
  }

  #calendarContainer {
    padding-bottom: 64px;
  }
}

::-webkit-scrollbar {
  width: 6px;
}
//
//::-webkit-scrollbar-track {
//  background: #d5d7d9;
//}
//::-webkit-scrollbar-thumb {
//  background: $base-green;
//  border-radius: 4px;
//}

.cbVisable {
  width: 50%;
  min-width: 50%;
  //transition: 1s;
}

.Home_Home__EwjGr .MuiInputLabel-outlined.MuiInputLabel-shrink {
  width: max-content !important;
}

textarea {
  width: 100%;
  padding: 20px;
  border: none;
  box-shadow: 0px 2px 5px 0px rgba(22, 18, 20, 0.25);
  font-family: inherit;
  min-height: 100px;
  transition: 0.3s;
  outline: none;
  border: 1px solid #fff;
  border-radius: 4px;
  font-family: "Roboto", "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  padding: 11.5px 14px;
  color: $gray-500;

  &:focus {
    border: 1px solid $base-green;
  }

  &::placeholder {
    color: #b5b7b8;
  }

  &.error {
    border: 1px solid red;
  }
}

.scrollbar-track-y {
  background: transparent;
  width: 3px;

  top: 0;
  right: 0;

  @media all and (min-width: 768px) {
    background: #ffffff !important;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    border-left: 1px solid #d5d7d9;

    width: 8px;
    height: 100%;

    &:after {
      transform: unset;
      content: " ";
      background: #d5d7d9;
      position: absolute;
      width: 6px;
      height: calc(100% - 12px);
      right: 7px;
      top: 6px;
      border-radius: 4px;
    }
  }
}

.scrollbar-thumb {
  background: $base-green !important;
  width: 3px;

  @media all and (min-width: 768px) {
    background: $base-green !important;
    width: 6px !important;
    left: 7px !important;
    margin: 6px 0;
    z-index: 9;
  }
}

.scroll-content {
  width: 100%;
}

#contentBoxBody {
  .scroll-content > div > div {
    padding: 16px;

    @media all and (min-width: 768px) {
      padding: 36px;
    }
  }
}

#contentBoxBody .scroll-content,
#domainsList .scroll-content,
#leftSide .scroll-content {
  width: calc(100% - 20px);

  @media all and (max-width: 768px) {
    width: calc(100% - 8px);
  }
}

// #leftSide #selectDomain .scroll-content {
//   width: 100%;
// }

#leftSideAdmin .scroll-content {
  width: calc(100% - 20px);

  @media all and (max-width: 960px) {
    width: calc(100% - 8px);
  }
}

#lawyersContainer {
  //transition: 0.1s;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.infoPopover {
  padding: 16px;
  max-width: 320px;
}

.scrollView {
  overflow-x: hidden !important;
  width: calc(100% - 14px);
  width: calc(100% - 9px);
  margin: 0 !important;

  //@media all and (min-width: 768px) {
  //  margin-right: 15px !important;
  //}

  //padding-top: 32px;
}

.track-horizontal {
  display: none;
}

.track-vertical {
  background: transparent;
  width: 3px;

  @media all and (min-width: 768px) {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0px;
    border-radius: 0;
    z-index: 9;
    background: #ffffff !important;
    padding: 7px;
    border-left: 1px solid #d5d7d9;
    width: unset !important;

    &:after {
      transform: unset;
      content: " ";
      background: #d5d7d9;
      position: absolute;
      width: 6px;
      height: calc(100% - 12px);
      right: 7px;
      top: 6px;
      border-radius: 4px;
    }
  }
}

.thumb-vertical {
  background: $base-green;
  width: 3px;

  @media all and (min-width: 768px) {
    z-index: 9;
    border-radius: 4px;
    background: rgb(142, 192, 75) !important;
    width: 6px !important;
  }
}

.home {
  footer {
    margin-top: 0;
  }

  // #pageContent {
  // > .scroll-content {
  //   display: flex;
  //  }
  // }
}

.rdw-editor-wrapper {
  box-sizing: content-box;
  box-shadow: 0px 2px 5px 0px rgba(22, 18, 20, 0.25);
  border-radius: 4px;

  .rdw-editor-toolbar {
    border: 0;
    border-bottom: 1px solid #e6e6e6;
    border-radius: 4px 4px 0 0;
    margin-bottom: 0;
  }

  .rdw-editor-main {
    padding: 0 14px;
  }
}

.limit {
  color: $gray-300;
  font-size: 14px;
  position: absolute;
  right: 0;
  top: 2px;
}

.Toastify__toast {
  border-radius: 4px !important;
}

.Toastify__toast-container--top-right {
  top: 5em !important;
  right: 2em !important;
}

.Toastify__toast--success {
  background: #8ec04b !important;
}

.additional-menu {
  background-color: $color-white;

  @include flex(flex-start, flex-start);
  padding: 10px 0 10px 20px;
  width: 100%;
  border-top: 1px solid $color-grey;

  &--top {
    top: 0;
    bottom: initial;
    border-bottom: 1px solid $color-grey;
  }

  .lp-button {
    margin-right: 10px !important;
    font-size: 1.12em !important;
    width: auto !important;
  }

  .button {
    margin-right: 10px !important;
    font-size: 1.12em;
    height: 50px;
  }
}

.clientTitle {
  margin: 0 0 36px 0;
  text-align: center;
  display: none;

  @media all and (max-width: 768px) {
    display: block;
  }
}

.left {
  position: relative;
  min-width: 50%;
  max-width: 50%;
  height: 100%;

  &--grey {
    background-color: $color-cream;
  }

  &--minimalized {
    height: calc(100% - 30px);
    top: -15px;
  }

  &__button {
    position: absolute;
    z-index: 200;
    bottom: 20px;
    right: 40px;
    background: 0;
    cursor: pointer;
    border: 0;

    &--pagination {
      bottom: 90px;

      @include respond-to($medium-screen) {
        bottom: 105px;
      }
    }

    & .icon {
      $size: 64px;
      width: $size;
      height: $size;
      fill: $color-green;
    }
  }
}

.pageTitle {
  display: block;
  text-align: center;
  font-size: 30px;
  margin: 40px 0;
}

.payment_to_choose {
  width: 125px;
  height: 62px;
  margin: 10px;
  -o-object-fit: contain;
  object-fit: contain;
  padding: 5px;
  border: 2px solid transparent;

  @media (max-width: 500px) {
    width: 31%;
    margin: 1%;
    height: auto;
  }

  &.selected {
    border: 2px solid $base-green;
  }
}

#contact {
  .MuiFormControl-root.unselected #mui-component-select-who_are_you {
    color: $gray-400;
  }
  .MuiFormControl-root {
    width: 100%;
  }
}

.client-link-container {
  padding: 20px 20px 10px;
  display: flex;
  justify-content: flex-end;
}

.stepsDomainContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-y: hidden;
  transition: all 0.3s linear;
  position: absolute;
  top: -95px;

  @media all and (min-width: 1024px) {
    align-items: flex-start;
    left: 0;
    top: -95px;
    width: calc(100% + 22px);
  }

  @media all and (min-width: 1024px) and (max-width: 1366px) {
    width: calc(100% + 24px);
    top: -75px;
  }

  @media all and (max-width: 1023px) {
    width: 100%;
    border-radius: 10px;

    top: -75px;
  }

  .MuiCollapse-container {
    width: 100%;
  }

  &.hidden {
    top: 0;
    .step {
      height: 0px;
    }
  }
  .step {
    width: 33%;
    background-color: rgba($light-green, 0.95);
    height: 85px;
    clip-path: polygon(93% 0, 100% 50%, 93% 100%, 0 100%, 0 50%, 0 0);
    float: right;
    font-size: 26px;
    color: $green-text;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s linear;

    &:nth-of-type(1) {
      width: calc(33%);
    }
    &:nth-of-type(2) {
      clip-path: polygon(93% 0, 100% 50%, 93% 100%, 0 100%, 7% 50%, 0 0);
      width: calc(33%);
      position: relative;
      left: -11px;
    }

    &:nth-of-type(3) {
      clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 7% 50%, 0 0);
      position: relative;
      left: -22px;
      width: calc(33%);
    }

    @media all and (min-width: 1024px) and (max-width: 1366px) {
      font-size: 21px;
      height: 65px;

      &:nth-of-type(1) {
        width: calc(33%);
        clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 50%, 0 0);
      }
      &:nth-of-type(2) {
        clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 10% 50%, 0 0);
        width: calc(33%);
        position: relative;
        left: -12px;
      }

      &:nth-of-type(3) {
        clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 10% 50%, 0 0);
        position: relative;
        left: -24px;
        width: calc(33%);
      }
    }

    @media all and (min-width: 1024px) and (max-width: 1210px) {
      font-size: 18px;
    }
    @media all and (max-width: 1023px) {
      clip-path: unset !important;
      font-size: 17px;
      left: 0 !important;
      margin: 0 !important;
      width: 100% !important;
      height: 65px;

      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      > span {
        width: 100%;
        padding: 0 25px 0 10px;
      }
      &:nth-of-type(1),
      &:nth-of-type(2) {
        &:after {
          background: url("assets/img/arrow-step.png") center center no-repeat;
          content: "";
          height: 20px;
          width: 20px;
          position: absolute;
          right: 0;
        }
      }
      &:nth-of-type(2) {
        clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 10% 50%, 0 0);
        width: calc(33%);
        position: relative;
        left: -12px;
      }
    }

    @media all and (max-width: 360px) {
      font-size: 15px;
    }
  }
}
body.leaveOpinion {
  padding-top: 0;

  .pageContent {
    height: 100vh !important;
  }
}
/* print styles */
@media print {
  .message__controls,
  .message__attachments {
    display: none !important;
  }
}

.hasGenericPlaceholder {
  input {
    &::placeholder,
    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder,
    :-moz-placeholder {
      color: #999;
      font-weight: 100;
      font-size: rem(11);
    }
  }
}

select.hasGenericPlaceholder {
  font-size: rem(11);
  background-color: #ffffff00 !important;
  z-index: 10;
  border-radius: 8px;
}

select.hasGenericPlaceholder.genericPSelected {
  background: #fff !important;
}

.hasGenericPlaceholderSelect {
  z-index: 1;
  position: absolute;
  font-size: rem(11);
  color: #999;

  overflow: hidden;
  width: min-content;
  margin-left: 10px;
  margin-right: auto;
}

#footer.customer {
  margin-top: 0;
}

.rightSide .scrollbar-track-x {
  display: none;
}

.customer-panel .scrollbar-track-x {
  display: none;
}
