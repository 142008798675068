.select-client-modal {
  @include flex(space-around);
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 20px;

  &__wrapper {
    @include flex;
    flex-direction: column;

    &--header {
      font-size: rem(40);
      font-weight: 300;
      padding: 20px 0;

      @include respond-to($large-screen) {
        font-size: rem(36);
        padding: 20px 0;
      }

      @include respond-to($medium-screen) {
        font-size: rem(32);
        padding: 20px 0;
      }

      @include respond-to($small-screen) {
        font-size: rem(28);
        padding: 20px 0;
      }
    }
  }

  &__selectors--wrapper {
    width: 100%;
    @include grid(3);
  }
}
