.service-calls {
  position: relative;
  width: 100%;
  @include flex(flex-start);
  flex-direction: column;

  & .button-square--green {
    position: absolute;
    right: 0;

    .icon {
      width: 23px;
      height: 23px;
    }
  }

  &__call {
    font-size: rem(16);
    font-weight: 300;
    color: $dark-grey;
    width: 100%;
    @include flex(flex-start);

    @include respond-to($large-screen) {
      font-size: rem(15);
    }

    @include respond-to($medium-screen) {
      font-size: rem(14);
    }

    @include respond-to($small-screen) {
      font-size: rem(12);
    }

    & .call__date {
      width: 15%;
    }

    & .call__time {
      width: 10%;
    }

    & .call__icon {
      width: 10%;

      & .icon {
        width: 26px;
        height: 26px;
        fill: $dark-grey;
        position: relative;
        top: 3px;
      }
    }

    & .call__initials {
      width: 10%;
    }

    & .call__duration {
      width: 20%;
    }

    & .call__price {
      width: 10%;
    }
  }
}
