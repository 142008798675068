$base-green: #8ec04b;
$dark-green: #1c3e18;
$light-green: #d2e6b7;
$green-text: #1c3e1b;

$gray-100: #f8f8f8;
$gray-200: #e9eaef;
$gray-300: #d5d7d9;
$gray-400: #989898;
$gray-500: #4b555f;

$color-green: #8ec04b;
$light-green: #d2e6b7;
$dark-green: #1c3e1b;

$color-red: #e44343;
$navy-blue: #4b555f;

$light-grey: #e9eaef;
$borderBottom: #d5d7d9;
$color-gray: #d5d6d7;
$color-light-grey: #bbb;
$color-grey: #c0c0c0;
// $dark-grey: #989898;
$dark-grey: #878787;
$darkest-grey: #666;

$color-black: #000000;
$color-white: #ffffff;
$color-cream: rgb(247, 248, 248);

$red: #e44343;

$black: #000000;
$white: #ffffff;

$font-size-1600: 13px;
$font-size-1900: 14px;

$font-size-b-1600: 15px;
$font-size-b-1900: 16px;

$section-title-size-1600: 32px;
$section-title-size-1900: 36px;

// Font Family
$base_font-family: Lato;
$base_font-size: 16 !default;
$large-screen: 1919px;
$medium-screen: 1367px;
$small-screen: 1023px;
$mobile-screen: 768px;

@function rem($px, $base: $base_font-size) {
  @return #{floor(($px/$base) * 100) / 100}rem; // to REMs
}

@mixin setFont($font-family) {
  html,
  body,
  input,
  textarea,
  button,
  select,
  option {
    font-family: $font-family;
  }
}

@mixin flex($vertical: center, $horizontal: center) {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  justify-content: $vertical;
  align-items: $horizontal;
}

@mixin grid($rows: 1, $gap: 0) {
  display: grid;
  display: -moz-grid;
  grid-template-columns: repeat($rows, 1fr);
  grid-gap: $gap;
}

// Transitions
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow($type: all) {
  transition: $type 0.2s ease-in-out;
}
@mixin transition-very-slow() {
  transition: all 0.4s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin translateX($x) {
  @include transform(translateY($x));
}

@mixin translateY($y) {
  @include transform(translateY($y));
}

@mixin scale($ratio) {
  @include transform(scale($ratio));
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
}

@mixin box-shadow($options) {
  -webkit-box-shadow: $options;
  -moz-box-shadow: $options;
  box-shadow: $options;
}

@mixin transition($time, $type, $property) {
  transition: $time $type $property;
  -moz-transition: $time $type $property;
  -webkit-transition: $time $type $property;
}

@mixin linear-gradient($top, $bottom) {
  background: -moz-linear-gradient(top, $top 0%, $bottom 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, $top),
    color-stop(100%, $bottom)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    $top 0%,
    $bottom 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $top 0%, $bottom 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $top 0%, $bottom 100%); /* IE10+ */
  background: linear-gradient(to bottom, $top 0%, $bottom 100%); /* W3C */
}

@mixin respond-to($width) {
  @media only screen and (max-width: $width - 1) {
    @content;
  }
}

@mixin respond-from($width) {
  @media only screen and (min-width: $width) {
    @content;
  }
}

@mixin animation($delay, $duration, $animation) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

  -o-animation-delay: $delay;
  -o-animation-duration: $duration;
  -o-animation-name: $animation;
  -o-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: forwards; /* this prevents the animation from restarting! */
}
