.valuation {
  margin-top: 0;
  padding: 10px;
  font-size: rem(18);
  @include respond-to($large-screen) {
    font-size: rem(18);
  }

  @include respond-to($medium-screen) {
    font-size: rem(14);
  }

  @include respond-to($small-screen) {
    font-size: rem(12);
  }

  &.client {
    font-size: rem(16);
    display: block;
    padding: 0;

    @include respond-to($large-screen) {
      font-size: rem(17);
    }

    @include respond-to($medium-screen) {
      font-size: rem(16);
    }

    @include respond-to($small-screen) {
      font-size: rem(14);
    }
    &__row {
      display: inline-block;
    }
    &__text {
      display: inline-block;
    }
  }

  &__row {
    display: flex;
    margin-bottom: 5px;
  }

  &__text {
    margin-right: 15px;

    &--lowercase {
      text-transform: lowercase;
      margin-right: 0;
    }
  }
}
