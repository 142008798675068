.email-history {
  &__disabled-checkbox {
    width: 16px;
    height: 16px;
    border: 2px solid #b5b7b8;
    border-radius: 2px;
    background-color: #b5b7b8;
  }
  &__email-tab {
    background-color: #8ec04b;
  }
  &__nodata-newmessage {
    width: 160px;
    height: 50px;
    background-color: #8ec04b;
    color: white;
    border-radius: 5px;
    margin-top: 20px;
    &:hover {
      background-color: white;
      color: #8ec04b;
      box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3);
    }
  }
  &__message-footer {
    & .message-managment {
      margin-left: 40px;
      margin-right: 60px;
      background-color: white;
      border-top: 1px solid #d5d7d9;
      display: flex;
      bottom: 10px;
      width: calc(100% - 100px);
      padding-top: 40px;
      margin-bottom: 0;
      flex-direction: row;
      & .send-button {
        height: 50px;
        margin-right: 40px;
        min-width: 160px;
        color: white;
        background-color: #8ec04b;
        border-radius: 5px;
        &:hover {
          color: #8ec04b;
          box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3);
          background-color: white;
        }
      }
      & .link-button {
        height: 50px;
        color: #8ec04b;
        min-width: 160px;
        width: 160px;
        text-align: center;
        padding-top: 15px;
        box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3);
        border-radius: 5px;
        &:hover {
          color: white;
          background-color: #8ec04b;
        }
      }
      & .right-side {
        width: 100%;
        & .cancel-button {
          float: right;
          height: 50px;
          color: #8ec04b;
          width: 160px;
          box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3);
          border-radius: 5px;
          &:hover {
            color: white;
            background-color: #8ec04b;
          }
        }
      }
    }
  }
  &__new-message-view {
    & .new-message-footer {
      // margin-top: 115px;
      margin-bottom: 20px;
      margin-top: 30px;
      @include respond-to($small-screen) {
        border-top: 1px solid #e9eaef;
        padding-top: 20px;
      }
      & .messageControls__wrapper {
        display: flex;
        padding-top: 10px;
        border-top: 1px solid #e9eaef;
        flex-direction: row;
        & .send-button {
          width: 160px;
          height: 50px;
          color: white;
          border-radius: 5px;
          font-size: 18px;
          background-color: #8ec04b;
          &:disabled {
            color: white;
            background-color: #d5d7d9;
          }
          &:disabled:hover {
            color: white;
            background-color: #d5d7d9;
            @include box-shadow(0px rgba(28, 28, 32, 0));
          }
          &:hover {
            color: #8ec04b;
            background-color: white;
            @include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3));
          }
        }
        & .cancel-button {
          width: 160px;
          height: 50px;
          color: white;
          border-radius: 5px;
          font-size: 18px;
          background-color: #8ec04b;
          color: #8ec04b;
          background-color: white;
          @include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3));
          &:hover {
            color: white;
            background-color: #8ec04b;
          }
        }
        & .attachFile-button {
          width: 60%;
          margin-left: 40px;
          color: $dark-grey;
          @include flex(flex-start);
          & .attach-button {
            cursor: pointer;
            width: 160px;
            height: 50px;
            padding-left: 35px;
            padding-top: 13px;
            @include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3));
            border-radius: 5px;
            font-size: 18px;
            color: #8ec04b;
            &:hover {
              color: white;
              background-color: #8ec04b;
              & .icon {
                margin-left: 5px;
                fill: white;
                width: 20px;
                height: 20px;
              }
            }
            & .fileInput {
              display: none;
            }
            & .icon {
              margin-left: 5px;
              fill: #8ec04b;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      & .add-files {
        width: 100%;
        padding: 20px 0;
        & .attachment {
          width: 100%;
          display: inline-block;
          padding-top: 5px;
          padding-bottom: 5px;
          border-top: 1px solid $gray-300;

          display: flex;
          justify-content: space-between;
          align-items: center;

          &:last-of-type {
            border-bottom: 1px solid $gray-300;
          }
          & .left {
            float: left;
            max-width: 60%;
            overflow: hidden;
            padding: 8px;
            & .file-title {
              color: $gray-400;
              font-size: 16px;

              &.file-uploaded {
                color: $navy-blue;
              }
            }
          }
          & .right {
            max-width: 40%;
            overflow: hidden;
            float: right;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            & .progress {
              margin-top: 15px;
              margin-left: 10px;
              width: 120px;
              height: 12px;
              background-color: #e9eaef;
              border-radius: 10px;
              & .progress-value {
                padding-left: 20px;
                height: 12px;
                background-color: #8ec04b;
                border-radius: 10px;
              }
            }
            & .delete-button {
              width: 110px;
              margin-left: 20px;
              height: 40px;
              border: 2px #8ec04b solid;
              border-radius: 5px;
              &:hover {
                background-color: #8ec04b;
                color: white;
              }
            }
            & .file-weight {
              color: #666666;
              @include respond-to($small-screen) {
                display: none;
              }
            }
          }
        }
      }
    }
    padding-left: 40px;
    padding-right: 40px;

    @include respond-to($small-screen) {
      padding-left: 16px;
      padding-right: 16px;
    }

    & .new-message-header {
      margin-top: 32px;
      display: flex;
      flex-direction: row;
      font-size: 20px;
      &:after {
        content: "";
        //margin-top: 20px;
        margin-top: 32px;
        //margin-left: 10px;
        //width: calc(100% - 180px);
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #d5d7d9;
        position: absolute;
      }

      @include respond-to($small-screen) {
        font-size: 16px;
      }
    }
    & .auto-complete {
      margin-top: 15px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;

      @include respond-to($mobile-screen) {
        flex-direction: column;
      }
      & .auto-complete-text {
        color: #4b555f;
        font-size: 16px;
        margin-top: 10px;

        @media all and (min-width: 768px) {
          margin-top: 17px;
          min-width: 102px;
          max-width: 102px;
        }
      }
      & .input-wrapper {
        width: 100%;
        float: right;
        & .input-validation {
          &--error {
            & .react-autosuggest {
              &__container {
                margin-bottom: 10px;
                width: 700px;
                float: right;
              }
              &__input {
                padding-left: 5px;
                height: 40px;
                width: 100%;
                font-size: 16px;
                color: #666666;
                border: 1px solid red;
                border-radius: 5px;
              }
              &__suggestions-container {
                position: absolute;
                right: 60px;
                z-index: 2000;
              }
              &__suggestions-list {
                max-height: 200px;
                overflow-y: scroll;
                margin-top: -5px;
                background-color: white;
                width: 430px;
                box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3);
                border-radius: 5px;
              }
              &__suggestion--highlighted {
                background-color: #f8f8f8;
              }
              &__suggestion {
                &:hover {
                  background-color: #f8f8f8;
                }
                padding: 15px;
                & .inside-sugesstion {
                  display: flex;
                  flex-direction: row;
                  & .suggestion--left {
                    font-size: 16px;
                    color: #666666;
                    width: 50%;
                  }
                  & .suggestion--right {
                    font-size: 16px;
                  }
                }
              }
            }
          }
          & .react-autosuggest {
            &__container {
              margin-bottom: 10px;
              width: 100%;
              float: right;
            }
            &__input {
              padding-left: 5px;
              height: 40px;
              width: 100%;
              font-size: 16px;
              color: #666666;
              box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3);
              border-radius: 5px;
            }
            &__suggestions-container {
              position: absolute;
              right: 60px;
              z-index: 2000;
            }
            &__suggestions-list {
              max-height: 200px;
              overflow-y: scroll;
              margin-top: -5px;
              background-color: white;
              width: 430px;
              box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3);
              border-radius: 5px;
            }
            &__suggestion--highlighted {
              background-color: #f8f8f8;
            }
            &__suggestion {
              &:hover {
                background-color: #f8f8f8;
              }
              padding: 15px;
              & .inside-sugesstion {
                display: flex;
                flex-direction: row;
                & .suggestion--left {
                  font-size: 16px;
                  color: #666666;
                  width: 50%;
                }
                & .suggestion--right {
                  font-size: 16px;
                }
              }
            }
          }
        }

        .react-autosuggest__suggestions-list {
          margin: 0;
          padding: 0;
          border: 0;
          outline: 0;
          text-decoration: none;
          list-style: none;

          font-weight: inherit;
          box-sizing: border-box;
        }
      }
    }
    & .topic {
      margin-top: 15px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;

      @include respond-to($mobile-screen) {
        flex-direction: column;
      }
      & .topic-text {
        color: $navy-blue;
        font-size: 16px;
        min-width: 102px;

        max-width: 102px;
        @include respond-to($mobile-screen) {
          width: auto;
          min-width: auto;
          max-width: 100%;
          margin-top: 29px;
          margin-bottom: 12px;
        }
      }
      & .input-wrapper {
        width: 100%;
        & .lp-input {
          float: right;
          width: 100%;
          &__info {
            height: 0;
            width: 0;
          }

          &__field {
            height: 40px;
            font-size: rem(16);
            font-weight: 300;
            text-align: left;
            padding-left: 10px;
            margin-left: 2px;
            max-width: initial;
            background-color: rgba(0, 0, 0, 0);
          }
        }
      }
    }
    & .message-input {
      margin-top: 28px;

      .label {
        color: $navy-blue;
        font-size: 16px;
        margin-bottom: 12px;
      }
      & .input-wrapper {
        width: 100%;
        // height: 300px;
        & .editor {
          width: 100%;
          padding: 5px 0;
          box-shadow: 0px 1px 5px 0 rgba(28, 28, 32, 0.3);
          border-radius: 5px;

          & > div:first-child {
            border-radius: 5px;

            margin: 0;

            & > div {
              margin: 0;
              padding: 0 10px;
              border-right: 1px solid $color-grey;
              &:nth-child(4) {
                border: none;
              }
              &:nth-child(2) > div > button {
                border: none;
                background: transparent;
                transition: 1s background-color;
                &:hover {
                  background: rgba(100, 100, 100, 0.1);
                }
              }
              &:nth-child(4) > div > button {
                border: none;
                background: transparent;
                transition: 1s background-color;
                &:hover {
                  background: rgba(100, 100, 100, 0.1);
                }
              }
            }
          }

          .DraftEditor-root {
            height: 300px;
            border: none;
          }

          & .input {
            margin-bottom: 10px;
            &__info {
              height: 0;
              width: 0;
            }

            &__field {
              height: 300px;
              border: none;
            }
          }
        }
      }
    }
  }
  &__new-message-btn {
    cursor: pointer;
    position: absolute;
    z-index: 1000;
    bottom: 100px;
    right: 80px;
    width: 80px;
    height: 80px;
    fill: #8ec04b;
    @media (max-width: 800px) {
      display: none;
    }
  }
  &__deleteAllCheckbox {
    width: auto;
    height: 60px;

    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $gray-300;
    padding-left: 40px;
    & .delete-info {
      margin-top: 20px;
      margin-left: 15px;
      color: #666666;
      font-size: 16px;

      @media all and (max-width: $small-screen) {
        font-size: 14px;
        margin-left: 6px;
      }
    }

    @include respond-to($large-screen) {
      padding-left: 35px;
    }

    @include respond-to($medium-screen) {
      padding-left: 24px;
    }

    @include respond-to($small-screen) {
      padding-left: 18px;
    }

    @media all and (max-width: 767px) {
      padding-left: 10px;

      & .delete-icon {
        margin-left: 16px;
      }
    }
    & .delete-icon {
      margin-top: 20px;
      width: 21px;
      height: 21px;

      @media all and (min-width: 768px) {
        margin-left: 26px;
      }

      & .icon-bin {
        fill: #666666;
      }
      &:hover {
        & .icon-bin {
          fill: #525252;
        }
      }
    }
  }
  &__lawyer {
    padding: 40px;
    background-color: $color-cream;

    @include respond-to($large-screen) {
      padding: 35px;
    }

    @include respond-to($medium-screen) {
      padding: 25px;
    }

    @include respond-to($small-screen) {
      padding: 20px;
    }

    & .box-big {
      margin: 0 !important;
    }

    & .box-big__mask {
      margin: 0 !important;
    }

    .lawyer__title {
      padding-bottom: 17px;
      font-size: rem(18);
      color: $navy-blue;
      @include flex(flex-start, flex-end);

      @include respond-to($large-screen) {
        font-size: rem(17);
      }

      @include respond-to($medium-screen) {
        font-size: rem(16);
      }

      @include respond-to($small-screen) {
        font-size: rem(14);
      }

      & .title__lawyer {
        white-space: nowrap;
        margin-right: 10px;

        @include respond-to($large-screen) {
          margin-right: 8px;
        }

        @include respond-to($medium-screen) {
          margin-right: 6px;
        }

        @include respond-to($small-screen) {
          margin-right: 5px;
        }
      }

      & .title__border {
        width: 100%;
        border-bottom: 1px solid $color-grey;
        margin-bottom: 3px;
      }

      @include respond-to($large-screen) {
        padding: 11px 0 16px 0;
      }

      @include respond-to($medium-screen) {
        padding: 10px 0 14px 0;
      }

      @include respond-to($small-screen) {
        padding: 8px 0 10px 0;
      }
    }
  }

  &__email-details {
    padding: 40px;

    @include respond-to($large-screen) {
      padding: 35px;
    }

    @include respond-to($medium-screen) {
      padding: 25px;
    }

    @include respond-to($small-screen) {
      padding: 20px;
    }

    .email-details__email {
      width: 100%;
      text-align: left;
      font-weight: 300;

      .email__title {
        color: #90c04c;
        text-align: left;
        text-overflow: ellipsis;
        font-size: rem(22);
        font-weight: 600;
        line-height: 1.09;
        margin-bottom: 15px;

        @include respond-to($large-screen) {
          font-size: rem(21);
        }

        @include respond-to($medium-screen) {
          font-size: rem(19);
          margin-bottom: 10px;
        }

        @include respond-to($small-screen) {
          font-size: rem(17);
        }
      }

      .email__date {
        display: none;

        @include respond-to($small-screen) {
          display: inline-block;
          font-size: rem(12);
          font-weight: 400;
          color: $gray-400;
          margin-bottom: 6px;
        }
      }

      .email__sender {
        font-size: rem(20);
        letter-spacing: 0.6px;
        color: $color-black;
        margin-bottom: 36px;
        font-stretch: normal;

        @include respond-to($large-screen) {
          font-size: rem(19);
          margin-bottom: 35px;
        }

        @include respond-to($medium-screen) {
          font-size: rem(18);
          margin-bottom: 25px;
        }

        @include respond-to($small-screen) {
          font-size: rem(14);
          color: $navy-blue;
          margin-bottom: 20px;
          font-weight: 400;
        }
      }

      .email__content {
        font-size: rem(16);
        color: $navy-blue;
        line-height: 1.25;
        padding-bottom: 37px;
        @include flex(flex-start, flex-start);
        flex-direction: column;

        &--padding {
          padding-bottom: 80px !important;

          @include respond-to($large-screen) {
            padding-bottom: 70px !important;
          }

          @include respond-to($medium-screen) {
            padding-bottom: 60px !important;
          }

          @include respond-to($small-screen) {
            padding-bottom: 50px !important;
          }
        }

        & .button--disabled {
          padding: 0;
          width: 110px;
          height: 40px;
          line-height: 36px;
          margin-right: 20px;
          font-size: rem(18);
          font-weight: 500;

          @include respond-to($large-screen) {
            font-size: rem(17);
            margin-right: 18px;
            height: 35px;
            line-height: 31px;
          }

          @include respond-to($medium-screen) {
            font-size: rem(16);
            margin-right: 15px;
            height: 25px;
            line-height: 21px;
            width: 90px;
          }

          @include respond-to($small-screen) {
            font-size: rem(14);
            margin-right: 10px;
            height: 20px;
            line-height: 16px;
            width: 80px;
          }
        }

        @include respond-to($large-screen) {
          font-size: rem(15);
          padding-bottom: 35px;
        }

        @include respond-to($medium-screen) {
          font-size: rem(14);
          padding-bottom: 25px;
        }

        @include respond-to($small-screen) {
          font-size: rem(14);
          padding-bottom: 20px;
        }

        & .content__messages {
          width: 100%;
          position: relative;

          & .messages__message {
            border: none;

            font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI",
              "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
              "Droid Sans", "Helvetica Neue", sans-serif;
            .public-DraftEditor-content {
              padding: 0;
            }
          }

          & .button {
            position: absolute;
            bottom: -60px;
            left: 0;
            @include respond-to($large-screen) {
              bottom: -50px;
            }

            @include respond-to($medium-screen) {
              bottom: -40px;
            }

            @include respond-to($small-screen) {
              bottom: -30px;
            }
          }
          & .button-wrapper {
            margin: 20px 0;
            display: flex;
            flex-direction: row;
            & .button-text {
              margin-top: 35px;
              margin-left: 20px;
              font-size: 16px;
              font-weight: 400;

              @include respond-to($small-screen) {
                font-size: 12px;
                color: $color-black;
                text-decoration: underline;
                margin: 0;
              }
            }
            & .button-circle {
              margin-top: 20px;
              cursor: pointer;
              width: 55px;
              height: 55px;
              border-radius: 50%;
              box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.75);
              & .button-icon {
                margin-top: 20px;
                margin-left: 22px;
                width: 11px;
                fill: #8ec04b;
                transform: rotate(90deg);
                &--hide {
                  margin-top: 18px;
                  margin-left: 22px;
                  width: 11px;
                  fill: #8ec04b;
                  transform: rotate(270deg);
                }

                &--mobile {
                }
              }

              @include respond-to($small-screen) {
                display: none;
              }
            }

            & .button-icon--mobile {
              display: none;
              width: 11px;
              fill: #8ec04b;
              height: 8px;
              font-weight: 500;

              &--hide {
                margin-top: 18px;
                margin-left: 22px;
                width: 11px;
                fill: #8ec04b;
                margin: 0;
              }
              @include respond-to($small-screen) {
                display: inline-block;
              }
            }
          }

          & .messages-enter {
            opacity: 0;
            @include transform(translateY(-100%));
          }

          & .messages-enter-active {
            transition: all 500ms ease-in;
            opacity: 1;
            @include transform(translateY(0));
          }

          & .messages-leave {
            opacity: 1;
            @include transform(translateY(0));
          }

          & .messages-leave-active {
            transition: all 500ms ease-in;
            opacity: 0;
            @include transform(translateY(-100%));
          }
        }
      }
    }
    .email-details__buttons {
      padding: 20px 0 20px 0;
      @include flex;

      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      .buttons__right {
        width: 100%;
        @include flex(flex-end);
      }
      & .message_buttons {
        @include flex(flex-start);
        flex-wrap: wrap;

        > button {
          font-size: 18px;
          line-height: 26px;
          padding: 5px 26px;
          font-weight: normal;
          color: $navy-blue;

          @include respond-to($large-screen) {
            font-size: 16px;
          }

          @include respond-to($medium-screen) {
            font-size: 16px;
          }

          @include respond-to($small-screen) {
            font-size: 12px;
            font-weight: 500;
            line-height: 1.75;
            padding: 3px 11px;
          }
        }

        &.valuation_buttons {
          margin-bottom: 8px;
        }
      }

      @include respond-to($large-screen) {
        padding: 17px 0 17px 0;
      }

      @include respond-to($medium-screen) {
        padding: 14px 0 14px 0;
      }

      @include respond-to($small-screen) {
        padding: 10px 0 10px 0;
      }

      .buttons__button {
        padding: 0;
        min-width: 120px;
        height: 40px;
        line-height: 36px;
        font-size: rem(18);
        margin: 10px 20px 10px 0px;

        &:last-child {
          margin-right: 0;
        }

        @include respond-to($large-screen) {
          font-size: rem(17);
          height: 35px;
          line-height: 31px;
        }

        @include respond-to($medium-screen) {
          font-size: rem(16);
          height: 25px;
          line-height: 21px;
          min-width: 100px;
        }

        @include respond-to($small-screen) {
          font-size: rem(14);
          height: 20px;
          line-height: 16px;
          min-width: 90px;
        }
      }

      .button__green {
        background: $color-green;
        color: $color-white;
      }
    }

    .email-details__attachments {
      margin-top: 29px;
      width: 100%;

      @include respond-to($large-screen) {
        margin-top: 25px;
      }

      @include respond-to($medium-screen) {
        margin-top: 20px;
      }

      @include respond-to($small-screen) {
        margin-top: 15px;
      }

      .attachments__title {
        color: $navy-blue;
        font-size: rem(18);

        @include respond-to($large-screen) {
          font-size: rem(17);
        }

        @include respond-to($medium-screen) {
          font-size: rem(16);
        }

        @include respond-to($small-screen) {
          font-size: rem(14);
          margin-bottom: 15px;
          color: $dark-grey;
          font-weight: 400;
        }
      }

      .file-name {
        color: $navy-blue;
        font-weight: 300;
        margin-right: 10px;
      }

      .attachments__content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 2px;

        @include respond-to($small-screen) {
          display: block;
          padding: 0;
        }

        .attachments__button {
          max-width: 240px;
          align-self: center;

          @include respond-to($large-screen) {
            max-width: 220px;
          }

          @include respond-to($medium-screen) {
            max-width: 200px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .attachments__attachment {
        width: 180px;
        height: 120px;
        border-radius: 5px;
        @include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3));
        position: relative;
        display: flex;
        cursor: pointer;
        flex-direction: column;
        margin: 15px 15px 0 0;
        &:hover {
          & .attachment-bottom {
            width: 100%;
            height: 40px;
            background-color: #d2e6b7;
            color: white;
            text-align: center;
            padding-top: 10px;
          }
        }
        & .attachment-icon {
          width: 100%;
          height: 80px;
          text-align: center;
          color: #666666;
          font-size: 30px;
          padding-top: 20px;
        }
        & .attachment-bottom {
          width: 100%;
          height: 40px;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          background-color: #8ec04b;
          color: white;
          text-align: center;
          padding-top: 10px;
        }

        &--mobile {
          display: none;
        }

        @include respond-to($large-screen) {
          width: 180px;
        }

        @include respond-to($medium-screen) {
          width: 160px;
        }

        @include respond-to($small-screen) {
          display: none;

          &--mobile {
            display: inline-block;
            height: auto;
            width: 100%;
            box-shadow: none;
            border-top: 1px solid #dde3e5;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .icon {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
    }
  }

  &__email-list {
    width: 100%;
    height: calc(100% - 60px);
    border-radius: 5px;

    // @include respond-to($large-screen) {
    //   height: 100%;
    // }

    // @media only screen and (max-width: 1706px) {
    //   height: 100%;
    // }

    // @include respond-to($medium-screen) {
    //   height: calc(100vh - 267px);
    // }

    // @include respond-to($small-screen) {
    //   height: calc(100vh - 232px);
    // }

    &--pagination {
      height: calc(100% - 60px) !important;
      border-radius: 0 0 5px 5px;
    }
    .title-turncate {
      width: 100%;
    }
    .email-list__email {
      font-size: rem(16);
      font-weight: 500;
      //color: $navy-blue;
      color: #b5b7b8;
      text-align: left;
      height: 59px;
      width: 100%;
      @include flex(flex-start);
      padding-left: 10px;
      background-color: $color-white;

      .unread {
        color: $navy-blue;
        font-weight: 600;

        &.email__subject {
          font-weight: 400;
          strong {
            font-weight: 600;
          }
        }

        .strong {
          @include respond-to($mobile-screen) {
            color: $navy-blue;
            font-weight: 600;
          }
        }
      }

      &.attachment {
        margin: 0;
      }

      .strong {
        font-size: 14px;
        color: #4b555f;
        font-weight: 400;

        @include respond-to($mobile-screen) {
          height: 30px;
          max-height: 30px;
          margin-top: 4px;
        }
      }

      &:first-child {
        padding-left: 40px;

        @include respond-to($large-screen) {
          padding-left: 35px;
        }

        @include respond-to($medium-screen) {
          padding-left: 25px;
        }

        @include respond-to($small-screen) {
          padding-left: 20px;
        }
      }

      @include respond-to($large-screen) {
        font-size: rem(15);
      }

      @include respond-to($medium-screen) {
        font-size: rem(14);
      }

      @include respond-to($medium-screen) {
        font-size: rem(12);
      }

      @include respond-to($mobile-screen) {
        font-size: rem(12);
        padding-left: 0px;
        height: 90px;
        color: $gray-400;
      }

      &:last-child {
        border-bottom-width: 0;
      }

      .email__content {
        width: 40%;

        & > strong {
          font-weight: 600;
        }
      }

      .email__subject {
        width: 90%;
        font-weight: 300;
        strong {
          font-weight: 500;
        }
      }

      .email__attachment-icon {
        position: relative;

        .icon {
          width: 22px;
          height: 22px;
          fill: $dark-grey;

          @include respond-to($large-screen) {
            width: 20px;
            height: 20px;
          }

          @include respond-to($medium-screen) {
            width: 18px;
            height: 18px;
          }

          @include respond-to($small-screen) {
            width: 16px;
            height: 16px;
          }
        }

        @include respond-to($mobile-screen) {
          margin-bottom: 30px;

          .icon {
            fill: #bdbfc0;
          }
        }

        & .attachment-icon__amount {
          position: absolute;
          bottom: -4px;
          right: -7px;
          background-color: $color-green;
          color: $color-white;
          width: 16px;
          height: 16px;
          line-height: 16px;
          border-radius: 50%;
          font-size: rem(10);
          @include flex;
        }
      }

      .email__date {
        width: 10%;
        font-weight: 300;
      }
    }

    .email-list__checkbox {
      @include respond-to($small-screen) {
        padding-left: 4px;
      }
    }

    & .email-list__email--selected {
      background-color: $color-cream;
      color: $dark-grey;
      font-weight: 300;
    }

    & .email-list__email--read {
      color: $dark-grey;
      font-weight: 300;
    }
  }

  &--controls {
    width: 100%;
    @include flex(flex-start);

    @include respond-to($small-screen) {
      flex-wrap: wrap;
    }

    .message_control {
      width: 150px;
      height: 40px;
      margin: 6px;

      @include respond-to($small-screen) {
        width: 100%;
        margin: 6px 0;
      }

      &--white {
        width: auto;
        border: 0;
        margin-left: auto;
        margin: 6px 0;
        margin-left: auto !important;
        @include respond-to($small-screen) {
          width: 100%;
        }

        @include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3));
        > .MuiButton-label {
          color: $base-green;
        }
      }

      &--text {
        border: 0;
        text-decoration: underline;
        background: none;
        font-size: rem(16);
        cursor: pointer;
      }
    }

    & .controls__attachFile {
      width: 45%;
      color: $dark-grey;
      @include flex(flex-start);

      @include respond-to($small-screen) {
        width: 100%;
      }

      &--desktop {
        @include respond-to($small-screen) {
          display: none;
        }
      }

      & .attachment-wrapper {
        & .fileInput {
          display: none;
        }
        &:focus {
          border: 1px solid $color-green;
        }
        & .icon {
          fill: $base-green;
          width: 20px;
          height: 20px;
          margin-right: 5px;
          .st0 {
            fill: $base-green;
          }
        }
      }

      & .docsIconWrapper {
        cursor: pointer;

        width: 45px;
        height: 45px;
        border: 1px solid $color-green;
        @include flex;

        & .icon {
          margin: 0;
          width: 20px;
          height: 20px;
          fill: $color-green;
        }
      }
    }

    .controls__button {
      height: 30px;
    }

    & .button--white {
      background: $color-white;
      color: $color-green;
      transition: all ease 0.3s;
    }

    & .button--white:focus {
      border: 1px solid $color-green;
    }

    & .button--white:disabled {
      color: $color-grey;
      &:hover {
        @include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3));
      }
    }

    &--mobile {
      display: none;

      @include respond-to($small-screen) {
        display: flex;
      }
    }
  }
}

.email-list--border {
  border-right: 1px solid $color-grey;
}

.messageToEl:not(:first-child) {
  display: none;
}

.Messages .service-info {
  margin-bottom: 32px;
}

.singleLawyerContainer {
  &--message {
    background: $gray-100;
    box-shadow: none;

    width: 100%;
    margin: 0 !important;

    @include respond-to($small-screen) {
      padding: 0px;

      .service-info__header {
        padding: 20px 20px 0;
      }

      > div {
        background: $gray-100;
        margin: 0;
        border: 0;
      }
    }
  }
}
