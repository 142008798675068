.service-description {
  font-size: rem(16);
  font-weight: 300;
  line-height: 19px;
  color: $dark-grey;

  @include respond-to($large-screen) {
    font-size: rem(15);
    line-height: 19px;
  }

  @include respond-to($medium-screen) {
    font-size: rem(14);
    line-height: 18px;
  }

  @include respond-to($small-screen) {
    font-size: rem(12);
    line-height: 17px;
  }

  &__header {
    padding-right: 15px;
    display: inline-block;
  }

  &__description {
    //padding-top: 20px;
    word-wrap: break-word;
  }
}
