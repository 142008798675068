.table-filters {
  width: 100%;
  height: 25px;
  @include flex(center, flex-start);
  flex-direction: column;

  &__input-documentRepo {
    min-width: initial;
    height: 100%;

    .lp-input__info {
      height: 0;
      width: 0;
    }

    .lp-input__field {
      width: calc(100% - 8px);
      margin: 20px 0px;
      height: 30px;
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0);
    }
  }
  &__input {
    min-width: initial;
    height: 100%;

    .lp-input__info {
      height: 0;
      width: 0;
    }

    .lp-input__field {
      width: 98%;
      margin: 20px 0;
      padding: 0;
      height: 30px;
      border-radius: 8px;
      background-color: rgba(90, 43, 43, 0);
      font-size: rem(11);
      text-align: start;
      padding: 0 0 0 10px;
      border: 1px solid #fefefe;
      box-shadow: 0px 2px 5px 0 #bbb;

      &::placeholder,
      ::-webkit-input-placeholder,
      ::-moz-placeholder,
      :-ms-input-placeholder,
      :-moz-placeholder {
        color: #999;
        font-weight: 100;
      }
    }
  }
  &__select {
    min-width: initial;
    width: calc(100% - 8px);
    height: 30px;
    border-radius: 7px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 3px 5px 0 rgba(0, 0, 0, 0.12);
    border: 2px;
  }
  &__select--service {
    min-width: initial;
    width: calc(100% - 4px);
    height: 30px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 3px 5px 0 rgba(0, 0, 0, 0.12);
    option {
      font-size: 14px;
    }
  }
}
