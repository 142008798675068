.ReactTable {
  .pagination-bottom {
    position: relative;
    //max-width: calc(50vw - 50px);
    max-width: 100%;

    @media (max-width: 768px) {
      max-width: 100%;
    }
    .Table__pagination--customer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
      border-top: none;
      height: 60px;
      margin: 10px 0 0 0;
      padding: 5px 0;

      @media (max-width: 768px) {
        justify-content: center;
        align-items: center;
        padding-bottom: 40px;
        margin: 30px 0 0 0;
      }
      .Table__nextPageWrapper--customer {
        width: 20%;
        display: flex;
        justify-content: center;
        @media (max-width: 768px) {
          width: auto;
        }

        .Table__pageButton--customer {
          font-size: 15px;
          outline: none;
          height: 40px;

          line-height: 1em;
          border: 2px solid $base-green;
          background-color: inherit;
          cursor: pointer;
          display: -webkit-flex;
          justify-content: center;
          align-items: center;

          .text {
            @media (max-width: 1200px) {
              display: none;
            }
          }
          .accordion-arrow {
            min-width: 12px;
            height: 12px;
            margin-left: 3px;
            fill: $navy-blue;
          }

          @include respond-to($medium-screen) {
            height: 30px;

            min-width: 30px;
            min-height: auto;
            font-size: 14px;
          }

          @media (max-width: 1200px) {
            width: 30px;
          }

          @media (max-width: 768px) {
            border-radius: 0;
            border: 1px solid rgb(214, 224, 225);
            border-left: none;
            width: 40px;
            height: 40px;
            .accordion-arrow {
              fill: $base-green;
            }
          }

          &:disabled {
            cursor: not-allowed;
            background: rgb(207, 209, 212);
            border: 2px solid rgb(207, 209, 212);
            color: white;
            .accordion-arrow {
              fill: white;
            }

            @media (max-width: 768px) {
              background: transparent;
              border: 1px solid rgb(214, 224, 225);
              border-left: none;

              .accordion-arrow {
                fill: $navy-blue;
              }
            }
          }
        }
      }
      .Table__prevPageWrapper--customer {
        width: 20%;
        display: flex;
        justify-content: center;

        @media (max-width: 768px) {
          width: auto;
        }
        .Table__pageButton--customer {
          font-size: 15px;
          outline: none;
          height: 40px;

          line-height: 1em;
          border: 2px solid $base-green;
          background-color: inherit;
          cursor: pointer;
          display: -webkit-flex;
          justify-content: center;
          align-items: center;

          .text {
            @media (max-width: 1200px) {
              display: none;
            }
          }
          .accordion-arrow {
            min-width: 12px;
            height: 12px;
            margin-right: 3px;
            fill: $navy-blue;
          }

          @include respond-to($medium-screen) {
            height: 30px;

            min-width: 30px;
            min-height: auto;
            font-size: 14px;
          }

          @media (max-width: 1200px) {
            width: 30px;
          }

          @media (max-width: 768px) {
            border-radius: 0;
            border: 1px solid rgb(214, 224, 225);
            border-right: none;
            width: 40px;
            height: 40px;
            .accordion-arrow {
              fill: $base-green;
            }
          }

          &:disabled {
            cursor: not-allowed;
            background: rgb(207, 209, 212);
            border: 2px solid rgb(207, 209, 212);
            color: white;
            .accordion-arrow {
              fill: white;
            }

            @media (max-width: 768px) {
              background: transparent;
              border: 1px solid rgb(214, 224, 225);
              border-right: none;
              width: 40px;
              height: 40px;
              .accordion-arrow {
                fill: $navy-blue;
              }
            }
          }
        }
      }
      .button__prev {
        .accordion-arrow {
          transform: rotate(180deg);
        }
      }
    }
    .Table__visiblePagesWrapper--customer {
      width: 55%;
      display: flex;
      justify-content: center;

      @media (max-width: 768px) {
        width: auto;
        justify-content: center;
        margin: 0;
      }
      .Table__pageInputWrapper {
        width: 25%;
        display: flex;
        justify-content: center;
      }
      .Table__pageInputWrapper--input {
        height: 30px;
        width: 80%;
        text-align: center;
        box-shadow: rgba(28, 28, 32, 0.3) 0px 1px 5px 0px;
        &::-webkit-inner-spin-button {
          margin: 0px;
          appearance: none;
        }
        &::-webkit-outer-spin-button {
          margin: 0px;
          appearance: none;
        }
      }

      .Table__dots {
        font-size: 16px;
        color: $navy-blue;
        margin: 0px 7px;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        @media (max-width: 768px) {
          background: transparent;
          border: 1px solid rgb(214, 224, 225);
          border-right: none;
          width: 40px;
          height: 40px;
          margin: 0;
          text-align: center;

          display: flex;
          justify-content: center;
          align-items: center;

          .accordion-arrow {
            fill: $navy-blue;
          }
        }
      }
      .Table__smallPageButton--customer {
        font-size: 16px;
        color: silver;
        margin: 0px 7px;
        border: 2px solid silver;
        background: none;
        outline: none;
        cursor: pointer;

        @include respond-to($medium-screen) {
          height: 30px;
          width: 30px;
          min-width: 30px;
          min-height: auto;
          font-size: 14px;
        }

        @media (max-width: 768px) {
          margin: 0;
          border: 1px solid rgb(214, 224, 225);
          border-right: none;
          border-radius: 0;
          color: $base-green;
          width: 40px;
          height: 40px;
          &:last-of-type {
            border-right: 1px solid rgb(214, 224, 225);
          }
        }
      }
      .Table__smallPageButton--customer--active {
        width: 80%;
        text-align: center;
        box-shadow: none;
        background: 0;
        border: 2px solid $base-green;
        color: black;

        @include respond-to($medium-screen) {
          height: 30px;
          width: 30px;
          min-width: 30px;
          min-height: auto;
          font-size: 14px;
        }

        @media (max-width: 768px) {
          margin: 0;
          border: 1px solid rgb(214, 224, 225);
          border-right: none;
          border-radius: 0;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
