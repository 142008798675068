.message-list-history-lawyer {
  position: relative;

  & .button-square--green {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__message {
    height: 30px;
    line-height: 30px;
    font-size: rem(16);
    font-weight: 300;
    color: $dark-grey;
    width: calc(100% - 40px);
    @include flex(flex-start);

    @include respond-to($large-screen) {
      font-size: rem(15);
    }

    @include respond-to($medium-screen) {
      font-size: rem(14);
      height: 25px;
      line-height: 25px;
    }

    @include respond-to($small-screen) {
      font-size: rem(12);
      height: 20px;
      line-height: 20px;
    }

    & .message__icon--camera {
      @include flex;
      margin-right: 15px;

      & .icon {
        width: 32px;
        height: 16px;
        fill: $dark-grey;
      }
    }

    & .message__icon {
      $size: 20px;
      min-width: $size;
      max-width: $size;
      min-height: $size;
      max-height: $size;
      border: 1px solid $dark-grey;
      border-radius: 50%;
      margin-right: 15px;
      @include flex;
      @include transition-very-slow;
      cursor: pointer;

      @include respond-to($medium-screen) {
        margin-right: 13px;
      }

      @include respond-to($small-screen) {
        margin-right: 10px;
      }

      &:hover {
        border-color: $color-green;
        & .icon {
          fill: $color-green;
        }
      }

      & .icon {
        width: 10px;
        height: 10px;
        fill: $dark-grey;
        @include transform(rotateZ(180deg));
      }
    }

    & .message__date {
      margin-right: 15px;

      @include respond-to($medium-screen) {
        margin-right: 13px;
      }

      @include respond-to($small-screen) {
        margin-right: 10px;
      }
    }

    & .message__content {
      margin-right: 15px;

      @include respond-to($medium-screen) {
        margin-right: 13px;
      }

      @include respond-to($small-screen) {
        margin-right: 10px;
      }
    }

    &--seen {
      color: $color-black;

      & .message__icon--camera {
        & .icon {
          fill: $color-black;
        }
      }
    }
  }
}
