@import "./src/variables";

.Header {
  position: fixed;
  width: 100%;
  background: #fff;
  top: 0;
  box-shadow: 0px 2px 5px 0px rgba(22, 18, 20, 0.15);
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  height: 80px;

  @media all and (max-width: 960px) {
    height: 60px;
  }

  @media all and (min-width: 768px) {
    padding: 16px 40px;
  }

  .logo {
    line-height: 13px;
    img {
      height: 50px;

      @media all and (max-width: 960px) {
        height: 30px;
      }
    }
  }

  .hamburger {
    cursor: pointer;
    background: none;
    padding: 0;
    border: 0;
    outline: none;
    margin-right: 4px;

    svg {
      width: 22px;
      fill: #8fa3aa;
    }
  }

  .login {
    text-decoration: none;

    button {
      //background: #fff;
      //color: $base-green;
      //box-shadow: 0px 1px 3px 0px rgba(50, 50, 50, 0.2);
    }
  }

  button {
    margin-left: 10px;
  }
}
