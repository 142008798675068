.message-list-history {
  // height: 60px;
  width: 100%;
  position: relative;
  padding: 40px;
  margin-bottom: 100px;

  & > footer {
    position: relative;
  }

  a {
    text-decoration: none;
  }

  @include respond-to($large-screen) {
    //   height: 55px;
    padding: 35px;
  }

  @include respond-to($medium-screen) {
    //   height: 50px;
    padding: 25px;
  }

  @include respond-to($small-screen) {
    //   height: 40px;
    padding: 20px;
  }

  &__title {
    font-size: rem(18);
    text-align: left;
    color: $navy-blue;
    margin-bottom: 20px;
    @include flex(flex-start, flex-end);

    @include respond-to($large-screen) {
      font-size: rem(17);
    }

    @include respond-to($medium-screen) {
      font-size: rem(16);
    }

    @include respond-to($small-screen) {
      font-size: rem(14);
    }

    & .title__lawyer {
      white-space: nowrap;
      margin-right: 10px;

      @include respond-to($large-screen) {
        margin-right: 8px;
      }

      @include respond-to($medium-screen) {
        margin-right: 6px;
      }

      @include respond-to($small-screen) {
        font-size: rem(16);
        color: black;
        margin-top: 20px;
      }
    }

    & .title__border {
      width: 100%;
      border-bottom: 1px solid $color-grey;
      margin-bottom: 3px;
      @include respond-to($small-screen) {
        display: none;
      }
    }

    @include respond-to($large-screen) {
      font-size: rem(17);
      margin-bottom: 18px;
    }

    @include respond-to($medium-screen) {
      font-size: rem(16);
      margin-bottom: 15px;
    }

    @include respond-to($small-screen) {
      font-size: rem(14);
      margin-bottom: 10px;
    }
  }
  &__newMessage {
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;

    & .message-info {
      color: #b5b7b8;
      font-size: 18px;
      width: 100%;
      text-transform: uppercase;
      font-weight: 300;
      margin-bottom: 24px;
    }
    & .message-button {
      width: 180px;
      font-size: 16px;
      float: right;
      color: #4b555f;
      height: 50px;
      border: solid 2px #8ec04b;
      border-radius: 5px;
      &:hover {
        @include box-shadow(0px 1px 3px 0 rgba(0, 0, 0, 0.25));
      }
    }
  }
  &__messages {
    @include flex(flex-start);
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;

    @include respond-to($large-screen) {
      margin-bottom: 17px;
    }

    @include respond-to($medium-screen) {
      margin-bottom: 12px;
    }

    @include respond-to($small-screen) {
      margin-bottom: 10px;
    }

    & span {
      width: 100%;
    }

    & .messages-enter {
      @include transition-very-slow;
      opacity: 0.01;
      @include transform(translateY(-100%));
    }

    & .messages-enter-active {
      opacity: 1;
      @include transform(translateY(0));
    }

    & .messages-leave {
      @include transition-very-slow;
      opacity: 1;
      @include transform(translateY(0));
    }

    & .messages-leave-active {
      opacity: 0.01;
      @include transform(translateY(-100%));
    }

    & .messages__message {
      @include flex(flex-start);
      height: 60px;
      border-top: 1px solid $color-grey;
      width: 100%;

      &:first-child {
        border-top-width: 0;
      }

      @include respond-to($large-screen) {
        height: 55px;
      }

      @include respond-to($medium-screen) {
        height: 50px;
      }

      @include respond-to($small-screen) {
        height: 90px;
      }

      & .message__button {
        width: 18%;
        &--mobile {
          width: auto;
          margin-right: 20px;
        }

        .MuiButton-root {
          font-size: 18px;
          line-height: 26px;
          padding: 5px 26px;
          font-weight: normal;
          color: $navy-blue;

          @media (max-width: 1500px) {
            font-size: 16px;
            // padding: 5px 26px;
            padding: 5px 16px;
            line-height: 26px;
          }

          @include respond-to($medium-screen) {
            font-size: 14px;
          }

          @media (max-width: 1170px) {
            font-size: 14px;
            // padding: 5px 26px;
            padding: 5px 10px;
            line-height: 26px;
          }

          @media (max-width: $small-screen) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      & .message__mobile {
        width: 60%;
      }
      & .message__date {
        font-size: rem(16);
        font-weight: 300;
        color: $dark-grey;
        width: 15%;
        text-align: left;

        &.unread {
          color: $navy-blue;
        }

        &--mobile {
          width: auto;
          font-size: 12px !important;
          margin-bottom: 10px;
          font-weight: 400;
        }

        @include respond-to($large-screen) {
          font-size: 16px;
        }

        @include respond-to($medium-screen) {
          font-size: rem(12);
        }

        @include respond-to($small-screen) {
          font-size: rem(10);
        }
      }

      & .message__icon {
        width: 8%;
        @include flex(flex-start);

        &.unread {
          & .icon {
            .st0 {
              fill: $navy-blue !important;
            }
          }
        }

        & .icon {
          width: 32px;
          height: 16px;

          .st0 {
            fill: $dark-grey !important;
          }

          @include respond-to($large-screen) {
            width: 30px;
            height: 15px;
          }

          @include respond-to($medium-screen) {
            width: 26px;
            height: 13px;
          }

          @include respond-to($small-screen) {
            width: 20px;
            height: 10px;
          }
        }
      }

      & .message__content {
        width: 60%;
        font-size: rem(16);
        font-weight: 300;
        color: $dark-grey;
        letter-spacing: normal;

        &--mobile {
          width: auto;
          font-size: 14px !important;
          font-weight: 400;
          color: $navy-blue;

          &.unread {
            font-weight: bold;
          }
        }

        @include respond-to($large-screen) {
          font-size: 16px;
        }

        @include respond-to($medium-screen) {
          font-size: rem(14);
        }

        @include respond-to($small-screen) {
          font-size: rem(12);
        }

        strong {
          font-weight: 500;
        }
        &.unread {
          color: $navy-blue;
          font-weight: 400;
        }
      }
    }

    & .selected {
      & .message__date {
        font-weight: 500;
      }

      & .message__icon {
        .icon {
          font-weight: 500;
        }
      }

      & .message__content {
        font-weight: 400;
      }
    }
  }

  &__footer {
    @include flex(flex-start);
    position: relative;

    & .button__wrapper {
      width: 18%;

      & .footer__button {
        margin-bottom: 30px;
        margin-right: 20px;
        height: 40px;
        line-height: 36px;
        min-width: 110px;

        @include respond-to($large-screen) {
          margin-right: 17px;
          margin-bottom: 27px;
          height: 35px;
          line-height: 31px;
          min-width: 100px;
          width: 90%;
          padding: 0;
        }

        @include respond-to($medium-screen) {
          margin-right: 15px;
          margin-bottom: 25px;
          height: 25px;
          line-height: 21px;
          min-width: 80px;
        }

        @include respond-to($small-screen) {
          margin-right: 10px;
          margin-bottom: 20px;
          height: 20px;
          line-height: 16px;
          min-width: 60px;
        }
      }
    }
  }
}

.service__messages {
  .messages__message {
    .message__button {
      .MuiButton-root {
        font-size: 16px !important;
        line-height: 26px;
        padding: 5px 26px;
        font-weight: normal;
        color: $navy-blue;

        @media (max-width: 1500px) {
          font-size: 16px !important;
          // padding: 5px 26px;
          padding: 5px 16px;
          line-height: 26px;
        }

        @include respond-to($medium-screen) {
          font-size: 14px;
        }

        @media (max-width: 1170px) {
          font-size: 14px !important;
          // padding: 5px 26px;
          padding: 5px 10px;
          line-height: 26px;
        }

        @media (max-width: $small-screen) {
          font-size: 12px !important;
          line-height: 16px;
        }
      }
    }
  }
}
