.blogSection {
  display: flex;
  flex-wrap: wrap;

  .footnotes {
    margin-top: 10px;
    color: #7b8289;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 1.5rem;
  }
  .strong {
    font-weight: 900;
  }
  .ikona_header_blog {
    margin-right: 10px;
  }
  .text_blog_header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .blogContent {
    flex: 0 0 70%;
    max-width: 70%;
    padding-right: 1.5rem;
    .blogHeaderCont {
      border-bottom: solid 1px #dde3e5;
      margin-top: 80px;
      .blogHeaderFirst {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
      .blogHeaderSecond {
        padding-bottom: 1.5rem;
      }
    }
    .blogLoopPostCont {
      display: flex;
      flex-wrap: wrap;
      padding: 2rem 0;
      border-bottom: solid 1px #dde3e5;
      min-height: 210px;
      .blogLoopPostImg {
        background-color: #dde3e5;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .blogLoopPostContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .blogLoopPostDate {
          color: #cccdce;
          margin-bottom: 0.3rem;
          font-weight: 300;
          font-size: 14px;
        }
        .blogLoopPostTitle {
          color: #94c355;
          font-weight: 600;
          margin-bottom: 0.7rem;
          font-size: 18px;
        }
        .blogLoopPostExcerpt {
          color: #7b8289;
          font-weight: 400;
          font-size: 15px;
        }
        .blogLoopPostButton {
          max-width: 85px;
          margin-top: 15px;
        }
      }
    }
  }
  .blogPostCont {
    margin-top: 80px;
    .blogPostHeaderCont {
      border-bottom: solid 1px #e8ebec;
      padding-bottom: 2rem;
      .blogPostHeader {
        color: #94c355;
        margin-bottom: 0.5rem;
      }
      .blogPostHeaderMeta {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #444444;
        .blogPostHeaderCat,
        .blogPostHeaderAuthor {
          text-decoration: underline;
          a {
            color: #444444;
          }
        }
      }
    }
    .blogPostTitle {
      font-size: 1.5rem;
      margin: 1.5rem 0;
    }
    .blogPostTeaser,
    .blogPostDesc {
      color: #7b8289;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 1.5rem;
    }
    .postImage {
      width: 100%;
      height: auto;
      border-radius: 5px;
    }
    .blogPostDescTitle {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    .blogPostAuthorCont {
      text-align: right;
      .blogPostAuthorContInner {
        text-align: left;
        display: inline-block;
        .blogPostAuthorRole {
          font-size: 13px;
          font-weight: 600;
          color: #94c355;
        }
        .blogPostAuthorSign {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    .blogPostLawyerCont {
      background-color: #f8f8f8;
      margin: 3.5rem 0;
      .blogPostLawyerHeader {
        .themeGreen {
          color: #94c355;
          font-weight: 700;
        }
      }
      .exampleHeight {
        border-radius: 5px;
        min-height: 250px;
        background-color: #fff;
        border-top: solid 4px #94c355;
        -webkit-box-shadow: 0px 3px 12px 4px rgba(204, 204, 204, 0.5);
        box-shadow: 0px 3px 12px 4px rgba(204, 204, 204, 0.5);
      }
    }
    form {
      .MuiFormControl-root {
        width: 100%;
      }
    }
  }
  .blogSidebarCont {
    flex: 0 0 30%;
    max-width: 30%;
    margin-top: 80px;

    .BlogSidebarElem1,
    .BlogSidebarElem2,
    .BlogSidebarElem3 {
      background-color: #f8f8f8;
      border: solid 1px #e8ebec;
      padding: 2rem;
      margin-bottom: 2rem;
    }

    .BlogSidebarElem1,
    .BlogSidebarElem2,
    .BlogSidebarElem3 {
      .BlogSidebarElemHeader {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 1.5rem;
      }
      .BlogSidebarElemTitle,
      .BlogSidebarElemCategoryTitle {
        font-weight: 300;
        color: #848c91;
        transition: ease 0.3s;
        a {
          text-decoration: none;
          color: #848c91;
        }
      }
      .BlogSidebarElemTitle:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      .BlogSidebarElemTitle:hover {
        text-decoration: underline;
        color: #94c355;
        transition: ease 0.3s;
        cursor: pointer;
        a {
          color: #94c355;
        }
      }
      .BlogSidebarElemCategoryCont {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.3rem;
        font-size: 15px;
        .BlogSidebarElemCategoryTitle:hover {
          cursor: pointer;
        }
        .BlogSidebarElemCategoryNumber {
          font-weight: bold;
          font-weight: 16px;
        }
      }
    }
  }
  .blogCategoryHeaderCont {
    width: 100%;
    text-align: center;
    margin-top: 80px;
    padding: 1.5rem;
    font-weight: 600;
    border-bottom: solid 1px #e8ebec;
    .blogCategoryHeaderTop {
      font-size: 17px;
    }
    .blogCategoryHeaderBottom {
      font-size: 35px;
      font-weight: 400;
    }
  }
  .blogCategoryNewCont,
  .blogCategoryPopularCont {
    margin-top: 50px;
    //width: 100%;
    .blogCategoryPostsHeader {
      color: #94c355;
      font-size: 27px;
      margin-bottom: 30px;
    }
    .blogCategoryPostsRow {
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      justify-content: center;
      .blogCategoryPostCont {
        .blogCategoryPostInner {
          margin-right: 1rem;
          border-radius: 5px;
          border: solid 1px #dde3e5;
          border-radius: 5px;
          .blogCategoryPostImg {
            border-radius: 5px 5px 0 0;
            background-image: url("https://via.placeholder.com/300x175");
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            min-height: 210px;
          }
          .blogCategoryPostMeta {
            padding: 2rem;
            background-color: #fff;
            border-radius: 0 0 5px 5px;

            .blogCategoryButton {
              margin-top: 1rem;
            }

            .blogCategoryPostDate {
              font-size: 14px;
              font-weight: 300;
              color: #cccdce;
              margin-bottom: 0.6rem;
            }
            .blogCategoryPostTitle {
              color: #94c355;
              font-weight: bold;
              margin-bottom: 0.7rem;
              font-size: 17px;
            }
            .blogCategoryPostExcerpt {
              color: #7b8289;
              font-weight: 400;
              font-size: 15px;
            }
            .blogCategoryPostButton {
              font-weight: 600;
              border: solid 2px #94c355;
              padding: 7px 15px;
              background-color: #fff;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              border-radius: 5px;
              margin-top: 1rem;
              max-width: 86px;
            }
          }
        }
      }
    }
  }
  .blogCategoryPopularCont {
    background-color: #f8f8f8;
    padding-bottom: 3rem;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    border-bottom: solid 2px #dde3e5;
    .blogCategoryPopularInner {
      margin-left: auto;
      margin-right: auto;
      padding: 0 20px;
      .blogCategoryPostsHeader {
        margin-top: 30px;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .blogHeaderSecond,
    .blogPostHeaderMeta {
      width: 55%;
    }
  }
  @media screen and (max-width: 1200px) {
    .blogHeaderSecond {
      width: 80%;
    }
    .blogPostHeaderMeta {
      width: 70%;
    }
  }
  @media screen and (max-width: 978px) {
    .blogSidebarCont,
    .blogContent {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 0;
    }
  }
  @media screen and (max-width: 768px) {
    .blogHeaderSecond {
      width: 100%;
    }
    .blogLoopPostContent {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 1rem;
    }
    .blogLoopPostImg {
      flex: 0 0 100%;
      min-height: 200px;
      max-width: 320px;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .blogPostCont {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .blogPostHeader {
      font-size: 2.1rem;
      width: 80%;
    }
    .blogPostHeaderMeta {
      width: 70%;
    }
    .blogPostLawyerHeader {
      font-size: 1.3rem;
      padding: 1rem 2rem;
    }
    .blogCategoryPostCont {
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 1.5rem;
    }
  }
  @media screen and (min-width: 768px) {
    .blogLoopPostContent {
      flex: 0 0 70%;
      max-width: 70%;
      padding-left: 2rem;
    }
    .blogLoopPostImg {
      flex: 0 0 30%;
      max-width: 30%;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .blogPostCont {
      flex: 0 0 70%;
      max-width: 70%;
      padding-right: 1.5rem;
    }
    .blogPostHeader {
      width: 90%;
      font-size: 2.1rem;
    }
    .blogPostLawyerHeader {
      font-size: 2rem;
      padding: 1.5rem 3rem;
    }
    .blogCategoryPostCont {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  }
  @media screen and (max-width: 568px) {
    .blogHeaderSecond,
    .blogPostHeader {
      font-size: 1.5rem;
    }
    .blogPostHeader,
    .blogPostHeaderMeta {
      width: 100%;
    }
    .blogPostLawyerCont {
      padding: 1rem;
    }
    .blogPostLawyerHeader {
      font-size: 1rem;
      font-weight: 600;
      padding: 1rem 0;
    }
    .blogCategoryPostCont {
      flex: 0 0 100%;
      max-width: 100%;
      .blogCategoryPostInner {
        margin-right: 0 !important;
      }
    }
  }
  @media screen and (min-width: 568px) {
    .blogHeaderSecond {
      font-size: 2.5rem;
    }
    .blogPostLawyerCont {
      padding: 2rem;
    }
  }
}

// Po rozwinięciu

.expanded-right {
  .blogPostCont {
    max-width: 100%;
    min-width: 100%;
  }
  .blogSidebarCont {
    min-width: 100%;
  }
  .BlogSidebarElem1 {
    width: 100%;
  }
}
