.document-list {
  &__input-filter {
    width: 100%;
    & .table-filters__input {
      width: 100%;
      & .input__field {
        width: 100%;
      }
    }
  }
  &__checkbox {
    height: 16px;
    width: 16px;
    border: 1px solid #b5b7b8;
    background-color: white;
    border-radius: 2px;
    &:hover {
      // background-color: #989898;
      background-color: $dark-grey;
    }
  }
  &__checkbox--selected {
    height: 16px;
    width: 16px;
    border: 1px solid #b5b7b8;
    border-radius: 2px;
    background-color: #8ec04b;
    &:hover {
      // background-color: #989898;
      background-color: $dark-grey;
    }
  }
  &__footer {
    width: 100%;
    border-top: 1px solid #d5d7d9;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    //margin-top: 10px;
    & .buttons {
      min-width: 400px;
      & .cancelButton {
        margin-left: 10px;
        width: 158px;
        height: 40px;
        background-color: #8ec04b;
        border-radius: 5px;
        color: white;
        &:hover {
          background-color: #d2e6b7;
        }
        &:disabled {
          background-color: #d5d7d9;
        }
      }
      & .selectButton {
        margin-left: 39px;
        width: 158px;
        height: 40px;
        border-radius: 5px;
        color: white;
        &:hover {
          background-color: #d2e6b7;
        }
        &:disabled {
          background-color: #d5d7d9;
        }
      }
    }
    & .counter {
      width: 100%;
      text-align: right;
      padding-right: 40px;
      // color: #989898;
      color: $dark-grey;
    }
  }
  &__service {
    font-size: rem(16);
    font-weight: 300;
    color: $dark-grey;
    min-height: 31px;
    width: 100%;
    @include flex;
    @include respond-to($large-screen) {
      font-size: rem(14);
    }

    @include respond-to($medium-screen) {
      font-size: rem(12);
    }

    @include respond-to($small-screen) {
      font-size: rem(10);
    }

    &:last-child {
      border-bottom-width: 0px;
    }

    .service__service-id {
      width: 20%;
    }

    .service__state {
      width: 15%;
    }
  }
}
