@import "src/variables";

.CompletePaymentAfterConfirmation {
  .title {
    font-weight: 400;
    text-align: center;
    font-size: 30px;
  }

  .subtitle {
    padding-top: 32px;
    text-align: center;
  }

  .summary {
    color: $gray-500;
    margin-bottom: 8px;
    margin-top: 16px;
  }

  .introduction {
    text-align: center;
    border: 0.5px solid #c3c3c3;
    padding: 26px 20px;
    border-radius: 6px;
    margin-bottom: 32px;

    .price {
      color: $base-green;
      font-size: 22px;
      font-weight: 900;
    }

    .desc {
      color: $gray-500;
      line-height: 23px;
    }
  }

  .graySection {
    //background: #f8f8f8;
    //margin: 0 -16px;
    //padding: 16px;
    text-align: center;
  }

  .timer {
    color: $base-green;
    font-size: 22px;
    font-weight: 900;
    margin-top: 16px;
  }
}
