@import "src/variables";
.Confirmation {
  padding-top: 32px;
  text-align: center;

  .title {
    font-weight: 400;
    text-align: center;
    font-size: 30px;
  }

  .content {
    color: $gray-500;

    @media all and(max-width: 425px) {
      text-align: left;
    }
  }

  .toHome {
    text-decoration: none;
    margin-top: 36px;
    display: inline-block;

    button {
      width: 320px;
      max-width: 100%;
    }
  }
}
