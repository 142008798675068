.lp-input {
  box-sizing: border-box;
  outline: none;
  text-align: center;
  width: 100%;
  min-width: 200px;
  display: inline-block;
  color: $color-white;

  &__info {
    line-height: 20px;
    height: 20px;
    width: 100%;

    @include respond-to($medium-screen) {
      height: 15px;
      line-height: 15px;
    }

    @include respond-to($small-screen) {
      height: 10px;
      line-height: 10px;
    }
  }

  &__label {
    font-size: rem(18);
    text-align: left;
    color: $color-green;
    margin: 9px;
  }

  &__field {
    height: 80px;
    width: 100%;
    max-width: 800px;
    border-radius: 5px;
    background-color: $color-white;
    font-size: rem(30);
    font-weight: 300;
    letter-spacing: 0.9px;
    text-align: center;
    display: inline-block;
    border: 1px solid $color-white;
    -webkit-appearance: none;
    @include box-shadow(0px 2px 5px 0 rgba(22, 18, 20, 0.25));

    @include respond-to($large-screen) {
      height: 70px;
      font-size: rem(26);
    }

    @include respond-to($medium-screen) {
      height: 60px;
      font-size: rem(24);
    }

    @include respond-to($small-screen) {
      height: 50px;
      font-size: rem(20);
    }

    &::placeholder,
    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder,
    :-moz-placeholder {
      color: $color-grey;
      font-weight: 100;
    }

    &:focus {
      color: $navy-blue;
      border-color: $color-green;
    }

    &:active {
      color: $navy-blue;
      border-color: $color-green;
    }

    &:disabled,
    &:disabled:active {
      border-color: $light-grey;
      cursor: not-allowed;
      box-shadow: none;
      -webkit-box-shadow: none;
    }
    &:disabled:active {
      background-color: $light-grey;
    }
  }
}

.has-error {
  border: 1px solid $color-red;
  &:focus {
    border: 1px solid $color-red;
  }
}

.switch-wrapper {
  width: 89px;
  height: 43px;

  @include respond-to($large-screen) {
    width: 80px;
    height: 39px;
  }

  @include respond-to($medium-screen) {
    width: 70px;
    height: 34px;
  }

  @include respond-to($small-screen) {
    width: 60px;
    height: 29px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  text-align: center;
  @include flex;

  &__input {
    position: absolute;
    top: 0;
    left: 5px;
    background-color: #cccccc;
    border-radius: 21.5px;
    display: inline-block;
    width: 89px;
    height: 43px;
    -webkit-appearance: none;
    cursor: pointer;
    @include transition-slow;

    @include respond-to($large-screen) {
      width: 80px;
      height: 39px;
    }

    @include respond-to($medium-screen) {
      width: 70px;
      height: 34px;
    }

    @include respond-to($small-screen) {
      width: 60px;
      height: 29px;
    }

    &:checked {
      background-color: $color-green;
    }

    &:checked + .switch__circle {
      @include transition-slow;
      left: 43px;

      @include respond-to($large-screen) {
        left: 39px;
      }

      @include respond-to($medium-screen) {
        left: 34px;
      }

      @include respond-to($small-screen) {
        left: 29px;
      }

      .circle__icon {
        fill: $color-green;
      }
    }
  }

  &__circle {
    cursor: pointer;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    display: inline-block;
    width: 55px;
    height: 55px;
    line-height: 61px;
    text-align: center;
    left: 0;
    top: -6px;
    @include border-radius(50%);
    @include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3));
    @include transition-slow;

    @include respond-to($large-screen) {
      width: 51px;
      height: 51px;
      line-height: 57px;
    }

    @include respond-to($medium-screen) {
      width: 46px;
      height: 46px;
      line-height: 52px;
    }

    @include respond-to($small-screen) {
      width: 40px;
      height: 40px;
      line-height: 46px;
    }

    .circle__icon {
      @include transition-slow;
      width: 25px;
      height: 20px;
      fill: $color-white;

      @include respond-to($large-screen) {
        width: 23px;
        height: 18px;
      }

      @include respond-to($medium-screen) {
        width: 21px;
        height: 17px;
      }

      @include respond-to($small-screen) {
        width: 18px;
        height: 14px;
      }
    }
  }
}

.checkbox-wrapper {
  @include flex;

  & > span {
    fill: $dark-grey;
    @include flex(flex-start);
    cursor: pointer;

    .checkbox-selected,
    .checkbox-unselected {
      @include transition-slow;
      width: 19px;
      height: 16px;
      margin-right: 5px;
    }
  }
}

.select-wrapper {
  .select__label {
    font-size: rem(16);
    padding-left: 10px;
  }
  select {
    width: 100%;
    min-width: initial;
    max-width: initial;
    font-size: rem(16);
    padding: 8px 10px;
    text-align: left;
    height: auto;
  }
}
