.step-tabs {
  width: 100%;
  @include box-shadow(0 2px 5px 0 rgba(22, 18, 20, 0.25));
  background-color: $color-white;
  max-height: 100%;

  @media all and (max-width: 767px) {
    width: 100vw;
    margin: 0 -16px;
    border-radius: 0;
    box-shadow: none;
  }

  &__nav {
    height: 60px;
    width: 100%;
    @include flex;
    //border-bottom: 2px solid #d5d7d9;
    border-bottom: 2px solid #c0c0c0;
  }

  &__mobileNav {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;

    .tab__icon {
      display: none;
    }
  }

  &.active {
    color: black;

    .icon {
      fill: $color-green;
      .st0 {
        fill: $color-green;
      }
    }
  }

  &--admin {
    .step-tabs {
      &__nav {
        .step-tabs__tab {
          background: white;
          border: 0;
          position: relative;

          &:not(:last-child):after {
            content: "";
            display: block;
            position: absolute;
            height: 30px;
            width: 1px;
            background-color: $light-grey;
            right: 0;
            top: 55%;
            transform: translateY(-50%);
          }

          .icon {
            width: 26px;
            height: 26px;
            fill: $dark-grey;

            &.wide {
              width: 38px;
            }
            .st0 {
              fill: $dark-grey;
            }
          }

          &.active {
            color: black;

            .icon {
              fill: $color-green;
              .st0 {
                fill: $color-green;
              }
            }
          }
        }
      }

      &__content {
        height: calc(100% - 60px);
        position: relative;

        @include respond-to($large-screen) {
          height: calc(100% - 60px);
        }

        @include respond-to($medium-screen) {
          height: calc(100% - 60px);
        }

        @include respond-to($small-screen) {
          height: calc(100% - 60px);
        }

        @media all and (max-width: 767px) {
          height: auto;
        }
      }
    }
  }

  &--pagination {
    .step-tabs__content {
      height: calc(100vh - 260px);
      position: relative;

      @include respond-to($large-screen) {
        height: calc(100vh - 260px);
      }

      @include respond-to($medium-screen) {
        height: calc(100vh - 260px);
      }

      @include respond-to($small-screen) {
        height: calc(100vh - 260px);
      }

      @media all and (max-width: 767px) {
        height: auto;
      }
    }
  }

  &__content {
    height: calc(100vh - 210px);
    position: relative;

    @include respond-to($large-screen) {
      height: calc(100vh - 210px);
    }

    @include respond-to($medium-screen) {
      height: calc(100vh - 210px);
    }

    @include respond-to($small-screen) {
      height: calc(100vh - 210px);
    }

    @media all and (max-width: 767px) {
      height: auto;
    }

    & .content__right-padding {
      padding-right: 28px;
      max-height: calc(100vh - 200px);

      @include respond-to($large-screen) {
        max-height: calc(100vh - 170px);
      }

      @include respond-to($medium-screen) {
        max-height: calc(100vh - 150px);
      }

      @include respond-to($small-screen) {
        max-height: calc(100vh - 100px);
      }

      .ps__rail-y {
        right: 10px;
      }

      &--settlements {
        max-height: calc(100vh - 320px);
      }
    }
  }

  &__tab {
    border-right: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;
    background-color: $color-cream;
    height: 60px;
    @include flex;
    font-size: rem(18);
    color: #b5b7b8;
    width: 100%;
    cursor: pointer;

    @include respond-to($large-screen) {
      font-size: rem(16);
    }

    @include respond-to($medium-screen) {
      font-size: rem(14);
    }

    @include respond-to($small-screen) {
      font-size: rem(12);
    }

    &:first-child {
      border-radius: 5px 0 0 0;
    }

    &:last-child {
      border-right-width: 0px;
      border-radius: 0 5px 0 0;
    }

    .tab__icon {
      @include flex(flex-start);

      & .icon {
        width: 26px;
        height: 26px;
        fill: $color-grey;
        margin-right: 10px;

        &--big {
          width: 36px;
          height: 36px;
        }

        .st0 {
          fill: $color-grey;
        }

        @include respond-to($medium-screen) {
          width: 16px;
          height: 16px;
          margin-right: 6px;

          &--big {
            width: 22px;
            height: 22px;
          }
        }

        @include respond-to($small-screen) {
          width: 0px;
          height: 0px;
          margin-right: 0;

          &--big {
            width: 0px;
            height: 0px;
          }
        }
      }
    }
  }

  &__mobileTab {
    background-color: $light-green;
    border-radius: 4px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 101px;
    color: $dark-green;

    &.active {
      background-color: $base-green;
      color: #fff;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .active {
    background-color: $color-white;
    border-bottom: 1px solid $color-white;
    color: $navy-blue;

    .icon {
      fill: $color-green;
      .st0 {
        fill: $color-green;
      }
    }
  }
}
