.lp-button {
  display: inline-block;
  padding: 0 25px;
  height: 50px;
  line-height: 46px;
  font-size: rem(18);
  text-align: center;
  color: $color-white;
  background-color: $color-green;
  border: 2px solid $color-green;
  border-radius: 5px;
  @include transition-very-slow;
  cursor: pointer;

  @include respond-to($medium-screen) {
    height: 40px;
    line-height: 36px;
    font-size: rem(14);
    padding: 0 20px;
  }

  @include respond-to($small-screen) {
    height: 30px;
    line-height: 26px;
    font-size: rem(12);
    padding: 0 15px;
  }

  &:hover {
    @include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3));
  }

  &:active {
    color: $dark-green;
    background-color: $light-green;
    border-color: $light-green;
  }

  &:disabled {
    background-color: $color-grey;
    border-color: $color-grey;
    cursor: not-allowed;
  }

  &:focus {
    border-color: $dark-green;
  }

  &--active {
    color: $dark-green;
    background-color: $light-green;
    border-color: $light-green;
  }

  &--loading {
    &-wrapper {
      position: relative;
    }

    &__icon {
      position: absolute;
      width: 100%;
      height: 100%;
      @include flex;
      top: 0;
      left: 0;
      z-index: 5;
      animation: rotation 5s linear infinite;

      & .loading-icon {
        width: 29px;
        height: 29px;
        fill: $dark-green;
      }

      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &--white {
    @include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3));

    &:focus {
      border-color: $color-white;
    }
  }

  &--red {
    background: red;
    border: 1px solid red;
    margin-left: 10px;
  }
  &--bordered {
    border-color: $color-white;
    background-color: $color-green;

    &:active {
      background-color: $color-white;
    }

    &:disabled {
      border-width: 0;
      cursor: not-allowed;
    }

    &:focus {
      border: 3px solid $color-white;
    }
  }

  &--disabled {
    border-color: $color-green;
    background-color: $color-white;
    color: $dark-green;

    &:hover {
      background-color: $color-white;
      color: $dark-green;
    }

    &:active {
      border-color: $color-green;
    }

    &:focus {
      border-color: $color-green;
    }
  }

  &--big {
    width: 263px;
    height: 80px;
    line-height: 76px;
    border-color: $color-green;

    &--responsive {
      height: 100%;
    }
  }

  &--link {
    color: $color-green;
    text-decoration: underline;
  }

  &--login {
    height: 45px;
    line-height: 41px;

    @include respond-to($medium-screen) {
      height: 40px;
      line-height: 36px;
    }

    @include respond-to($small-screen) {
      height: 30px;
      line-height: 26px;
    }
  }

  &--logout {
    height: 45px;
    line-height: 41px;
    background-color: $color-white;
    border-color: $color-white;
    @include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3));
    color: $color-green;

    @include respond-to($medium-screen) {
      height: 40px;
      line-height: 36px;
    }

    @include respond-to($small-screen) {
      height: 30px;
      line-height: 26px;
    }
  }

  &--input {
    width: 100%;
    min-width: 200px;
    height: 80px;
    line-height: 76px;
    margin-top: 20px;

    @include respond-to($large-screen) {
      height: 70px;
      line-height: 66px;
    }

    @include respond-to($medium-screen) {
      height: 60px;
      line-height: 56px;
      margin-top: 15px;
    }

    @include respond-to($small-screen) {
      height: 50px;
      line-height: 46px;
      margin-top: 10px;
    }
  }
}

//   @mixin circle-button($width: 55px, $height: 55px) {
// 	position: relative;
// 	display: inline-block;
// 	width: $width;
// 	height: $height;
// 	min-width: $width;
// 	object-fit: contain;
// 	background-color: $color-white;
// 	fill: $color-green;
// 	border-radius: 50%;
// 	@include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3));
// 	@include transition-very-slow;
// 	@include flex;

// 	&:hover {
// 	  background-color: $color-green;
// 	  fill: $color-white;
// 	}
//   }

//   .button-back {
// 	@include circle-button;
// 	@include flex;

// 	@include respond-to($medium-screen) {
// 	  @include circle-button(45px, 45px);
// 	}

// 	@include respond-to($small-screen) {
// 	  @include circle-button(30px, 30px);
// 	}

// 	.thin-arrow {
// 	  width: 11px;
// 	  height: 18px;
// 	  transform: rotate(180deg);

// 	  @include respond-to($medium-screen) {
// 		width: 8px;
// 		height: 13px;
// 	  }

// 	  @include respond-to($small-screen) {
// 		width: 5px;
// 		height: 9px;
// 	  }
// 	}
//   }

//   .button-send {
// 	@include circle-button(80px, 80px);
// 	@include flex;

// 	@include respond-to($medium-screen) {
// 	  @include circle-button(60px, 60px);
// 	}

// 	@include respond-to($small-screen) {
// 	  @include circle-button(40px, 40px);
// 	}

// 	.send-arrow {
// 	  width: 33px;
// 	  height: 30px;

// 	  @include respond-to($large-screen) {
// 		width: 29px;
// 		height: 27px;
// 	  }

// 	  @include respond-to($medium-screen) {
// 		width: 26px;
// 		height: 24px;
// 	  }

// 	  @include respond-to($small-screen) {
// 		width: 21px;
// 		height: 20px;
// 	  }
// 	}
//   }

//   .button-link {
// 	width: 100%;
// 	line-height: 46px;

// 	&:hover {
// 	  cursor: pointer;

// 	  .button {
// 		background-color: $light-green;
// 		border-color: $light-green;
// 		color: $dark-green;
// 	  }
// 	}
//   }

@mixin circle-button($width: 55px, $height: 55px) {
  position: relative;
  display: inline-block;
  width: $width;
  height: $height;
  min-width: $width;
  object-fit: contain;
  background-color: $color-white;
  fill: $color-green;
  border-radius: 50%;
  cursor: pointer;
  @include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3));
  @include transition-very-slow;
  @include flex;

  &:hover {
    background-color: $color-green;
    fill: $color-white;
  }
}

.button-back {
  @include circle-button;
  @include flex;

  @include respond-to($medium-screen) {
    @include circle-button(45px, 45px);
  }

  @include respond-to($small-screen) {
    @include circle-button(30px, 30px);
  }

  .thin-arrow {
    width: 11px;
    height: 18px;
    transform: rotate(180deg);

    @include respond-to($medium-screen) {
      width: 8px;
      height: 13px;
    }

    @include respond-to($small-screen) {
      width: 5px;
      height: 9px;
    }
  }
}

.button-send {
  @include circle-button(80px, 80px);
  @include flex;

  @include respond-to($medium-screen) {
    @include circle-button(60px, 60px);
  }

  @include respond-to($small-screen) {
    @include circle-button(40px, 40px);
  }

  .send-arrow {
    width: 33px;
    height: 30px;

    @include respond-to($large-screen) {
      width: 29px;
      height: 27px;
    }

    @include respond-to($medium-screen) {
      width: 26px;
      height: 24px;
    }

    @include respond-to($small-screen) {
      width: 21px;
      height: 20px;
    }
  }

  &--small {
    @include circle-button(30px, 30px);
    @include flex;

    .send-arrow {
      width: 17px;
      height: 15px;
    }
  }
}

.button-link {
  width: 100%;
  line-height: 46px;

  &:hover {
    cursor: pointer;

    .button {
      background-color: $light-green;
      border-color: $light-green;
      color: $dark-green;
    }
  }
}

.button-circle--green {
  &:disabled {
    background-color: $color-grey;
    border-color: $color-grey;
    cursor: not-allowed;

    &:hover {
      background-color: $color-grey;
      border-color: $color-grey;
    }
  }

  @include circle-button(40px, 40px);
  background-color: $color-green;
  fill: $color-white;
  @include box-shadow(initial);

  &-invert {
    background-color: $color-white !important;
    border: 1px solid $color-green;

    &:hover {
      @include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3) !important);
    }

    .icon {
      fill: $color-green;
    }
  }

  @include respond-to($medium-screen) {
    @include circle-button(35px, 35px);
    background-color: $color-green;
    fill: $color-white;
    @include box-shadow(initial);
  }

  @include respond-to($small-screen) {
    @include circle-button(30px, 30px);
    background-color: $color-green;
    fill: $color-white;
    @include box-shadow(initial);
  }

  .icon {
    width: 20px;
    height: 20px;

    @include respond-to($large-screen) {
      width: 19px;
      height: 19px;
    }

    @include respond-to($medium-screen) {
      width: 18px;
      height: 18px;
    }

    @include respond-to($small-screen) {
      width: 16px;
      height: 16px;
    }
  }

  &:hover {
    background-color: $light-green;
  }
}

.button-square--green {
  @include circle-button(35px, 35px);
  border-radius: 0;
  border: 1px solid $color-green;
  @include box-shadow(initial);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  @include transition-very-slow;

  &:disabled {
    background-color: $color-grey;
    border-color: $color-grey;
    cursor: not-allowed;

    & .icon {
      @include transition-very-slow;
      fill: $color-white;
    }

    &:hover {
      @include transition-very-slow;
      background-color: $color-grey;
      border-color: $color-grey;
      cursor: not-allowed;
    }
  }

  &__focus {
    background-color: $color-white;
    border-color: $dark-grey;

    & .icon {
      @include transition-very-slow;
      fill: $dark-grey !important;
    }

    &:disabled {
      background-color: $color-white;
      border-color: $dark-grey;

      & .icon {
        fill: $dark-grey !important;
      }
    }

    &:hover {
      @include transition-very-slow;
      background-color: $color-white !important;
      border-color: $dark-grey;
    }
  }

  &:focus {
    @include transition-very-slow;
    border-width: 2px;
  }

  @include respond-to($medium-screen) {
    @include circle-button(35px, 35px);
    border-radius: 0;
    border: 1px solid $color-green;
    @include box-shadow(initial);
    cursor: context-menu;
    background-color: rgba(0, 0, 0, 0);
  }

  @include respond-to($small-screen) {
    @include circle-button(30px, 30px);
    border-radius: 0;
    border: 1px solid $color-green;
    @include box-shadow(initial);
    cursor: context-menu;
    background-color: rgba(0, 0, 0, 0);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .icon {
    width: 20px;
    height: 20px;
    fill: $color-green;

    @include respond-to($large-screen) {
      width: 19px;
      height: 19px;
    }

    @include respond-to($medium-screen) {
      width: 18px;
      height: 18px;
    }

    @include respond-to($small-screen) {
      width: 16px;
      height: 16px;
    }
  }
}

.button-square--pressed {
  border-color: $color-grey;

  & .icon {
    fill: $color-grey;
  }
}
