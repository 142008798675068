@import "src/variables";

.Menu {
  position: absolute;
  top: 60px;
  right: 0;
  width: 0;
  background: #fff;
  height: calc(100vh - 60px);
  z-index: 999;
  transition: 0.4s;

  &.isOpen {
    width: 100%;
  }

  ul {
    padding-inline-start: 0;
    margin: 0;

    li {
      list-style: none;
      border-bottom: 1px solid #c7d1d4;

      a {
        text-decoration: none;
        color: #000;
        font-weight: 300;
        padding: 16px;
        display: inline-block;
        width: 100%;
      }
    }

    .disabled {
      background: $gray-300;
      color: #a8acb0;
    }
  }
}
