/*
 * Container style
 */

.scroll-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: block;

  &--padding {
    & > .scrollbar-track-y {
      right: 20px;
    }
  }
}

.scroll-padding {
  margin-right: 8px !important;

  @include respond-to($medium-screen) {
    margin-right: 0 !important;
  }
}

.scrollbar-track {
  position: absolute;
  opacity: 1;
  z-index: 1;
  background-color: $color-grey;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: opacity 0.5s 0.5s ease-out;
  transition: opacity 0.5s 0.5s ease-out;
}

.scrollbar-track-x {
  //display: none;
  border-top: 1px solid #d5d7d9;
  width: calc(100% - 21px);
  background: #fff;
  height: 21px !important;
  bottom: 0;
  padding: 0 6px;
  background: #fff !important;
  width: calc(100% - 21px) !important;

  &:after {
    transform: unset;
    content: " ";
    background: #d5d7d9;
    position: absolute;
    width: calc(100% - 21px);
    height: 6px;
    top: 7px;
    border-radius: 4px;
    margin-left: 6px;
    z-index: -1;
  }

  .scrollbar-thumb-x {
    height: 6px;
    margin: 7px 0px;
    z-index: 9;
    background: rgb(142, 192, 75) !important;
    border-radius: 4px;
  }
}

.scrollbar-track-y {
  width: 8px !important;
  right: 10px;
  height: calc(100% - 40px);

  @include respond-to($medium-screen) {
    //margin-top: 15px;
    height: calc(100% - 30px);
    right: 8px;
    width: 6px !important;
  }

  @include respond-to($small-screen) {
    //margin-top: 10px;
    height: calc(100% - 20px);
    right: 6px;
    width: 5px !important;
  }
}

.scrollbar-thumb {
  position: absolute;
  left: 0;
  width: 8px;
  height: 8px;
  background: $color-green;
  border-radius: 4px;

  @include respond-to($medium-screen) {
    width: 6px;
  }

  @include respond-to($small-screen) {
    width: 5px;
  }
}
