.services {
  &__services-list {
    background-color: $color-white;
    width: 100%;
    height: calc(100vh - 285px);

    .attachments-icon {
      width: 50%;
      height: 50%;
      fill: $gray-400;
      display: flex;
      .icon {
        fill: $gray-400;
        .st0 {
          fill: $gray-400;
        }
      }
    }

    .list-checkbox {
      height: 60%;
      fill: $gray-400;
      margin-left: 15px;
      .icon {
        height: 100%;
        height: 16px;
        width: 18px;
      }
    }
    .list-checkbox-disabled {
      height: 60%;
      fill: $gray-400;
      margin-left: 15px;
      background-color: grey;
      border-radius: 5px;
      .icon {
        height: 100%;
      }
    }

    @include respond-to($large-screen) {
      height: calc(100vh - 260px);
    }

    @media only screen and (max-width: 1706px) {
      height: calc(100vh - 300px);
    }

    @include respond-to($medium-screen) {
      height: calc(100vh - 267px);
    }

    @include respond-to($small-screen) {
      height: calc(100vh - 232px);
    }

    &--pagination {
      height: 100% !important;
      border-radius: 0 0 5px 5px;
    }

    .services-list__service {
      font-size: rem(16);
      font-weight: 300;
      color: $gray-400;
      text-align: left;
      width: 100%;
      min-height: 32px;
      padding: 0;
      padding-left: 8px !important;
      justify-content: flex-start;

      @include respond-to($large-screen) {
        font-size: rem(14);
      }
      @include respond-to($medium-screen) {
        font-size: rem(12);
      }
      @include respond-to($small-screen) {
        font-size: rem(10);
      }

      &:last-child {
        border-bottom-width: 0px;
      }

      .service__service-id {
        width: 20%;
      }

      .service__status {
        text-align: center;
        width: calc(100% - 12px);
        color: $navy-blue;
      }

      .service__client {
        color: $navy-blue;
      }

      .service__hour {
        color: $navy-blue;
      }

      .service__number {
        color: $navy-blue;
      }

      .service__deadline {
        color: $navy-blue;
      }

      .service__icon {
        height: 30px;
        width: 30px;
        fill: $gray-400;
      }

      .service__dealer {
        display: flex;
        width: 97%;
        padding-right: 10px;
        justify-content: center !important;
        padding-left: 0 !important;
      }
      .service__lawyer {
        display: flex;
        width: 97%;
        padding-right: 10px;
        justify-content: center !important;
        padding-left: 0 !important;
      }
      .service__branch {
        width: 100%;
        margin-right: 10%;
        text-align: left;

        @include respond-to($large-screen) {
          margin-right: 8%;
        }

        @include respond-to($medium-screen) {
          margin-right: 6%;
        }

        @include respond-to($small-screen) {
          margin-right: 4%;
        }
      }

      .service__name {
        width: 100%;
        margin-right: 10%;
        text-align: left;

        @include respond-to($large-screen) {
          margin-right: 8%;
        }

        @include respond-to($medium-screen) {
          margin-right: 6%;
        }

        @include respond-to($small-screen) {
          margin-right: 4%;
        }
      }

      .service__state {
        width: 15%;
      }
    }

    .services-list__service-active {
      background-color: $color-cream;
    }

    .filter-no-padding > .table-filters__input .lp-input__field {
      padding: 0 0 0 2px;
    }
    .filter-narrow-padding > .table-filters__input .lp-input__field {
      padding: 0 0 0 5px;
    }
  }

  &__service-details {
    position: relative;
    padding: 40px;

    footer {
      display: block !important;
    }

    @include respond-to($large-screen) {
      padding: 35px;
    }

    @include respond-to($medium-screen) {
      padding: 25px;
    }

    @include respond-to($small-screen) {
      padding: 20px;
    }

    .service-details__header {
      &__service {
        font-size: rem(16);
        font-weight: 300;
        color: $dark-grey;
        text-align: right;

        @include respond-to($large-screen) {
          font-size: rem(15);
        }

        @include respond-to($medium-screen) {
          font-size: rem(14);
        }

        @include respond-to($small-screen) {
          font-size: rem(12);
          text-align: left;
          font-weight: 400;
        }
      }

      &__name {
        color: #90c04c;
        font-size: rem(22);
        padding-top: 31px;
        font-weight: 500;

        @include respond-to($large-screen) {
          font-size: rem(20);
          padding-top: 30px;
        }

        @include respond-to($medium-screen) {
          font-size: rem(18);
          padding-top: 25px;
        }

        @include respond-to($small-screen) {
          font-size: rem(18);
          padding-top: 6px;
        }
      }

      &__branch {
        color: $color-black;
        letter-spacing: 0.6px;
        font-weight: 300;
        font-size: rem(20);
        padding-top: 16px;

        @include respond-to($large-screen) {
          font-size: rem(18);
          padding-top: 15px;
        }

        @include respond-to($medium-screen) {
          font-size: rem(16);
          padding-top: 13px;
        }

        @include respond-to($small-screen) {
          font-size: rem(14);
          padding-top: 10px;
        }
      }
    }

    .service-details__main {
      margin-top: 36px;

      @include respond-to($large-screen) {
        margin-top: 35px;
      }

      @include respond-to($medium-screen) {
        margin-top: 25px;
      }

      @include respond-to($small-screen) {
        margin-top: 20px;
      }

      & > .main__button {
        margin-right: 20px;
        height: 40px;
        line-height: 36px;
        min-width: 210px;
        padding: 0;

        @include respond-to($large-screen) {
          margin-right: 17px;
          height: 35px;
          line-height: 31px;
          min-width: 190px;
        }

        @include respond-to($medium-screen) {
          margin-right: 15px;
          height: 25px;
          line-height: 21px;
          min-width: 160px;
        }

        @include respond-to($small-screen) {
          margin-right: 10px;
          height: 20px;
          line-height: 16px;
          min-width: 130px;
        }
      }
    }

    .service-details__footer {
      footer {
        display: block;
      }
      &__footer {
        @include flex(flex-start);
        position: relative;

        & .footer__button {
          margin-bottom: 30px;
          margin-right: 20px;
          height: 40px;
          line-height: 36px;
          min-width: 110px;

          @include respond-to($large-screen) {
            margin-right: 17px;
            margin-bottom: 27px;
            height: 35px;
            line-height: 31px;
            min-width: 100px;
          }

          @include respond-to($medium-screen) {
            margin-right: 15px;
            margin-bottom: 25px;
            height: 25px;
            line-height: 21px;
            min-width: 80px;
          }

          @include respond-to($small-screen) {
            margin-right: 10px;
            margin-bottom: 20px;
            height: 20px;
            line-height: 16px;
            min-width: 60px;
          }
        }

        .button-text {
          margin-top: 8px;

          font-size: 16px;
          font-weight: 400;
          display: none;

          @include respond-to($small-screen) {
            display: inline-block;
            font-size: 14px;
            color: $color-black;
            text-decoration: underline;
          }
        }

        & .button-icon--mobile {
          display: none;
          width: 11px;
          fill: #8ec04b;
          height: 8px;
          font-weight: 500;
          margin-right: 5px;
          &--hide {
            margin-top: 18px;
            margin-left: 22px;
            width: 11px;
            fill: #8ec04b;
            margin: 0;
            transform: rotate(180deg);
          }
          @include respond-to($small-screen) {
            display: inline-block;
          }
        }
      }

      &__header {
        margin-top: 40px;
        font-size: rem(18);
        color: $navy-blue;

        @include respond-to($large-screen) {
          margin-top: 35px;
          font-size: rem(17);
        }

        @include respond-to($medium-screen) {
          margin-top: 25px;
          font-size: rem(16);
        }

        @include respond-to($small-screen) {
          margin-top: 20px;
          font-size: rem(16);
          color: black;
        }
      }

      &__stages {
        margin-top: 17px;

        @include respond-to($large-screen) {
          margin-top: 15px;
        }

        @include respond-to($medium-screen) {
          margin-top: 12px;
        }

        @include respond-to($small-screen) {
          margin-top: 10px;
        }

        & > .stages__list {
          width: 100%;
          padding: 0 !important;
          .stages__list--element {
            background-color: $color-white;
            font-size: rem(18);
            font-weight: 300;
            color: $navy-blue;
            width: 100%;
            @include flex(flex-start);

            @include respond-to($large-screen) {
              font-size: rem(17);
            }

            @include respond-to($medium-screen) {
              font-size: rem(16);
            }

            @include respond-to($small-screen) {
              font-size: rem(14);
            }

            @media (max-width: 340px) {
              font-size: rem(12);
            }

            & > .element__date {
              min-width: 15%;
              max-width: 15%;
              text-align: center;
              margin-left: 10px;
              @include respond-to($small-screen) {
                min-width: 25%;
                max-width: unset;
                width: auto;
                color: black;
                font-weight: 500;
                margin-left: 10px;
              }
            }

            &:nth-child(odd) {
              background-color: $color-cream;
            }

            &:last-child {
              margin-bottom: 20px;

              @include respond-to($large-screen) {
                margin-bottom: 17px;
              }

              @include respond-to($medium-screen) {
                margin-bottom: 14px;
              }

              @include respond-to($small-screen) {
                margin-bottom: 10px;
              }
            }

            & > span {
              margin-left: 20px;
              padding: 10px;
              @include respond-to($small-screen) {
                color: $navy-blue;
                font-weight: 500;
                margin-left: 10px;
              }
            }
          }

          & .steps-enter {
            @include transition-very-slow;
            opacity: 0.01;
            @include transform(translateY(-100%));
          }

          & .steps-enter-active {
            opacity: 1;
            @include transform(translateY(0));
          }

          & .steps-leave {
            @include transition-very-slow;
            opacity: 1;
            @include transform(translateY(0));
          }

          & .steps-leave-active {
            opacity: 0.01;
            @include transform(translateY(-100%));
          }
        }
      }

      &__button {
        font-size: 16px;
        line-height: 26px;
        padding: 5px 26px;
        font-weight: normal;
        color: #4b555f;

        @include respond-to($large-screen) {
          font-size: 16px;
        }

        @include respond-to($medium-screen) {
          font-size: 14px;
        }

        @include respond-to($small-screen) {
          font-size: 12px;
          display: none;
        }
      }
    }
  }

  &__service-calls {
    padding: 40px;
    background-color: $color-cream;

    @include respond-to($large-screen) {
      padding: 35px;
    }

    @include respond-to($medium-screen) {
      padding: 25px;
    }

    @include respond-to($small-screen) {
      padding: 20px;
    }

    & .service-calls__footer {
      @include flex(flex-start);
      position: relative;

      & .footer__button {
        margin-bottom: 30px;
        margin-right: 20px;
        height: 40px;
        line-height: 36px;
        min-width: 110px;

        @include respond-to($large-screen) {
          margin-right: 17px;
          margin-bottom: 27px;
          height: 35px;
          line-height: 31px;
          min-width: 100px;
        }

        @include respond-to($medium-screen) {
          margin-right: 15px;
          margin-bottom: 25px;
          height: 25px;
          line-height: 21px;
          min-width: 80px;
        }

        @include respond-to($small-screen) {
          margin-right: 10px;
          margin-bottom: 20px;
          height: 20px;
          line-height: 16px;
          min-width: 60px;
        }
      }
    }

    & .service-calls__button {
      margin-bottom: 20px;
      margin-right: 20px;
      height: 40px;
      line-height: 36px;
      min-width: 110px;

      @include respond-to($large-screen) {
        margin-bottom: 17px;
        margin-right: 17px;
        height: 35px;
        line-height: 31px;
        min-width: 100px;
      }

      @include respond-to($medium-screen) {
        margin-bottom: 14px;
        margin-right: 15px;
        height: 25px;
        line-height: 21px;
        min-width: 80px;
      }

      @include respond-to($small-screen) {
        margin-bottom: 10px;
        margin-right: 10px;
        height: 20px;
        line-height: 16px;
        min-width: 60px;
      }
    }

    & > .service-calls__header {
      font-size: rem(18);
      color: $navy-blue;
      @include flex(flex-start, flex-end);

      & .header__title {
        white-space: nowrap;
        margin-right: 10px;
        @include respond-to($small-screen) {
          font-size: rem(16);
          color: black;
          margin-top: 20px;
        }
      }

      & .header__border {
        width: 100%;
        border-bottom: 1px solid $color-grey;
        margin-bottom: 3px;
        @include respond-to($small-screen) {
          display: none;
        }
      }

      @include respond-to($large-screen) {
        font-size: rem(17);
      }

      @include respond-to($medium-screen) {
        font-size: rem(16);
      }

      @include respond-to($small-screen) {
        font-size: rem(14);
      }
    }

    & > .service-calls__service-call-list {
      margin-top: 16px;

      & .services-enter {
        transition: 500ms;
        opacity: 0;
        @include transform(translateY(-100%));
      }

      & .services-enter-active {
        opacity: 1;
        @include transform(translateY(0));
      }

      & .services-leave {
        opacity: 1;
        @include transform(translateY(0));
      }

      & .services-leave-active {
        transition: all 300ms ease-in;
        opacity: 0;
        @include transform(translateY(-100%));
      }

      @include respond-to($large-screen) {
        margin-top: 15px;
      }

      @include respond-to($medium-screen) {
        margin-top: 12px;
      }

      @include respond-to($small-screen) {
        margin-top: 10px;
      }

      .service-call-list__service-call {
        @include flex(flex-start);
        color: $dark-grey;
        font-size: rem(16);
        height: 60px;
        border-top: 1px solid $color-grey;
        font-weight: 300;

        &:first-child {
          border-top-width: 0;
        }

        @include respond-to($large-screen) {
          height: 55px;
          font-size: rem(14);
        }

        @include respond-to($medium-screen) {
          height: 50px;
          font-size: rem(12);
        }

        @include respond-to($small-screen) {
          height: 80px;
          font-size: rem(12);
        }

        @media (max-width: 340px) {
          font-size: rem(10);
        }

        p {
          margin: 0;
        }

        &.mobile {
          .service-call__status {
            width: 40%;
            text-align: right;
            .MuiButton-root {
              font-size: rem(16);
              width: 70%;
              @include respond-to($large-screen) {
                font-size: rem(16);
                width: 70%;
              }

              @include respond-to($medium-screen) {
                font-size: rem(14);
                width: 90%;
              }

              @include respond-to($small-screen) {
                width: 90%;
                font-size: rem(12);
                padding: 5px 10px;
              }

              @media (max-width: 340px) {
                font-size: rem(10);
                padding: 5px;
              }
            }
          }
        }

        .service-call__date {
          width: 20%;
          @include respond-to($small-screen) {
            display: none;
          }

          &--mobile {
            display: none;
            @include respond-to($small-screen) {
              display: inline-block;
            }

            width: 25%;
            strong {
              height: 40px;
              font-size: rem(14);
              color: $navy-blue;
              margin-bottom: 6px;
              @media (max-width: 340px) {
                font-size: rem(12);
              }
            }
          }
        }

        .service-call__icon {
          width: 15%;

          & > .icon {
            width: 22px;
            height: 22px;
            fill: $dark-grey;

            @include respond-to($large-screen) {
              width: 20px;
              height: 20px;
            }

            @include respond-to($medium-screen) {
              width: 17px;
              height: 17px;
            }

            @include respond-to($small-screen) {
              width: 15px;
              height: 15px;
            }
          }
        }

        .service-call__lawyer {
          width: 20%;

          @include respond-to($small-screen) {
            display: none;
          }

          &--mobile {
            display: none;
            width: 35%;

            @include respond-to($small-screen) {
              display: inline-block;
            }

            strong {
              height: 40px;
              font-size: rem(14);
              color: $navy-blue;
              margin-bottom: 6px;
              @media (max-width: 340px) {
                font-size: rem(12);
              }
            }
          }
        }

        .service-call__time {
          @include respond-to($small-screen) {
            display: none;
          }
          width: 10%;
        }

        .service-call__domain {
          @include respond-to($small-screen) {
            display: none;
          }
          width: 20%;
        }

        .service-call__status {
          width: 40%;
          text-align: right;

          .MuiButton-root {
            font-size: rem(16);
            width: 70%;
            background: transparent;
            @include respond-to($large-screen) {
              font-size: rem(16);
              width: 70%;
            }

            @include respond-to($medium-screen) {
              font-size: rem(14);
              width: 90%;
            }

            @include respond-to($small-screen) {
              width: 90%;
              font-size: rem(12);
              padding: 5px 10px;
            }

            @media (max-width: 340px) {
              font-size: rem(10);
              padding: 5px;
            }
          }
        }

        .service-call__diagram {
          width: 22%;
          text-align: right;
        }

        .service-call__button {
          height: 40px;
          width: 100%;
          line-height: 36px;
          font-weight: 500;

          @include respond-to($medium-screen) {
            padding: 0 !important;
          }
        }
      }
    }
  }
}

.services-list--border {
  border-right: 1px solid $color-grey;
}

.services {
  &__services-list {
    .ReactTable {
      .rt-thead.-filters {
        .rt-tr {
          > :first-of-type {
            padding-left: 12px;
          }
          > :last-of-type {
            //padding-right: 12px;
            padding-right: 10px;
          }
        }

        .rt-th {
          padding: 0 0 0 5px;
          justify-content: flex-start;
        }
      }
    }

    .services-list {
      &__filter {
        width: 97%;

        &--small-padding {
          .lp-input__field {
            padding: 0 0 0 4px;
          }
        }

        &--wide {
          width: 97%;
        }

        & .table-filters__input .lp-input__field {
          width: 100%;
        }
      }
    }
  }
}
