.newMessage__wrapper {
  .public-DraftEditorPlaceholder-root {
    padding: 0 !important;
  }
  .react-autosuggest__suggestions-container--open {
    z-index: 11;
  }
  width: 100%;
  padding: 20px 30px 0 30px;

  position: relative;
  height: 100%;
  @include flex(flex-start, flex-start);
  flex-direction: column;

  &--title {
    width: 100%;
    height: 30px;
    line-height: 30px;
    color: $navy-blue;
    font-size: 1.12rem;
    font-weight: 300;
    border-bottom: 1px solid $color-grey;
  }

  .templates {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  .templates .trashIconWrapper {
    cursor: pointer;
    margin-left: 10px;

    .icon {
      color: #878787;
      fill: #878787;
    }
  }

  .template-button {
    margin: 5px;
  }
  & .filters__wrapper {
    padding-bottom: 20px;
    border-bottom: 1px solid $color-grey;
    width: 100%;
    @include flex(center, flex-start);
    flex-direction: column;

    &--filter {
      width: 100%;
      display: flex;
      flex-direction: row;
      .icon__wrapper {
        width: 35px;
        cursor: pointer;
        margin-top: 10px;
        height: 35px;
        border: 1px solid $color-green !important;
        @include flex;

        & .icon {
          width: 22px;
          height: 22px;
          fill: $color-green !important;

          .st0 {
            fill: $color-green !important;
          }
        }
      }
      & .autocompletes-list {
        display: flex;
        padding-top: 3px;
        flex-direction: column;
        width: 100%;
        & .autocomplete-input {
          margin-top: 10px;
          height: 30px;
          width: 100%;
          & .react-autosuggest__container {
            margin-left: 0;
            width: 100%;
          }
          & .react-autosuggest__input {
            margin: 0;
            height: 30px;
            margin-left: 25px;
            width: calc(100% - 25px);
          }
          &--error {
            width: calc(100% - 10px);
            margin-top: 10px;
            height: 30px;
            & .react-autosuggest__container--open {
              border: none;
            }
            & .react-autosuggest__container {
              width: 100%;
              border: none;
            }
            & .react-autosuggest__input {
              margin: 0;
              height: 30px;
              border: 1px solid red;
            }
            & .react-autosuggest__suggestions-container--open {
              width: 50%;
              right: 0;
              border: none;
              position: absolute;
              z-index: 100;
            }
          }
        }

        .react-autosuggest__suggestions-list {
          margin: 0;
          padding: 0;
          border: 0;
          outline: 0;
          text-decoration: none;
          list-style: none;

          font-weight: inherit;
          box-sizing: border-box;
        }
      }
    }
  }

  & .headers__wrapper {
    padding-top: 15px;
    width: 100%;
    grid-gap: 10px 0;
    display: grid;
    grid-template-columns: 60px 1fr;
    border-bottom: 1px solid $color-grey;

    &--title {
      justify-self: flex-start;
      align-self: center;
      grid-column-start: 1;
      grid-column-end: 2;
      color: $navy-blue;
    }
    &--title-autocplete {
      margin-top: 15px;
      justify-self: flex-start;
      grid-column-start: 1;
      grid-column-end: 2;
      color: $navy-blue;

      .trashIconWrapper {
        fill: #878787;
        color: #878787;
        height: 10px !important;
        width: 10px !important;
      }
    }
    &--select {
      display: flex;
      .icon-reply {
        margin-left: 5px;
        transform: rotate(-90deg);
        height: 18px;
        cursor: pointer;
      }
    }
    &--autocpletes {
      & .autocomplete-input {
        margin-top: 10px;
        width: 100%;
        height: 30px;
        & .react-autosuggest__container--open {
          border: none;
        }
        & .react-autosuggest__container {
          width: 100%;
          border: none;
        }
        & .react-autosuggest__input {
          margin: 0;
          height: 30px;
        }
        & .react-autosuggest__suggestions-container--open {
          width: 50%;
          right: 0;
          border: none;
          position: absolute;
          z-index: 100;
        }
      }
    }
    .react-autosuggest__suggestions-list {
      top: 5;
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      text-decoration: none;
      list-style: none;

      font-weight: inherit;
      box-sizing: border-box;
    }

    &--input {
      width: 100%;
      justify-self: flex-start;
      align-self: center;
      grid-column-start: 2;
      grid-column-end: 3;
      & .lp-input {
        & .lp-input__info {
          display: none;
        }

        & .lp-input__field {
          font-size: rem(16);
          color: $navy-blue;
          padding: 0 3px;
          width: 100%;
          text-align: left;
          height: 30px;
        }
      }
    }

    &--circles {
      padding: 15px 0;
      grid-column-start: 1;
      grid-column-end: 3;
      width: 100%;
      @include flex(flex-start);

      & .circle {
        margin-right: 15px;
        border: 1px solid $dark-grey;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        cursor: pointer;
        padding: 5px;
        font-size: rem(20);
        color: $dark-grey;
        @include flex;
        &--add {
          border: 1px solid $color-grey;
          background: $color-grey;
          fill: $color-white;
          .icon-plus {
            height: 15px;
          }
        }
      }

      & .custom {
        border: 1px dashed $dark-grey;
      }
    }
  }

  & .valuation__wrapper {
    padding-top: 15px;
    width: 100%;

    border-bottom: 1px solid $color-grey;
    padding-bottom: 30px;

    .valuation-form-row {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      flex-wrap: wrap;

      .form-control {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin: 0 10px 15px 0;

        &.flex-column {
          display: flex;
          flex-direction: column;
        }

        .price-wrapper {
          display: flex;
        }

        &-title {
          width: 100%;

          .lp-input__field {
            width: 100% !important;
          }
        }

        .valuation-summary {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-top: 15px;

          &-field {
            min-width: 90px;
            color: darkgray;
          }
        }

        .payment-button {
          width: 20px;
          height: 20px;
          background: 0;
          border: 0;
          cursor: pointer;
          margin-left: 10px;

          svg {
            fill: #666;
            width: 20px;
            height: 20px;
          }
        }
        .headers__wrapper--title {
          margin-right: 8px;
          max-width: 62px;
        }
        .MuiFormControl-root {
          min-width: 130px;
          max-width: 130px;
          height: 30px;
          margin: 0;
          margin-left: 15px;
          padding: 0;

          .MuiIconButton-root {
            padding: 4px;
          }
          .MuiOutlinedInput-input {
            padding: 6px;
            font-size: rem(14);
          }
          .MuiOutlinedInput-adornedEnd {
            padding: 0;
          }
        }

        .select {
          min-width: 100px;
          height: 30px;
          font-size: rem(14);
          > div {
            border-color: transparent;
          }
        }

        .lp-input__info {
          display: none;
        }
        .lp-input.input {
          height: 30px;
          width: 90x;
          min-width: 90px;
          margin: 0 5px;
        }

        .lp-input__field {
          width: 90px;
          height: 30px;

          font-size: rem(14);
          color: $navy-blue;
          padding: 0 10px;

          text-align: left;
          height: 30px;
        }
      }
    }

    .valuation-buttons {
      display: flex;
      justify-content: flex-end;
      margin-right: 30px;
      margin-top: 15px;

      .upsert-service-form__button--add {
        cursor: pointer;
      }
    }

    &--input {
      width: 100%;
      justify-self: flex-start;
      align-self: center;
      grid-column-start: 2;
      grid-column-end: 3;
      & .lp-input {
        & .lp-input__info {
          display: none;
        }

        & .lp-input__field {
          font-size: rem(16);
          color: $navy-blue;
          padding: 0 3px;
          width: 100%;
          text-align: left;
          height: 30px;
        }
      }
    }
  }

  & .template__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
    margin-bottom: 5px;

    .message_control--white {
      margin-left: 10px !important;
      width: 80px !important;
      height: 30px;
    }

    .lp-input {
      width: auto !important;
    }
    .lp-input.input {
      height: 30px;
      width: 140px !important;
      min-width: 90px;
      margin: 0 5px;
    }

    .lp-input__field {
      width: 140px !important;
      height: 30px;

      font-size: rem(14);
      color: $navy-blue;
      padding: 0 10px;

      text-align: left;
      height: 30px;
    }
  }

  & .messageBody__wrapper {
    width: 100%;
    padding: 15px 0;

    .messageContent__toolbar {
      &--button {
        border: none;
        background: transparent;
        transition: 1s background-color;
        &:hover {
          background: rgba(100, 100, 100, 0.1);
        }
      }
    }

    .DraftEditor-root {
      height: 300px;
      & > div > div {
        padding: 20px 0 0 0;
      }
    }

    &--editor {
      border: none;
      font-family: $base-font-family;
      & > div:first-child {
        border-radius: 5px;
        box-shadow: 0px 2px 5px 0 rgba(22, 18, 20, 0.25);
        margin: 0;
        padding: 10px 0;
        & > div {
          margin: 0;
          padding: 0 10px;
          border-right: 1px solid $color-grey;
          &:nth-child(4) {
            border: none;
          }
          &:nth-child(2) > div > button {
            border: none;
            background: transparent;
            transition: 1s background-color;
            &:hover {
              background: rgba(100, 100, 100, 0.1);
            }
          }
          &:nth-child(4) > div > button {
            border: none;
            background: transparent;
            transition: 1s background-color;
            &:hover {
              background: rgba(100, 100, 100, 0.1);
            }
          }
        }
      }
    }
  }

  & .messageControls__wrapper {
    width: 100%;
    padding-bottom: 15px;
    @include flex(flex-start);
    flex-direction: column;

    &--bottom {
      width: 100%;
      padding-bottom: 15px;
      margin-top: 20px;
      border-top: 1px solid #c0c0c0;
      @include flex(flex-start);
      flex-direction: row;
    }

    &--attachments {
      width: 100%;
      & .attachment {
        color: $dark-grey;
        fill: $dark-grey;
        width: 100%;
        display: inline-block;
        padding-top: 5px;
        padding-bottom: 5px;
        border-top: 1px solid $dark-grey;
        & .left {
          float: left;
          max-width: 50%;
          overflow: hidden;
          border: none;
        }
        & .right {
          max-width: 50%;
          overflow: hidden;
          float: right;
          display: flex;
          flex-direction: row;
          & .progress {
            margin-top: 4px;
            margin-left: 10px;
            width: 120px;
            height: 12px;
            background-color: #e9eaef;
            border-radius: 10px;
            & .progress-value {
              padding-left: 20px;
              height: 12px;
              background-color: #8ec04b;
              border-radius: 10px;
            }
          }
          & .trashIconWrapper {
            cursor: pointer;
            margin-left: 10px;
          }
          & .file-weight {
            // color: #989898;
            color: $dark-grey;
            margin-left: 10px;
          }
        }
      }
    }

    &--controls {
      padding-top: 20px;
      width: 100%;
      @include flex(flex-start);

      .message_control {
        width: 150px;
        margin-right: 15px;
        height: 40px;

        &--white {
          width: auto;
          border: 0;
          margin-left: auto;

          @include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3));
          > .MuiButton-label {
            color: $base-green;
          }
        }

        &--text {
          border: 0;
          text-decoration: underline;
          background: none;
          font-size: rem(16);
          cursor: pointer;
        }
      }

      & .controls__attachFile {
        width: 45%;
        color: $dark-grey;
        @include flex(flex-start);

        & .attachment-wrapper {
          & .fileInput {
            display: none;
          }
          &:focus {
            border: 1px solid $color-green;
          }
          & .icon {
            fill: $base-green;
            width: 20px;
            height: 20px;
            margin-right: 5px;
            .st0 {
              fill: $base-green;
            }
          }
        }

        & .docsIconWrapper {
          cursor: pointer;

          width: 45px;
          height: 45px;
          border: 1px solid $color-green;
          @include flex;

          & .icon {
            margin: 0;
            width: 20px;
            height: 20px;
            fill: $color-green;
          }
        }
      }

      .controls__button {
        height: 30px;
      }

      & .button--white {
        background: $color-white;
        color: $color-green;
        transition: all ease 0.3s;
      }

      & .button--white:focus {
        border: 1px solid $color-green;
      }

      & .button--white:disabled {
        color: $color-grey;
        &:hover {
          @include box-shadow(0px 1px 5px 0 rgba(28, 28, 32, 0.3));
        }
      }
    }
  }
}
